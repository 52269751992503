import { Fragment, useCallback } from 'react';
import { Row, Col, Card, Button, Input, Label } from 'reactstrap';
import api from '../../../services/api';
import { useAuth } from '../../../context/Auth';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const CreateUser = () => {
  // TODO: validate fields
  const { toggleLoading, authenticated } = useAuth();
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const submitData = useCallback(async (inputData) => {
    toggleLoading(true);
    try {
      const formData = new FormData();
      const { hash } = authenticated;

      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('nome', inputData.nome);
      formData.append('codigo', inputData.codigo);
      formData.append('email', inputData.email);
      formData.append('senha', inputData.senha);
      formData.append('telefone', inputData.telefone);
      formData.append('cep', inputData.cep);
      formData.append('cidade', inputData.cidade);
      formData.append('estado', inputData.estado);
      formData.append('experiencia', '');
      formData.append('indicacao', '');
      formData.append('level', parseInt(inputData.level));

      const response = await api.post('/create_user', formData);
      const { data } = response;
      if (data && data.result && data.result === 'success' && data.message) {
        toast.success(data.message);
        history.push(`/usuarios`);
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Erro ao criar usuário');
      }
    }
    toggleLoading(false);
  }, []);

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Criar usuário</h1>
        </Col>
      </Row>

      <Row className="mt-1">

        <Col md={12}>
          <Card className='p-3'>
            <Row>
              <Col md={8}>
                <Label>Nome</Label>
                <Input
                  type='text'
                  name='nome'
                  id='nome'
                  innerRef={register}
                />
              </Col>
              <Col md={4}>
                <Label>Código</Label>
                <Input
                  type='text'
                  name='codigo'
                  innerRef={register}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={6}>
                <Label>E-mail</Label>
                <Input
                  type='email'
                  name='email'
                  id='email'
                  innerRef={register}
                />
              </Col>
              <Col md={6}>
                <Label>Telefone</Label>
                <Input
                  type='text'
                  name='telefone'
                  id='telefone'
                  innerRef={register}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={4}>
                <Label>CEP</Label>
                <Input
                  type='text'
                  name='cep'
                  id='cep'
                  innerRef={register}
                />
              </Col>
              <Col md={4}>
                <Label>Cidade</Label>
                <Input
                  type='text'
                  name='cidade'
                  id='cidade'
                  innerRef={register}
                />
              </Col>
              <Col md={4}>
                <Label>Estado</Label>
                <Input
                  type='text'
                  name='estado'
                  id='estado'
                  innerRef={register}
                />
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={6}>
                <Label>Senha</Label>
                <Input
                  type='password'
                  name='senha'
                  id='senha'
                  innerRef={register}
                />
              </Col>
              <Col md={6}>
                <Label>Nível</Label>
                <Input
                  type='select'
                  name='level'
                  id='level'
                  innerRef={register}
                >
                  <option value='3'>Vendedor</option>
                  <option value='2'>Orçamentista / Interno</option>
                  <option value='1'>Admin</option>
                </Input>
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col md={6}>
                <Button
                  color='primary'
                  className='mr-1'
                  onClick={handleSubmit(submitData)}
                >
                  Criar
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>

      </Row>
    </Fragment>
  )
}

export default CreateUser;
