import { Fragment, useEffect, useState } from 'react'
import { Sun, Moon } from 'react-feather'
import { NavItem, NavLink } from 'reactstrap'
import { useAuth } from '../../../../context/Auth'

import UserDropdown from './UserDropdown'
import AdminDropdown from './AdminDropdown'

import NavbarBookmarks from './NavbarBookmarks'

const ThemeNavbar = props => {
  const { skin, setSkin, setMenuVisibility } = props
  const { authenticated } = useAuth()
  const [userRole, setUserRole] = useState()

  const ThemeToggler = () => {
    // ** Toggle Theme (Light/Dark)
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />
    }
  }

  useEffect(() => {
    const userData = authenticated
    if (userData && userData.level) {
      setUserRole(parseInt(userData.level))
    }
  }, [authenticated, setUserRole])

  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
      <ul className='nav navbar-nav align-items-center ml-auto'>

        {userRole && userRole === 1 && <AdminDropdown />}
        
        <NavItem className='d-none d-lg-block'>
          <NavLink className='nav-link-style'>
            <ThemeToggler />
          </NavLink>
        </NavItem>
        <UserDropdown />
      </ul>
    </Fragment>
  )
}

export default ThemeNavbar
