import { Fragment, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, CardBody, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../../../services/api';
import { useAuth } from '../../../context/Auth';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DataTableRepensa from '../../../@core/components/datatable/DataTableRepensa';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const Representantes = () => {
  const history = useHistory();
  const { register, handleSubmit, setValue } = useForm();
  const [data, setData] = useState();
  const { toggleLoading, authenticated } = useAuth();
  const [currentRepresentante, setCurrentRepresentante] = useState();

  const [modalRemove, setModalRemove] = useState(false);
  const toggleModalRemove = useCallback(() => {
    setModalRemove(value => !value);
  }, [setModalRemove]);

  const getData = useCallback(async () => {
    toggleLoading(true)
    try {
      const formData = new FormData()
      formData.append('access_key', ACCESS_KEY)

      const response = await api.post('/listar_representantes', formData)
      const { data } = response
      if (data && data.data) {
        setData(data.data)
      }
    } catch (err) {
      toast.error('Erro ao recuperar usuários')
    }
    toggleLoading(false)
  }, [api, setData, toggleLoading]);

  const onSubmit = useCallback(async (data) => {
    if (data.nome && data.telefone) {
      const { hash } = authenticated;

      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('nome', data.nome);
      formData.append('telefone', data.telefone);
      formData.append('email', data.email);
  
      toggleLoading(true);

      try {
        const response = await api.post('/criar_representante', formData);
        if (response && response.data && response.data.message) {
          toast.success(response.data.message);
          setValue('nome', '');
          setValue('telefone', '');
          setValue('email', '');
        }
        getData();
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }

      toggleLoading(false);
    }
  }, [toggleLoading, authenticated, setValue, getData]);

  const removerRepresentante = useCallback(async () => {
    if (currentRepresentante && currentRepresentante.id) {
      const { hash } = authenticated;
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('id', currentRepresentante.id);
  
      toggleLoading(true);

      try {
        const response = await api.post('/remover_representante', formData);
        if (response && response.data && response.data.message) {
          toast.success(response.data.message);
        }
        getData();
        toggleModalRemove();
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }

      toggleLoading(false);
    }
  }, [authenticated, currentRepresentante, getData, toggleModalRemove]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = [
    { selector: 'id', name: 'ID' },
    { selector: 'nome', name: 'Nome' },
    { selector: 'telefone', name: 'Telefone' },
    { selector: 'email', name: 'E-mail' },
    {
      selector: 'actions',
      name: 'Ações',
      cell: (row) => (
        <Fragment>
          <Button color='danger' className='mr-1' onClick={() => {
            setCurrentRepresentante(row);
            toggleModalRemove();
          }} >
            Remover
          </Button>
        </Fragment>
      )
    }
  ];

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <h1>Representantes</h1>
        </Col>
      </Row>

      <Card className='mt-2'>
        <CardBody>
          <Row>
            <Col>
              <h5>Criar representante</h5>
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={12}>
              <Label>Nome</Label>
              <Input
                name='nome'
                id='nome'
                innerRef={register}
              />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={6}>
              <Label>Telefone</Label>
              <Input
                name='telefone'
                id='telefone'
                innerRef={register}
              />
            </Col>
            <Col md={6}>
              <Label>E-mail</Label>
              <Input
                name='email'
                id='email'
                innerRef={register}
              />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col>
              <Button
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row className="mt-1">
        <Col md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h5>Representantes cadastrados</h5>
                </Col>
              </Row>
            </CardBody>
          
            <DataTableRepensa columns={columns} data={data} />
          </Card>
        </Col>

      </Row>

      <Modal isOpen={modalRemove} toggle={toggleModalRemove}>
        <ModalHeader toggle={toggleModalRemove}>
          Remover representante
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>Confirmar remoção do representante:</p>
              <p>Nome: {currentRepresentante && currentRepresentante.nome}</p>
              <p>Telefone: {currentRepresentante && currentRepresentante.telefone}</p>
              <p>E-mail: {currentRepresentante && currentRepresentante.email}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='text-right'>
              <Button color='secondary' onClick={toggleModalRemove}>Cancelar</Button>
              <Button color='danger' className='ml-1' onClick={removerRepresentante}>Remover</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default Representantes;
