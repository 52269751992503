import { Database } from 'react-feather'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const AdminDropdown = () => {
  return (
    <Link className='nav-link mr-2' to='/admin'>
      <Button>
      Painel administrativo <Database size={21} color='#2CC770' />
      </Button>
    </Link>
  )
}

export default AdminDropdown
