import { Home, BarChart } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboard',
    icon: <Home size={20} />,
    navLink: '/dashboard',
  },
  // {
  //   id: 'dashboard-analitico',
  //   title: 'Analítico',
  //   icon: <BarChart size={20} />,
  //   navLink: '/dashboard-analitico'
  // }
]
