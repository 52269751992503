import { HelpCircle, User, Folder } from 'react-feather'

export default [
  {
    id: 'conta',
    title: 'Conta',
    icon: <User size={20} />,
    navLink: '/perfil'
  },
  // {
  //   id: 'documentos',
  //   title: 'Documentos',
  //   icon: <Folder size={20} />,
  //   navLink: '/documentos'
  // },
  {
    id: 'faq',
    title: 'FAQ',
    icon: <HelpCircle size={20} />,
    navLink: '/faq'
  }
]
