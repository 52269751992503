import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import ConfigEmailNotificacoes from './components/ConfigEmailNotificacoes';
import ConfigTelefoneRepensa from './components/ConfigTelefoneRepensa';
import ConfigTotal from './components/ConfigTotal';

const ConfiguracoesSistema = () => {
  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Configurações do sistema</h1>
        </Col>
      </Row>

      <Row className='mt-2'>

        <Col md={6}>
          <ConfigEmailNotificacoes />
        </Col>

        <Col md={6}>
          <ConfigTelefoneRepensa />
        </Col>

      </Row>

      <Row className='mt-2'>

        <Col md={6}>
          <ConfigTotal />
        </Col>
        
      </Row>

      <Row className='mt-4'>
        <Col>
          <Button
            color='light'
            tag={Link}
            to='/admin'
            className='ml-1'
          >
            Retornar ao painel de administração
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default ConfiguracoesSistema;
