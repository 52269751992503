/* Formulario2.js */

import { useRef, useState, Fragment, useCallback, useEffect } from 'react';
import { FileText, User, ArrowLeft, ArrowRight, DollarSign, CheckCircle } from 'react-feather';
import { Label, FormGroup, Row, Col, Input, Form, Button } from 'reactstrap';
import Wizard from '@components/wizard';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../context/Auth';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Gerador from './Gerador';
import axios from 'axios';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const DEMO_MODE = (process.env.REACT_APP_DEMO_MODE === 'true');
const ENABLE_ITENS_OPCIONAIS = (process.env.REACT_APP_ENABLE_ITENS_OPCIONAIS === 'true');
const meses = ['janeiro', 'fevereiro', 'marco', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
const numberFormatter = new Intl.NumberFormat('pt-BR');

const Formulario2 = ({ propostaEdit, newVersao = false, vNumber }) => {
  const { toggleLoading, authenticated } = useAuth();
  const [userRole, setUserRole] = useState();

  const [propostaId, setPropostaId] = useState(false);
  const [versaoId, setVersaoId] = useState(false);
  const [versaoNumber, setVersaoNumber] = useState(1);

  const [vendedorData, setVendedorData] = useState(false);

  const [stepper, setStepper] = useState(null);
  const ref = useRef(null);

  const { register, getValues, setValue } = useForm();
  const [parametrosProposta, setParametrosProposta] = useState();
  const [telefoneRepensa, setTelefoneRepensa] = useState('');

  const [placas, setPlacas] = useState();
  const [inversores, setInversores] = useState();
  const [inversoresOptions, setInversoresOptions] = useState();
  const [transformadores, setTransformadores] = useState();

  const [executivos, setExecutivos] = useState();
  const [representantes, setRepresentantes] = useState();

  const precoMedioRange = [
    {value: 30, preco: 3120.14},
    {value: 110, preco: 2714.48}
  ];

  const buscaCep = useCallback(async(cep) => {
    if (cep) {
      cep = cep.replace(/\D/g, '');
      if (cep.length > 8) {
        return false;
      }
      const url = `https://brasilapi.com.br/api/cep/v2/${cep}`;
      try {
        const response = await axios.get(url);
        if (response && response.data) {
          const { data } = response;
          return data;
        }
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  }, []);

  const getPrecoMedioEquipamentos = useCallback((potencia) => {
    let preco = precoMedioRange[0].preco;
    if (potencia) {
      potencia = parseFloat(potencia);
      precoMedioRange.forEach((value) => {
        if (potencia > value.value) {
          preco = value.preco;
        }
      });
    }
    return preco;
  }, []);

  const [camposAdicionais, setCamposAdicionais] = useState(true)
  const toggleCamposAdicionais = useCallback(() => {
    setCamposAdicionais(!camposAdicionais)
  }, [setCamposAdicionais, camposAdicionais])

  const [camposIrradiacao, setCamposIrradiacao] = useState(false)
  const toggleCamposIrradiacao = useCallback(() => {
    setCamposIrradiacao(!camposIrradiacao)
  }, [setCamposIrradiacao, camposIrradiacao])

  const [proposta, setProposta] = useState({
    nome_do_cliente: '',
    cpf_cliente: '',
    email_cliente: '',
    telefone_cliente: '',
    executivo_de_vendas: '',
    executivo_de_vendas_nome: '',
    executivo_de_vendas_telefone: '',
    representante: '',
    representante_nome: '',
    representante_telefone: '',
    tipo_projeto: 'residencial',
    cep_cliente: '',
    logradouro_cliente: '',
    bairro_cliente: '',
    municipio_cliente: '',
    estado_cliente: '',
    concessionaria: '',

    demanda_contratada: '',
    n_unidade_consumidora: '',
    classificacao: '',
    custo_de_disponibilidade: '',
    prospeccao: 'sim',
    faturamento: 'direto',
    tipo_de_instalacao: '',

    opcional_cerca: 'nao',
    opcional_brita: 'nao',
    opcional_garantia_estendida: 'nao',
    opcional_estacao_solarimetrica: 'nao',
    opcional_abrigo: 'nao',
    
    unidade_consumidora: {
      1: {
        janeiro: '',
        fevereiro: '',
        marco: '',
        abril: '',
        maio: '',
        junho: '',
        julho: '',
        agosto: '',
        setembro: '',
        outubro: '',
        novembro: '',
        dezembro: '',
        total: ''
      },
      2: {
        janeiro: '',
        fevereiro: '',
        marco: '',
        abril: '',
        maio: '',
        junho: '',
        julho: '',
        agosto: '',
        setembro: '',
        outubro: '',
        novembro: '',
        dezembro: '',
        total: ''
      },
      3: {
        janeiro: '',
        fevereiro: '',
        marco: '',
        abril: '',
        maio: '',
        junho: '',
        julho: '',
        agosto: '',
        setembro: '',
        outubro: '',
        novembro: '',
        dezembro: '',
        total: ''
      },
      4: {
        janeiro: '',
        fevereiro: '',
        marco: '',
        abril: '',
        maio: '',
        junho: '',
        julho: '',
        agosto: '',
        setembro: '',
        outubro: '',
        novembro: '',
        dezembro: '',
        total: ''
      },
      5: {
        janeiro: '',
        fevereiro: '',
        marco: '',
        abril: '',
        maio: '',
        junho: '',
        julho: '',
        agosto: '',
        setembro: '',
        outubro: '',
        novembro: '',
        dezembro: '',
        total: ''
      }
    },
    geracao: {
      janeiro: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      fevereiro: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      marco: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      abril: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      maio: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      junho: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      julho: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      agosto: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      setembro: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      outubro: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      novembro: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      dezembro: {
        consumo_atual: '',
        consumo_a_compensar: '',
        geracao: '',
        autonomia: '',
        excedente: ''
      },
      total: {
        consumo_a_compensar: '0',
        geracao: '0',
        autonomia: '0',
        excedente: '0'
      }
    },
    consumo_medio_mensal: 0,
    fator_irradiacao: '',
    adicionais: 0,
    adicionais_2: 0,
    adicionais_3: 0,
    adicionais_4: 0,
    adicionais_5: 0,
    irradiacao: {
      detalhado: {
        cidades: {
          1: {
            nome: 'Jundiaí',
            meses: {
              1: 6.852,
              2: 6.043,
              3: 5.293,
              4: 5.257,
              5: 4.447,
              6: 3.555,
              7: 3.485,
              8: 4.291,
              9: 4.914,
              10: 6.665,
              11: 6.161,
              12: 5.825,
              media: 5.23
            },
            desvio_media: {
              1: 1.31,
              2: 1.15,
              3: 1.01,
              4: 1,
              5: 0.85,
              6: 0.68,
              7: 0.67,
              8: 0.82,
              9: 0.94,
              10: 1.27,
              11: 1.18,
              12: 1.11
            }
          },
          2: {
            nome: 'São Paulo',
            meses: {
              1: 6.658,
              2: 5.38,
              3: 4.796,
              4: 5.053,
              5: 4.056,
              6: 3.317,
              7: 3.583,
              8: 3.999,
              9: 4.388,
              10: 5.875,
              11: 5.428,
              12: 5.825,
              media: 4.86
            },
            desvio_media: {
              1: 1.27,
              2: 1.11,
              3: 0.99,
              4: 1.04,
              5: 0.83,
              6: 0.68,
              7: 0.74,
              8: 0.82,
              9: 0.90,
              10: 1.21,
              11: 1.12,
              12: 1.20
            }
          },
          3: {
            nome: 'Riberão Preto',
            meses: {
              1: 7.207,
              2: 5.732,
              3: 5.506,
              4: 5.562,
              5: 4.541,
              6: 3.482,
              7: 3.84,
              8: 5.121,
              9: 5.765,
              10: 6.848,
              11: 6.523,
              12: 6.215,
              media: 5.53
            },
            desvio_media: {
              1: 1.38,
              2: 1.04,
              3: 1,
              4: 1.01,
              5: 0.82,
              6: 0.63,
              7: 0.69,
              8: 0.93,
              9: 1.04,
              10: 1.24,
              11: 1.18,
              12: 1.12
            }
          }
        }
      },
      simples: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        media: 0
      },
      potencial_medio_diario: 0,
      pontecial_medio_mensal: 0,
      potencial_medio_anual: 0,
      geracao_media_anual_swera: 0
    },
    taxas_fixas: {
      pis: 0.0165,
      cofins: 0.076,
      multiplicador: 0.27,
      custo_administrativo: 0.005,
      comissao_vendedor_interno: 0.009,
      comissao_representante: 0.05,
      custom_comissao_representante: false,
      preco_kwp_equipamentos: 3250,
      materiais_eletricos: 0.14,
      andaime: 0.02,
      reserva_servicos: 0.02,
      iss: 0,
      seguros: 0.0065
    },
    potencia_sistema_sugerida: 0,
    potencia_instalada: 0,
    autonomia_desejada: '100',
    potencia_do_sistema: 0,
    autonomia_atendida: 0,
    consumo_medio_antes_instalacao_sistema: 0,
    estimativa_geracao_media_mensal: 0,
    estimativa_geracao_media_anual: 0,
    custo_medio_aproximado_nova_fatura_energia: 0,
    economia_media_mensal_estimada: 0,
    economia_media_anual_estimada: 0,
    area_aproximada_instalacao: 0,
    tarifa_media_energia: 0,
    elaboracao_dimensionamento_projeto_total: 0,
    visita_tecnica_comercial: 0,
    analise_elaboracao_proposta: 0,
    visita_tecnica_levantamento: 0,
    elaboracao_dimensionamento_projeto: 0,
    processo_acesso_rede: 0,
    seguros: 0,
    instalacao_civil_eletrica_total: 0,
    frete: 0,
    materiais_eletricos: 0,
    transformador: 0,
    transformador_label: '',
    transformador_value: '',
    inversor: '',
    inversor_1: '',
    inversor_1_qtd: '',
    inversor_2: '',
    inversor_2_qtd: '',
    inversor_3: '',
    inversor_3_qtd: '',
    placa: '',
    placa_qtd: 0,
    diarias_equipe_instalacao: 0,
    deslocamento_hospedagem_equipe_instalacao: 0,
    alimentacao_equipe_instalacao: 0,
    andaime: 0,
    descarga_kit_fotovoltaico: 0,
    reserva_servicos: 0,
    obras_civis_total: 0,
    civil: 0,
    alvenaria: 0,
    aterramento: 0,
    pos_venda_total: 0,
    visita_tecnica_comercial_inspecao: 0,
    preco_do_kit_custom: 0,
    servico_o_m_1_visita: 0,
    servico_o_m_pacote_1_ano: 0,
    seguro: 0,
    impostos_taxas_total: 0,
    pis: 0,
    cofins: 0,
    iss: 0,
    custos_fixos_variaveis_total: 0,
    multiplicador: 0,
    custo_administrativo: 0,
    comissao_vendedor_interno: 0,
    comissao_representante: 0,
    comissao_representante_perc: 0,
    lucro_liquido_valor_total: 0,
    lucro_liquido_valor_total_perc: 0,
    lucro_liquido_servicos: 0,
    lucro_liquido_servicos_perc: 0,
    custom_lucro_liquido_servicos_perc: 0,
    custom_lucro: false,
    equipamentos: 0,
    total: 0,
    total_wp: 0,
    total_custos_diretos: 0,
    total_projeto_repensa: 0,
    final_projeto_repensa: 0,
    final_projeto_direto: 0,
    modulos: 0,
    descricao_adicionais: '',
    descricao_adicionais_2: '',
    descricao_adicionais_3: '',
    descricao_adicionais_4: '',
    descricao_adicionais_5: '',
    custom_data: {
      lucro: false
    }
  });

  const tratarNumero = useCallback((value) => {
    if (value === '' || value === ' ' || value === null) {
      value = '0';
    }

    value = value.replace(' ', '');
    value = value.replace('R$', '');
    value = value.replace('.', '');
    value = value.replace(',', '.');
    value = parseFloat(value);

    return value;
  }, []);

  // Funções de formatação
  const formatValue = useCallback((value, h) => {
    if (value === '') {
      return ''
    } else {
      value = parseFloat(value)
      return value.toFixed(h)
    }
  }, []);
  const formatPercentage = useCallback((value) => {
    if (value === '') {
      return ''
    } else {
      value = parseFloat(value)
      value = value * 100
      return `${value.toFixed(2)} %`
    }
  }, []);
  const formatCurrency = useCallback((value) => {
    if (value === '') {
      value = 0
    }
    value = parseFloat(value)

    return currencyFormatter.format(value)
  }, []);
  const formatKWP = useCallback((value) => {
    if (value === '') {
      value = 0
    }
    value = parseFloat(value)

    return `${numberFormatter.format(value.toFixed(2))} kWp`
  }, []);
  const formatFatorIrradiacao = useCallback((value) => {
    if (value === '' || value === ' ' || value === null) {
      value = 0;
    } else {
      value = parseFloat(value);
    }
    return numberFormatter.format(value);
  }, []);
  const formatConsumoMedioMensal = useCallback((value) => {
    if (value === '') {
      value = 0;
    }
    value = parseFloat(value);
    return numberFormatter.format(value.toFixed(0));
  }, []);

  const fillFormValues = useCallback((propostaCalc) => {
    const commmomValues = ['nome_do_cliente', 'cpf_cliente', 'email_cliente', 'telefone_cliente', 'tipo_projeto', 'prospeccao', 'faturamento', 'executivo_de_vendas', 'representante', 'cep_cliente', 'logradouro_cliente', 'bairro_cliente', 'municipio_cliente', 'estado_cliente', 'concessionaria', 'classificacao', 'demanda_contratada', 'tipo_de_instalacao', 'custo_de_disponibilidade', 'n_unidade_consumidora', 'placa', 'opcional_cerca', 'opcional_brita', 'opcional_garantia_estendida', 'opcional_estacao_solarimetrica', 'opcional_abrigo', 'autonomia_desejada', 'descricao_adicionais', 'descricao_adicionais_2', 'descricao_adicionais_3', 'descricao_adicionais_4', 'descricao_adicionais_5', 'inversor_1', 'inversor_1_qtd', 'inversor_2', 'inversor_2_qtd', 'inversor_3', 'inversor_3_qtd'];
    commmomValues.forEach((value) => {
      setValue(value, propostaCalc[value]);
    });

    const currencyValues = ['tarifa_media_energia', 'preco_do_kit_custom', 'visita_tecnica_comercial', 'analise_elaboracao_proposta', 'visita_tecnica_levantamento', 'elaboracao_dimensionamento_projeto', 'processo_acesso_rede', 'seguros', 'frete', 'materiais_eletricos', 'diarias_equipe_instalacao', 'deslocamento_hospedagem_equipe_instalacao', 'alimentacao_equipe_instalacao', 'andaime', 'descarga_kit_fotovoltaico', 'reserva_servicos', 'civil', 'alvenaria', 'aterramento', 'adicionais', 'adicionais_2', 'adicionais_3', 'adicionais_4', 'adicionais_5'];
    currencyValues.forEach((value) => {
      setValue(value, formatCurrency(propostaCalc[value]));
    });

    // Valores manuais/especiais
    setValue('fator_irradiacao', formatFatorIrradiacao(propostaCalc.fator_irradiacao));
    setValue('consumo_medio_mensal', formatConsumoMedioMensal(propostaCalc.consumo_medio_mensal));
    setValue('consumo_medio_anual_suprido', propostaCalc.geracao.total.consumo_a_compensar);
    setValue('geracao_media_anual_swera', (propostaCalc.irradiacao.geracao_media_anual_swera).toFixed(2));

    setValue('uc_1_janeiro', formatValue(propostaCalc.unidade_consumidora[1].janeiro, 0));
    setValue('uc_2_janeiro', formatValue(propostaCalc.unidade_consumidora[2].janeiro, 0));
    setValue('uc_3_janeiro', formatValue(propostaCalc.unidade_consumidora[3].janeiro, 0));
    setValue('uc_4_janeiro', formatValue(propostaCalc.unidade_consumidora[4].janeiro, 0));
    setValue('uc_5_janeiro', formatValue(propostaCalc.unidade_consumidora[5].janeiro, 0));
    setValue('uc_1_fevereiro', formatValue(propostaCalc.unidade_consumidora[1].fevereiro, 0));
    setValue('uc_2_fevereiro', formatValue(propostaCalc.unidade_consumidora[2].fevereiro, 0));
    setValue('uc_3_fevereiro', formatValue(propostaCalc.unidade_consumidora[3].fevereiro, 0));
    setValue('uc_4_fevereiro', formatValue(propostaCalc.unidade_consumidora[4].fevereiro, 0));
    setValue('uc_5_fevereiro', formatValue(propostaCalc.unidade_consumidora[5].fevereiro, 0));
    setValue('uc_1_marco', formatValue(propostaCalc.unidade_consumidora[1].marco, 0));
    setValue('uc_2_marco', formatValue(propostaCalc.unidade_consumidora[2].marco, 0));
    setValue('uc_3_marco', formatValue(propostaCalc.unidade_consumidora[3].marco, 0));
    setValue('uc_4_marco', formatValue(propostaCalc.unidade_consumidora[4].marco, 0));
    setValue('uc_5_marco', formatValue(propostaCalc.unidade_consumidora[5].marco, 0));
    setValue('uc_1_abril', formatValue(propostaCalc.unidade_consumidora[1].abril, 0));
    setValue('uc_2_abril', formatValue(propostaCalc.unidade_consumidora[2].abril, 0));
    setValue('uc_3_abril', formatValue(propostaCalc.unidade_consumidora[3].abril, 0));
    setValue('uc_4_abril', formatValue(propostaCalc.unidade_consumidora[4].abril, 0));
    setValue('uc_5_abril', formatValue(propostaCalc.unidade_consumidora[5].abril, 0));
    setValue('uc_1_maio', formatValue(propostaCalc.unidade_consumidora[1].maio, 0));
    setValue('uc_2_maio', formatValue(propostaCalc.unidade_consumidora[2].maio, 0));
    setValue('uc_3_maio', formatValue(propostaCalc.unidade_consumidora[3].maio, 0));
    setValue('uc_4_maio', formatValue(propostaCalc.unidade_consumidora[4].maio, 0));
    setValue('uc_5_maio', formatValue(propostaCalc.unidade_consumidora[5].maio, 0));
    setValue('uc_1_junho', formatValue(propostaCalc.unidade_consumidora[1].junho, 0));
    setValue('uc_2_junho', formatValue(propostaCalc.unidade_consumidora[2].junho, 0));
    setValue('uc_3_junho', formatValue(propostaCalc.unidade_consumidora[3].junho, 0));
    setValue('uc_4_junho', formatValue(propostaCalc.unidade_consumidora[4].junho, 0));
    setValue('uc_5_junho', formatValue(propostaCalc.unidade_consumidora[5].junho, 0));
    setValue('uc_1_julho', formatValue(propostaCalc.unidade_consumidora[1].julho, 0));
    setValue('uc_2_julho', formatValue(propostaCalc.unidade_consumidora[2].julho, 0));
    setValue('uc_3_julho', formatValue(propostaCalc.unidade_consumidora[3].julho, 0));
    setValue('uc_4_julho', formatValue(propostaCalc.unidade_consumidora[4].julho, 0));
    setValue('uc_5_julho', formatValue(propostaCalc.unidade_consumidora[5].julho, 0));
    setValue('uc_1_agosto', formatValue(propostaCalc.unidade_consumidora[1].agosto, 0));
    setValue('uc_2_agosto', formatValue(propostaCalc.unidade_consumidora[2].agosto, 0));
    setValue('uc_3_agosto', formatValue(propostaCalc.unidade_consumidora[3].agosto, 0));
    setValue('uc_4_agosto', formatValue(propostaCalc.unidade_consumidora[4].agosto, 0));
    setValue('uc_5_agosto', formatValue(propostaCalc.unidade_consumidora[5].agosto, 0));
    setValue('uc_1_setembro', formatValue(propostaCalc.unidade_consumidora[1].setembro, 0));
    setValue('uc_2_setembro', formatValue(propostaCalc.unidade_consumidora[2].setembro, 0));
    setValue('uc_3_setembro', formatValue(propostaCalc.unidade_consumidora[3].setembro, 0));
    setValue('uc_4_setembro', formatValue(propostaCalc.unidade_consumidora[4].setembro, 0));
    setValue('uc_5_setembro', formatValue(propostaCalc.unidade_consumidora[5].setembro, 0));
    setValue('uc_1_outubro', formatValue(propostaCalc.unidade_consumidora[1].outubro, 0));
    setValue('uc_2_outubro', formatValue(propostaCalc.unidade_consumidora[2].outubro, 0));
    setValue('uc_3_outubro', formatValue(propostaCalc.unidade_consumidora[3].outubro, 0));
    setValue('uc_4_outubro', formatValue(propostaCalc.unidade_consumidora[4].outubro, 0));
    setValue('uc_5_outubro', formatValue(propostaCalc.unidade_consumidora[5].outubro, 0));
    setValue('uc_1_novembro', formatValue(propostaCalc.unidade_consumidora[1].novembro, 0));
    setValue('uc_2_novembro', formatValue(propostaCalc.unidade_consumidora[2].novembro, 0));
    setValue('uc_3_novembro', formatValue(propostaCalc.unidade_consumidora[3].novembro, 0));
    setValue('uc_4_novembro', formatValue(propostaCalc.unidade_consumidora[4].novembro, 0));
    setValue('uc_5_novembro', formatValue(propostaCalc.unidade_consumidora[5].novembro, 0));
    setValue('uc_1_dezembro', formatValue(propostaCalc.unidade_consumidora[1].dezembro, 0));
    setValue('uc_2_dezembro', formatValue(propostaCalc.unidade_consumidora[2].dezembro, 0));
    setValue('uc_3_dezembro', formatValue(propostaCalc.unidade_consumidora[3].dezembro, 0));
    setValue('uc_4_dezembro', formatValue(propostaCalc.unidade_consumidora[4].dezembro, 0));
    setValue('uc_5_dezembro', formatValue(propostaCalc.unidade_consumidora[5].dezembro, 0));
    setValue('uc_1_total', formatValue(propostaCalc.unidade_consumidora[1].total, 0));
    setValue('uc_2_total', formatValue(propostaCalc.unidade_consumidora[2].total, 0));
    setValue('uc_3_total', formatValue(propostaCalc.unidade_consumidora[3].total, 0));
    setValue('uc_4_total', formatValue(propostaCalc.unidade_consumidora[4].total, 0));
    setValue('uc_5_total', formatValue(propostaCalc.unidade_consumidora[5].total, 0));

    setValue('placa_qtd', formatValue(propostaCalc.modulos, 0));
    setValue('transformador', propostaCalc.transformador_value);
    setValue('transformador_field', formatCurrency(propostaCalc.transformador));

    setValue('iss', formatPercentage(propostaCalc.taxas_fixas.iss));
    setValue('custo_administrativo', formatPercentage(propostaCalc.taxas_fixas.custo_administrativo));
    setValue('comissao_vendedor_interno', formatPercentage(propostaCalc.taxas_fixas.comissao_vendedor_interno));
    setValue('lucro_liquido_servicos_perc', formatPercentage(propostaCalc.lucro_liquido_servicos_perc));

    setValue('proposta_potencia_do_sistema', `${formatValue(propostaCalc.potencia_do_sistema, 2)} kWp`);
    setValue('potencia_sistema_sugerida', formatKWP(propostaCalc.potencia_sistema_sugerida));
    setValue('potencia_instalada', formatKWP(propostaCalc.potencia_instalada));
    setValue('autonomia_atendida', `${(propostaCalc.autonomia_atendida).toFixed(0)} %`);
    setValue('area_aproximada_instalacao', `${(propostaCalc.area_aproximada_instalacao).toFixed(0)} m²`);
    setValue('consumo_medio_mensal_2', formatKWP(propostaCalc.consumo_medio_mensal));
    setValue('estimativa_geracao_media_mensal', formatKWP(propostaCalc.estimativa_geracao_media_mensal));
    setValue('estimativa_geracao_media_anual', formatKWP(propostaCalc.estimativa_geracao_media_anual));
    setValue('custo_medio_aproximado_nova_fatura_energia', formatCurrency(propostaCalc.custo_medio_aproximado_nova_fatura_energia));
    setValue('economia_media_mensal_estimada', formatCurrency(propostaCalc.economia_media_mensal_estimada));
    setValue('economia_media_anual_estimada', formatCurrency(propostaCalc.economia_media_anual_estimada));

    setValue('total_geral', formatCurrency(propostaCalc.total));
    setValue('total_rs_wp', formatCurrency(propostaCalc.total_wp));
    // setValue('total_projeto_repensa', formatCurrency(propostaCalc.total_projeto_repensa));
    // setValue('total_projeto_direto', formatCurrency(propostaCalc.total_custos_diretos));
    setValue('total_projeto_repensa', formatCurrency(propostaCalc.final_projeto_repensa));
    setValue('total_projeto_direto', formatCurrency(propostaCalc.final_projeto_direto));

    // Dados de geração
    setValue('proposta_geracao_janeiro_consumo_atual', propostaCalc.geracao.janeiro.consumo_atual);
    setValue('proposta_geracao_janeiro_consumo_a_compensar', formatValue(propostaCalc.geracao.janeiro.consumo_a_compensar, 0));
    setValue('proposta_geracao_janeiro_geracao', formatValue(propostaCalc.geracao.janeiro.geracao, 0));
    setValue('proposta_geracao_janeiro_autonomia', `${formatValue(propostaCalc.geracao.janeiro.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_janeiro_excedente', formatValue(propostaCalc.geracao.janeiro.excedente, 0));
    setValue('proposta_geracao_fevereiro_consumo_atual', propostaCalc.geracao.fevereiro.consumo_atual);
    setValue('proposta_geracao_fevereiro_consumo_a_compensar', formatValue(propostaCalc.geracao.fevereiro.consumo_a_compensar, 0));
    setValue('proposta_geracao_fevereiro_geracao', formatValue(propostaCalc.geracao.fevereiro.geracao, 0));
    setValue('proposta_geracao_fevereiro_autonomia', `${formatValue(propostaCalc.geracao.fevereiro.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_fevereiro_excedente', formatValue(propostaCalc.geracao.fevereiro.excedente, 0));
    setValue('proposta_geracao_marco_consumo_atual', propostaCalc.geracao.marco.consumo_atual);
    setValue('proposta_geracao_marco_consumo_a_compensar', formatValue(propostaCalc.geracao.marco.consumo_a_compensar, 0));
    setValue('proposta_geracao_marco_geracao', formatValue(propostaCalc.geracao.marco.geracao, 0));
    setValue('proposta_geracao_marco_autonomia', `${formatValue(propostaCalc.geracao.marco.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_marco_excedente', formatValue(propostaCalc.geracao.marco.excedente, 0));
    setValue('proposta_geracao_abril_consumo_atual', propostaCalc.geracao.abril.consumo_atual);
    setValue('proposta_geracao_abril_consumo_a_compensar', formatValue(propostaCalc.geracao.abril.consumo_a_compensar, 0));
    setValue('proposta_geracao_abril_geracao', formatValue(propostaCalc.geracao.abril.geracao, 0));
    setValue('proposta_geracao_abril_autonomia', `${formatValue(propostaCalc.geracao.abril.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_abril_excedente', formatValue(propostaCalc.geracao.abril.excedente, 0));
    setValue('proposta_geracao_maio_consumo_atual', propostaCalc.geracao.maio.consumo_atual);
    setValue('proposta_geracao_maio_consumo_a_compensar', formatValue(propostaCalc.geracao.maio.consumo_a_compensar, 0));
    setValue('proposta_geracao_maio_geracao', formatValue(propostaCalc.geracao.maio.geracao, 0));
    setValue('proposta_geracao_maio_autonomia', `${formatValue(propostaCalc.geracao.maio.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_maio_excedente', formatValue(propostaCalc.geracao.maio.excedente, 0));
    setValue('proposta_geracao_junho_consumo_atual', propostaCalc.geracao.junho.consumo_atual);
    setValue('proposta_geracao_junho_consumo_a_compensar', formatValue(propostaCalc.geracao.junho.consumo_a_compensar, 0));
    setValue('proposta_geracao_junho_geracao', formatValue(propostaCalc.geracao.junho.geracao, 0));
    setValue('proposta_geracao_junho_autonomia', `${formatValue(propostaCalc.geracao.junho.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_junho_excedente', formatValue(propostaCalc.geracao.junho.excedente, 0));
    setValue('proposta_geracao_julho_consumo_atual', propostaCalc.geracao.julho.consumo_atual);
    setValue('proposta_geracao_julho_consumo_a_compensar', formatValue(propostaCalc.geracao.julho.consumo_a_compensar, 0));
    setValue('proposta_geracao_julho_geracao', formatValue(propostaCalc.geracao.julho.geracao, 0));
    setValue('proposta_geracao_julho_autonomia', `${formatValue(propostaCalc.geracao.julho.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_julho_excedente', formatValue(propostaCalc.geracao.julho.excedente, 0));
    setValue('proposta_geracao_agosto_consumo_atual', propostaCalc.geracao.agosto.consumo_atual);
    setValue('proposta_geracao_agosto_consumo_a_compensar', formatValue(propostaCalc.geracao.agosto.consumo_a_compensar, 0));
    setValue('proposta_geracao_agosto_geracao', formatValue(propostaCalc.geracao.agosto.geracao, 0));
    setValue('proposta_geracao_agosto_autonomia', `${formatValue(propostaCalc.geracao.agosto.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_agosto_excedente', formatValue(propostaCalc.geracao.agosto.excedente, 0));
    setValue('proposta_geracao_setembro_consumo_atual', propostaCalc.geracao.setembro.consumo_atual);
    setValue('proposta_geracao_setembro_consumo_a_compensar', formatValue(propostaCalc.geracao.setembro.consumo_a_compensar, 0));
    setValue('proposta_geracao_setembro_geracao', formatValue(propostaCalc.geracao.setembro.geracao, 0));
    setValue('proposta_geracao_setembro_autonomia', `${formatValue(propostaCalc.geracao.setembro.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_setembro_excedente', formatValue(propostaCalc.geracao.setembro.excedente, 0));
    setValue('proposta_geracao_outubro_consumo_atual', propostaCalc.geracao.outubro.consumo_atual);
    setValue('proposta_geracao_outubro_consumo_a_compensar', formatValue(propostaCalc.geracao.outubro.consumo_a_compensar, 0));
    setValue('proposta_geracao_outubro_geracao', formatValue(propostaCalc.geracao.outubro.geracao, 0));
    setValue('proposta_geracao_outubro_autonomia', `${formatValue(propostaCalc.geracao.outubro.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_outubro_excedente', formatValue(propostaCalc.geracao.outubro.excedente, 0));
    setValue('proposta_geracao_novembro_consumo_atual', propostaCalc.geracao.novembro.consumo_atual);
    setValue('proposta_geracao_novembro_consumo_a_compensar', formatValue(propostaCalc.geracao.novembro.consumo_a_compensar, 0));
    setValue('proposta_geracao_novembro_geracao', formatValue(propostaCalc.geracao.novembro.geracao, 0));
    setValue('proposta_geracao_novembro_autonomia', `${formatValue(propostaCalc.geracao.novembro.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_novembro_excedente', formatValue(propostaCalc.geracao.novembro.excedente, 0));
    setValue('proposta_geracao_dezembro_consumo_atual', propostaCalc.geracao.dezembro.consumo_atual);
    setValue('proposta_geracao_dezembro_consumo_a_compensar', formatValue(propostaCalc.geracao.dezembro.consumo_a_compensar, 0));
    setValue('proposta_geracao_dezembro_geracao', formatValue(propostaCalc.geracao.dezembro.geracao, 0));
    setValue('proposta_geracao_dezembro_autonomia', `${formatValue(propostaCalc.geracao.dezembro.autonomia * 100, 0)} %`);
    setValue('proposta_geracao_dezembro_excedente', formatValue(propostaCalc.geracao.dezembro.excedente, 0));
    setValue('proposta_geracao_total_consumo_a_compensar', propostaCalc.geracao.total.consumo_a_compensar);
    setValue('proposta_geracao_total_geracao', formatValue(propostaCalc.geracao.total.geracao, 0));

    // Impostos/taxas especiais
    setValue('proposta_comissao_vendedor_interno', formatCurrency(propostaCalc.comissao_vendedor_interno));
    setValue('proposta_comissao_representante_perc', formatPercentage(propostaCalc.comissao_representante_perc));
    setValue('proposta_comissao_representante', formatCurrency(propostaCalc.comissao_representante));
    setValue('proposta_lucro_liquido_valor_total_perc', formatPercentage(propostaCalc.lucro_liquido_valor_total_perc));
    setValue('proposta_lucro_liquido_valor_total', formatCurrency(propostaCalc.lucro_liquido_valor_total));
    setValue('proposta_taxas_fixas_preco_kwp_equipamentos', formatCurrency(propostaCalc.taxas_fixas.preco_kwp_equipamentos));
    setValue('proposta_equipamentos', formatCurrency(propostaCalc.equipamentos));

    setValue('proposta_elaboracao_dimensionamento_projeto_total', formatCurrency(propostaCalc.elaboracao_dimensionamento_projeto_total));
    setValue('proposta_instalacao_civil_eletrica_total', formatCurrency(propostaCalc.instalacao_civil_eletrica_total));
    setValue('proposta_obras_civis_total', formatCurrency(propostaCalc.obras_civis_total));
    setValue('proposta_iss', formatCurrency(propostaCalc.iss));
    setValue('proposta_custos_fixos_variaveis_total', formatCurrency(propostaCalc.custos_fixos_variaveis_total));
    setValue('proposta_custo_administrativo', formatCurrency(propostaCalc.custo_administrativo));

  }, [setValue]);

  const setCustomDataField = useCallback((field) => {
    const propostaCalc = proposta;
    let haveToUpdate = false;

    if (propostaCalc.custom_data) { 

    } else {
      propostaCalc.custom_data = {};
      haveToUpdate = true;
    }

    if (propostaCalc && propostaCalc.custom_data && propostaCalc.custom_data[field]) {

    } else {
      propostaCalc.custom_data[field] = true; haveToUpdate = true;
    }
    
    if (haveToUpdate) {
      setProposta(propostaCalc);
      setTimeout(() => fillFormValues(propostaCalc), 500);
    }
  }, [proposta]);

  const calculate = useCallback((evt, previousProposta = null) => {
    const propostaCalc = (previousProposta) ? previousProposta : proposta;
    const data = getValues();

    propostaCalc.nome_do_cliente = data.nome_do_cliente;
    propostaCalc.cpf_cliente = data.cpf_cliente;
    propostaCalc.email_cliente = data.email_cliente;
    propostaCalc.telefone_cliente = data.telefone_cliente;
    propostaCalc.tipo_projeto = data.tipo_projeto;
    propostaCalc.cep_cliente = data.cep_cliente;
    propostaCalc.logradouro_cliente = data.logradouro_cliente;
    propostaCalc.bairro_cliente = data.bairro_cliente;
    propostaCalc.municipio_cliente = data.municipio_cliente;
    propostaCalc.estado_cliente = data.estado_cliente;
    propostaCalc.concessionaria = data.concessionaria;
    propostaCalc.demanda_contratada = data.demanda_contratada;
    propostaCalc.n_unidade_consumidora = data.n_unidade_consumidora;
    propostaCalc.classificacao = data.classificacao;

    propostaCalc.executivo_de_vendas = data.executivo_de_vendas;
    propostaCalc.representante = data.representante;

    if (propostaCalc.executivo_de_vendas) {
      executivos.map((value) => {
        if (parseInt(value.id) === parseInt(propostaCalc.executivo_de_vendas)) {
          propostaCalc.executivo_de_vendas_nome = value.name;
          propostaCalc.executivo_de_vendas_telefone = value.telefone;
        }
      });
    } else if (propostaCalc.executivo_de_vendas === '') {
      propostaCalc.executivo_de_vendas_nome = '';
      propostaCalc.executivo_de_vendas_telefone = '';
    }

    if (propostaCalc.representante) {
      representantes.map((value) => {
        if (parseInt(value.id) === parseInt(propostaCalc.representante)) {
          propostaCalc.representante_nome = value.nome;
          propostaCalc.representante_telefone = value.telefone;
        }
      });
    } else if (propostaCalc.representante === '') {
      propostaCalc.representante_nome = '';
      propostaCalc.representante_telefone = '';
    }

    propostaCalc.custo_de_disponibilidade = data.custo_de_disponibilidade
    propostaCalc.faturamento = data.faturamento
    propostaCalc.fator_irradiacao = tratarNumero(data.fator_irradiacao);
    propostaCalc.autonomia_desejada = data.autonomia_desejada
    propostaCalc.tarifa_media_energia = tratarNumero(data.tarifa_media_energia);
    propostaCalc.tipo_de_instalacao = data.tipo_de_instalacao
    propostaCalc.prospeccao = data.prospeccao

    propostaCalc.preco_do_kit_custom = tratarNumero(data.preco_do_kit_custom);

    propostaCalc.opcional_cerca = data.opcional_cerca;
    propostaCalc.opcional_brita = data.opcional_brita;
    propostaCalc.opcional_garantia_estendida = data.opcional_garantia_estendida;
    propostaCalc.opcional_estacao_solarimetrica = data.opcional_estacao_solarimetrica;
    propostaCalc.opcional_abrigo = data.opcional_abrigo;

    propostaCalc.inversor_1 = data.inversor_1;
    propostaCalc.inversor_1_qtd = data.inversor_1_qtd;
    propostaCalc.inversor_2 = data.inversor_2;
    propostaCalc.inversor_2_qtd = data.inversor_2_qtd;
    propostaCalc.inversor_3 = data.inversor_3;
    propostaCalc.inversor_3_qtd = data.inversor_3_qtd;

    let tmpTransformador = data.transformador;
    propostaCalc.transformador_value = tmpTransformador;
    
    // Transformador
    if (tmpTransformador !== '' && tmpTransformador !== ' ' && tmpTransformador !== null && tmpTransformador !== undefined) {
      tmpTransformador = tmpTransformador.split(' || ');
      if (tmpTransformador && tmpTransformador[0] && tmpTransformador[0] !== '' && tmpTransformador[0] !== ' ' && tmpTransformador[0] !== null && tmpTransformador[0] !== undefined) {
        if (propostaCalc.custom_data && propostaCalc.custom_data.transformador_field) {
          propostaCalc.transformador = tratarNumero(data.transformador_field);
        } else {
          propostaCalc.transformador = parseFloat(tmpTransformador[0]);
        }
      }
      if (tmpTransformador && tmpTransformador[1] && tmpTransformador[1] !== '' && tmpTransformador[1] !== ' ' && tmpTransformador[1] !== null && tmpTransformador[1] !== undefined) {
        propostaCalc.transformador_label = tmpTransformador[1];
      }
    } else {
      if (propostaCalc.custom_data && propostaCalc.custom_data.transformador_field) {
        propostaCalc.transformador = tratarNumero(data.transformador_field);
      } else {
        propostaCalc.transformador = 0;
      }
    }
    
    let potenciaPlaca = 500;
    propostaCalc.placa = data.placa;
    if (propostaCalc.placa) {
      const matches = propostaCalc.placa.match(/\[(.*?)\]/);
      if (matches) {
        const submatch = matches[1];
        if (submatch) { potenciaPlaca = parseInt(submatch); }
      }
    }

    // Mudança do custo administrativo
    if (data.custo_administrativo) {
      propostaCalc.taxas_fixas.custo_administrativo = parseFloat(data.custo_administrativo) / 100;
    }

    // Mudança da comissão vendedor interno
    if (data.comissao_vendedor_interno) {
      propostaCalc.taxas_fixas.comissao_vendedor_interno = parseFloat(data.comissao_vendedor_interno) / 100;
    }

    // Customização do lucro
    if (propostaCalc.custom_data.lucro) {
      propostaCalc.custom_lucro = true;
    } else {
      propostaCalc.custom_lucro = false;
    }
    if (propostaCalc.custom_lucro) {
      let tmpLucroLiquidoServicos = data.lucro_liquido_servicos_perc
      tmpLucroLiquidoServicos = tmpLucroLiquidoServicos.replace(' ', '')
      tmpLucroLiquidoServicos = tmpLucroLiquidoServicos.replace('%', '')
      tmpLucroLiquidoServicos = parseFloat(tmpLucroLiquidoServicos)
      tmpLucroLiquidoServicos = tmpLucroLiquidoServicos / 100

      if (tmpLucroLiquidoServicos !== propostaCalc.lucro_liquido_servicos_perc) {
        propostaCalc.lucro_liquido_servicos_perc = tmpLucroLiquidoServicos;
      }
    }

    // Custom comissão do representante
    if (propostaCalc.custom_data.comissao_representante) {
      if (data.proposta_comissao_representante_perc) {
        propostaCalc.taxas_fixas.comissao_representante = parseFloat(data.proposta_comissao_representante_perc) / 100;
      } else {
        propostaCalc.taxas_fixas.comissao_representante = 0;
      }
    }

    // Custom ISS
    if (propostaCalc.custom_data.iss) {
      if (data.iss) {
        propostaCalc.taxas_fixas.iss = parseFloat(data.iss) / 100; 
      } else {
        propostaCalc.taxas_fixas.iss = 0;
      }
    }

    // Custom quantidade de placas
    if (data.placa_qtd) {
      propostaCalc.placa_qtd = parseInt(data.placa_qtd);
    }

    propostaCalc.descricao_adicionais = data.descricao_adicionais;
    propostaCalc.descricao_adicionais_2 = data.descricao_adicionais_2;
    propostaCalc.descricao_adicionais_3 = data.descricao_adicionais_3;
    propostaCalc.descricao_adicionais_4 = data.descricao_adicionais_4;
    propostaCalc.descricao_adicionais_5 = data.descricao_adicionais_5;
    if (data.adicionais) propostaCalc.adicionais = tratarNumero(data.adicionais);
    if (data.adicionais_2) propostaCalc.adicionais_2 = tratarNumero(data.adicionais_2);
    if (data.adicionais_3) propostaCalc.adicionais_3 = tratarNumero(data.adicionais_3);
    if (data.adicionais_4) propostaCalc.adicionais_4 = tratarNumero(data.adicionais_4);
    if (data.adicionais_5) propostaCalc.adicionais_5 = tratarNumero(data.adicionais_5);

    // Getting custom data
    if (propostaCalc.custom_data && propostaCalc.custom_data.visita_tecnica_comercial) {
      propostaCalc.visita_tecnica_comercial = tratarNumero(data.visita_tecnica_comercial);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.analise_elaboracao_proposta) {
      propostaCalc.analise_elaboracao_proposta = tratarNumero(data.analise_elaboracao_proposta);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.visita_tecnica_levantamento) {
      propostaCalc.visita_tecnica_levantamento = tratarNumero(data.visita_tecnica_levantamento);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.processo_acesso_rede) {
      propostaCalc.processo_acesso_rede = tratarNumero(data.processo_acesso_rede);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.frete) {
      propostaCalc.frete = tratarNumero(data.frete);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.deslocamento_hospedagem_equipe_instalacao) {
      propostaCalc.deslocamento_hospedagem_equipe_instalacao = tratarNumero(data.deslocamento_hospedagem_equipe_instalacao);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.alimentacao_equipe_instalacao) {
      propostaCalc.alimentacao_equipe_instalacao = tratarNumero(data.alimentacao_equipe_instalacao);
    }
    
    if (propostaCalc.custom_data && propostaCalc.custom_data.civil) {
      propostaCalc.civil = tratarNumero(data.civil);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.alvenaria) {
      propostaCalc.alvenaria = tratarNumero(data.alvenaria);
    }
    if (propostaCalc.custom_data && propostaCalc.custom_data.aterramento) {
      propostaCalc.aterramento = tratarNumero(data.aterramento);
    }

    // Unidades consumidoras
    for (let i = 1; i <= 5; i++) {
      meses.map((value) => {
        if (typeof data[`uc_${i}_${value}`] !== undefined && data[`uc_${i}_${value}`]) {
          propostaCalc.unidade_consumidora[i][value] = data[`uc_${i}_${value}`]
        }
        return true
      })

      let soma = 0
      meses.map((value) => {
        if (propostaCalc.unidade_consumidora[i][value]) {
          soma += parseFloat(propostaCalc.unidade_consumidora[i][value])
        }
        return true
      })
      propostaCalc.unidade_consumidora[i].total = soma.toString()
    }

    // Cálculo geração
    let somaConsumoACompensar = 0
    let somaConsumoAtual = 0
    meses.map((value) => {
      let soma = 0
      for (let i = 1; i <= 5; i++) {
        if (propostaCalc.unidade_consumidora[i][value]) {
          soma += parseFloat(propostaCalc.unidade_consumidora[i][value])
        }
      }

      propostaCalc.geracao[value].consumo_atual = soma.toString()
      somaConsumoAtual += soma

      if (soma > 0 && propostaCalc.custo_de_disponibilidade !== '') {
        const consumoACompensar = soma - parseFloat(propostaCalc.custo_de_disponibilidade)
        propostaCalc.geracao[value].consumo_a_compensar = (consumoACompensar).toString()
        somaConsumoACompensar += consumoACompensar
      }

      return true;
    });

    propostaCalc.geracao.total.consumo_a_compensar = somaConsumoACompensar
    propostaCalc.consumo_medio_mensal = somaConsumoAtual / 12

    // Cálculo irradiação
    if (propostaCalc.fator_irradiacao !== '') {
      propostaCalc.irradiacao.simples.media = parseFloat(propostaCalc.fator_irradiacao)

      for (let i = 1; i <= 12; i++) {
        const media = (propostaCalc.irradiacao.detalhado.cidades[1].desvio_media[i] + propostaCalc.irradiacao.detalhado.cidades[2].desvio_media[i] + propostaCalc.irradiacao.detalhado.cidades[3].desvio_media[i]) / 3
        propostaCalc.irradiacao.simples[i] = media * propostaCalc.irradiacao.simples.media
      }

      propostaCalc.irradiacao.potencial_medio_diario = propostaCalc.irradiacao.simples.media
      propostaCalc.irradiacao.pontecial_medio_mensal = propostaCalc.irradiacao.potencial_medio_diario * 30
      propostaCalc.irradiacao.potencial_medio_anual = propostaCalc.irradiacao.pontecial_medio_mensal * 12
      propostaCalc.irradiacao.geracao_media_anual_swera = propostaCalc.irradiacao.potencial_medio_anual * 0.75
    }

    // Potência do sistema sugerida
    propostaCalc.potencia_sistema_sugerida = propostaCalc.geracao.total.consumo_a_compensar / propostaCalc.irradiacao.geracao_media_anual_swera;

    // Cálculo da autonomia
    if (propostaCalc.custom_data.placa_qtd) {
      propostaCalc.modulos = propostaCalc.placa_qtd;
      propostaCalc.potencia_instalada = (propostaCalc.placa_qtd * potenciaPlaca) / 1000;
      propostaCalc.autonomia_atendida = (propostaCalc.potencia_instalada / propostaCalc.potencia_sistema_sugerida) * 100;
    } else {
      if (propostaCalc.autonomia_desejada !== '') {
        propostaCalc.autonomia_atendida = parseFloat(propostaCalc.autonomia_desejada);
        propostaCalc.potencia_instalada = (propostaCalc.autonomia_atendida / 100) * propostaCalc.potencia_sistema_sugerida;
      }
    }

    // Demais cálculos
    let somaGeracao = 0
    meses.map((value, index) => {
      propostaCalc.geracao[value].geracao = propostaCalc.potencia_instalada * propostaCalc.irradiacao.simples[index + 1] * 30 * 0.75
      if (propostaCalc.geracao[value].consumo_a_compensar) {
        propostaCalc.geracao[value].autonomia = propostaCalc.geracao[value].geracao / propostaCalc.geracao[value].consumo_a_compensar
      }
      propostaCalc.geracao[value].excedente = propostaCalc.geracao[value].geracao - propostaCalc.geracao[value].consumo_atual
      somaGeracao += propostaCalc.geracao[value].geracao
    })
    propostaCalc.geracao.total.geracao = somaGeracao

    propostaCalc.estimativa_geracao_media_mensal = propostaCalc.geracao.total.geracao / 12
    propostaCalc.estimativa_geracao_media_anual = propostaCalc.geracao.total.geracao

    if (propostaCalc.estimativa_geracao_media_mensal > propostaCalc.consumo_medio_mensal) {
      propostaCalc.custo_medio_aproximado_nova_fatura_energia = parseFloat(propostaCalc.custo_de_disponibilidade) * propostaCalc.tarifa_media_energia;
    } else {
      propostaCalc.custo_medio_aproximado_nova_fatura_energia = (propostaCalc.consumo_medio_mensal - propostaCalc.estimativa_geracao_media_mensal) * propostaCalc.tarifa_media_energia;
    }

    propostaCalc.economia_media_mensal_estimada = propostaCalc.estimativa_geracao_media_mensal * propostaCalc.tarifa_media_energia;
    propostaCalc.economia_media_anual_estimada = propostaCalc.geracao.total.geracao * propostaCalc.tarifa_media_energia;

    propostaCalc.area_aproximada_instalacao = propostaCalc.potencia_instalada * 6.5;

    propostaCalc.potencia_do_sistema = propostaCalc.potencia_instalada;

    // Potência do sistema
    const potenciaDoSistemaWP = propostaCalc.potencia_do_sistema * 1000;

    // Elaboração dimensionamento projeto
    if (propostaCalc.custom_data && propostaCalc.custom_data.elaboracao_dimensionamento_projeto) {
      propostaCalc.elaboracao_dimensionamento_projeto = tratarNumero(data.elaboracao_dimensionamento_projeto);
    } else {
      if (potenciaDoSistemaWP <= 5000) {
        propostaCalc.elaboracao_dimensionamento_projeto = 500;
      } else if (potenciaDoSistemaWP <= 10000) {
        propostaCalc.elaboracao_dimensionamento_projeto = 700;
      } else if (potenciaDoSistemaWP <= 20000) {
        propostaCalc.elaboracao_dimensionamento_projeto = 1000;
      } else if (potenciaDoSistemaWP <= 50000) {
        propostaCalc.elaboracao_dimensionamento_projeto = 1500;
      } else if (potenciaDoSistemaWP <= 100000) {
        propostaCalc.elaboracao_dimensionamento_projeto = 7500;
      } else {
        propostaCalc.elaboracao_dimensionamento_projeto = 10000;
      }
    }    

    // Preço do KIT FOTOVOLTAICO
    propostaCalc.taxas_fixas.preco_kwp_equipamentos = getPrecoMedioEquipamentos(propostaCalc.potencia_do_sistema);
    if (propostaCalc.preco_do_kit_custom > 0) {
      propostaCalc.equipamentos = propostaCalc.preco_do_kit_custom;
    } else {
      propostaCalc.equipamentos = propostaCalc.potencia_do_sistema * propostaCalc.taxas_fixas.preco_kwp_equipamentos;
    }
    

    // Seguros
    if (propostaCalc.custom_data && propostaCalc.custom_data.seguros) {
      propostaCalc.seguros = tratarNumero(data.seguros);
    } else {
      propostaCalc.seguros = propostaCalc.equipamentos * propostaCalc.taxas_fixas.seguros;
    }    

    // Total da elaboração e dimensionamento do projeto
    propostaCalc.elaboracao_dimensionamento_projeto_total = propostaCalc.visita_tecnica_comercial + propostaCalc.analise_elaboracao_proposta + propostaCalc.visita_tecnica_levantamento + propostaCalc.elaboracao_dimensionamento_projeto + propostaCalc.processo_acesso_rede + propostaCalc.seguros;

    // Instalação Civil e Elétrica

    if (potenciaDoSistemaWP) {
      // Materiais elétricos
      if (propostaCalc.custom_data && propostaCalc.custom_data.materiais_eletricos) {
        propostaCalc.materiais_eletricos = tratarNumero(data.materiais_eletricos);
      } else {
        propostaCalc.materiais_eletricos = 400 + (propostaCalc.taxas_fixas.materiais_eletricos * potenciaDoSistemaWP);
      }

      // Quantidade de módulos
      let quantidadeDeModulos;
      if (propostaCalc.custom_data.placa_qtd) {
        propostaCalc.modulos = propostaCalc.modulos;
        quantidadeDeModulos = propostaCalc.modulos;
      } else {
        quantidadeDeModulos = potenciaDoSistemaWP / potenciaPlaca;
        propostaCalc.modulos = quantidadeDeModulos;
      }

      // Diárias equipe de instalação
      if (propostaCalc.custom_data && propostaCalc.custom_data.diarias_equipe_instalacao) {
        propostaCalc.diarias_equipe_instalacao = tratarNumero(data.diarias_equipe_instalacao);
      } else {
        if (potenciaDoSistemaWP < 50000) {
          propostaCalc.diarias_equipe_instalacao = quantidadeDeModulos * 110;
        } else if (potenciaDoSistemaWP >= 50000 && potenciaDoSistemaWP < 100000) {
          propostaCalc.diarias_equipe_instalacao = quantidadeDeModulos * 100;
        } else {
          propostaCalc.diarias_equipe_instalacao = quantidadeDeModulos * 90;
        }
      }

      // Andaime
      if (propostaCalc.custom_data && propostaCalc.custom_data.andaime) {
        propostaCalc.andaime = tratarNumero(data.andaime);
      } else {
        propostaCalc.andaime = propostaCalc.taxas_fixas.andaime * potenciaDoSistemaWP;
      }      

      // Descarga do KIT
      if (propostaCalc.custom_data && propostaCalc.custom_data.descarga_kit_fotovoltaico) {
        propostaCalc.descarga_kit_fotovoltaico = tratarNumero(data.descarga_kit_fotovoltaico);
      } else {
        propostaCalc.descarga_kit_fotovoltaico = 300;
      }

      // Reserva de serviços
      if (propostaCalc.custom_data && propostaCalc.custom_data.reserva_servicos) {
        propostaCalc.reserva_servicos = tratarNumero(data.reserva_servicos);
      } else {
        propostaCalc.reserva_servicos = propostaCalc.taxas_fixas.reserva_servicos * potenciaDoSistemaWP;
      }
      
    }

    propostaCalc.instalacao_civil_eletrica_total = propostaCalc.frete + propostaCalc.materiais_eletricos + propostaCalc.transformador + propostaCalc.diarias_equipe_instalacao + propostaCalc.deslocamento_hospedagem_equipe_instalacao + propostaCalc.alimentacao_equipe_instalacao + propostaCalc.andaime + propostaCalc.descarga_kit_fotovoltaico + propostaCalc.reserva_servicos

    // Obras Civis

    if (potenciaDoSistemaWP) {
      // Cálculo Obras civis base
      if (propostaCalc.tipo_de_instalacao === 'solo' || propostaCalc.tipo_de_instalacao === 'carport') {
        if (propostaCalc.custom_data && propostaCalc.custom_data.civil) {
          propostaCalc.civil = tratarNumero(data.civil);
        } else {
          propostaCalc.civil = 0.175 * potenciaDoSistemaWP;
        }
      }

      // const quantidadeDeModulos = potenciaDoSistemaWP / potenciaPlaca;
      const quantidadeDeModulos = propostaCalc.modulos;

      if (propostaCalc.tipo_de_instalacao === 'laje') {
        // Cálculo alvenaria
        if (propostaCalc.custom_data && propostaCalc.custom_data.alvenaria) {
          propostaCalc.alvenaria = tratarNumero(data.alvenaria);
        } else {
          propostaCalc.alvenaria = quantidadeDeModulos * 40;
        }
      }

      if (propostaCalc.tipo_de_instalacao === 'solo' || propostaCalc.tipo_de_instalacao === 'carport') {
        // Cálculo aterramento
        if (propostaCalc.custom_data && propostaCalc.custom_data.aterramento) {
          propostaCalc.aterramento = tratarNumero(data.aterramento);
        } else {
          propostaCalc.aterramento = 0.049 * potenciaDoSistemaWP;
        }
      }
    }

    // Cálculo obras civis total
    propostaCalc.obras_civis_total = propostaCalc.civil + propostaCalc.alvenaria + propostaCalc.aterramento

    // Total custos diretos
    propostaCalc.total_custos_diretos = propostaCalc.elaboracao_dimensionamento_projeto_total + propostaCalc.instalacao_civil_eletrica_total + propostaCalc.obras_civis_total + propostaCalc.pos_venda_total + propostaCalc.adicionais + propostaCalc.adicionais_2 + propostaCalc.adicionais_3 + propostaCalc.adicionais_4 + propostaCalc.adicionais_5;

    // Cálculo do Total Projeto REPENSA
    propostaCalc.total_projeto_repensa = propostaCalc.total_custos_diretos + propostaCalc.equipamentos

    // Comissão do representante
    if (propostaCalc.prospeccao === 'sim') {
      propostaCalc.comissao_representante_perc = propostaCalc.taxas_fixas.comissao_representante;
    } else {
      propostaCalc.comissao_representante_perc = 0;
    }

    // Outras despesas e taxas
    if (propostaCalc.faturamento === 'completo') {
      if (propostaCalc.custom_data.iss) {
        propostaCalc.taxas_fixas.iss = propostaCalc.taxas_fixas.iss;
      } else {
        propostaCalc.taxas_fixas.iss = 0.065;
      }

      let _lucroGoal = 0.115;
      if (propostaCalc.custom_lucro) {
        _lucroGoal = propostaCalc.lucro_liquido_servicos_perc;
      }

      let loopCount = 0
      const ganhoIncrement = 0.01
      let ganhoMagic = 0

      do {
        const _custo_administrativo = propostaCalc.taxas_fixas.custo_administrativo
        const _comissao_vendedor_interno = propostaCalc.taxas_fixas.comissao_vendedor_interno
        const _comissao_representante = propostaCalc.comissao_representante_perc

        const _ganho = ganhoMagic

        // Percentuais adicionais total para cálculo do TOTAL
        const percentuais_adicionais = _custo_administrativo + _comissao_vendedor_interno + _comissao_representante + _ganho

        // Total
        propostaCalc.total = propostaCalc.total_projeto_repensa / (1 - percentuais_adicionais)

        // Recuperar custos
        propostaCalc.custo_administrativo = _custo_administrativo * propostaCalc.total
        propostaCalc.comissao_vendedor_interno = _comissao_vendedor_interno * propostaCalc.total
        propostaCalc.comissao_representante = _comissao_representante * propostaCalc.total

        // Recuperar custos fixos e variáveis
        propostaCalc.custos_fixos_variaveis_total = propostaCalc.total - propostaCalc.equipamentos

        // Recuperar o Imposto
        propostaCalc.iss = propostaCalc.total * propostaCalc.taxas_fixas.iss

        // Cálculo do lucro
        propostaCalc.lucro_liquido_valor_total = propostaCalc.total - propostaCalc.total_projeto_repensa - propostaCalc.custo_administrativo - propostaCalc.comissao_vendedor_interno - propostaCalc.iss - propostaCalc.comissao_representante

        propostaCalc.lucro_liquido_valor_total_perc = (propostaCalc.lucro_liquido_valor_total / propostaCalc.total)
        propostaCalc.lucro_liquido_servicos_perc = (propostaCalc.lucro_liquido_valor_total / propostaCalc.custos_fixos_variaveis_total)

        if (propostaCalc.lucro_liquido_servicos_perc < _lucroGoal) {
          ganhoMagic += ganhoIncrement
        }

        loopCount++
      } while (propostaCalc.lucro_liquido_servicos_perc < _lucroGoal)
    } else {
      if (propostaCalc.custom_data.iss) {
        propostaCalc.taxas_fixas.iss = propostaCalc.taxas_fixas.iss;
      } else {
        propostaCalc.taxas_fixas.iss = 0.14;
      }

      let _lucroGoal = 0.325;
      if (propostaCalc.custom_lucro) {
        _lucroGoal = propostaCalc.lucro_liquido_servicos_perc;
      }

      let loopCount = 0
      const ganhoIncrement = 0.01
      let ganhoMagic = 0

      do {
        const _custo_administrativo = propostaCalc.taxas_fixas.custo_administrativo
        const _comissao_vendedor_interno = propostaCalc.taxas_fixas.comissao_vendedor_interno
        const _comissao_representante = propostaCalc.comissao_representante_perc

        const _ganho = ganhoMagic

        // Percentuais adicionais total para cálculo do TOTAL
        const percentuais_adicionais = _custo_administrativo + _comissao_vendedor_interno + _comissao_representante + _ganho

        // Total
        propostaCalc.total = propostaCalc.total_projeto_repensa / (1 - percentuais_adicionais)

        // Recuperar custos
        propostaCalc.custo_administrativo = _custo_administrativo * propostaCalc.total
        propostaCalc.comissao_vendedor_interno = _comissao_vendedor_interno * propostaCalc.total
        propostaCalc.comissao_representante = _comissao_representante * propostaCalc.total

        // Recuperar custos fixos e variáveis
        propostaCalc.custos_fixos_variaveis_total = propostaCalc.total - propostaCalc.equipamentos

        // Recuperar o Imposto
        propostaCalc.iss = propostaCalc.custos_fixos_variaveis_total * propostaCalc.taxas_fixas.iss

        // Cálculo do lucro
        propostaCalc.lucro_liquido_valor_total = propostaCalc.total - propostaCalc.total_projeto_repensa - propostaCalc.custo_administrativo - propostaCalc.comissao_vendedor_interno - propostaCalc.iss - propostaCalc.comissao_representante

        propostaCalc.lucro_liquido_valor_total_perc = (propostaCalc.lucro_liquido_valor_total / propostaCalc.total)
        propostaCalc.lucro_liquido_servicos_perc = (propostaCalc.lucro_liquido_valor_total / propostaCalc.custos_fixos_variaveis_total)

        if (propostaCalc.lucro_liquido_servicos_perc < _lucroGoal) {
          ganhoMagic += ganhoIncrement
        }

        loopCount++
      } while (propostaCalc.lucro_liquido_servicos_perc < _lucroGoal)
    }

    // Total por WP
    propostaCalc.total_wp = propostaCalc.total / (propostaCalc.potencia_do_sistema * 1000);

    // Mostrar final de projeto Repensa / Direto
    propostaCalc.final_projeto_direto = propostaCalc.equipamentos;
    propostaCalc.final_projeto_repensa = propostaCalc.total - propostaCalc.equipamentos;

    fillFormValues(propostaCalc);
    setProposta(propostaCalc);
  }, [proposta, setProposta, fillFormValues, executivos, representantes]);

  const getParametrosProposta = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);

    try {
      const response = await api.post('/get_parametros_proposta', formData);
      const { data } = response;

      if (data && data.data) {
        let parametros = data.data;
        if (parametros) {
          parametros = JSON.parse(parametros);

          setParametrosProposta(parametros);
        }
      }
    } catch (err) {
      toast.error('Erro ao recuperar configurações. Atualize a página!');
    }
  }, []);

  const getTelefoneRepensa = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('config', 'telefone_repensa');

    try {
      const response = await api.post('/get_config', formData);
      const { data } = response;
      
      if (data && data.data) {
        setTelefoneRepensa(data.data);
      }
    } catch (err) {
      let errorMessage = 'Erro ao recuperar configurações';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      console.error(errorMessage);
    }
  }, [setTelefoneRepensa]);

  const getVendedorInfo = useCallback(async (id) => {
    try {
      const { user_id, hash } = authenticated;
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('user_id', id);

      const response = await api.post('/get_user_info', formData);
      const { data } = response;

      if (data && data.result === 'success' && data.data) {
        setVendedorData(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [authenticated, setVendedorData]);

  const criarProposta = useCallback(async (cliente) => {
    try {
      const { user_id, hash } = authenticated;
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('owner_id', user_id);
      formData.append('cliente', cliente);

      const response = await api.post('/criar_proposta', formData);
      const { data } = response;

      if (data && data.result === 'success' && data.proposta_id) {
        return data.proposta_id;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }, []);

  const criarVersao = useCallback(async (proposta_id, versao, proposta, propostaCliente = null, propostaValor = null, propostaExecutivo = null, propostaRepresentante = null) => {
    try {
      const { user_id, hash } = authenticated;
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('owner_id', user_id);
      formData.append('proposta_id', proposta_id);
      formData.append('versao', versao);
      formData.append('proposta', proposta);
      if (propostaCliente) { formData.append('propostaCliente', propostaCliente); }
      if (propostaValor) { formData.append('propostaValor', propostaValor); }
      if (propostaExecutivo) { formData.append('propostaExecutivo', propostaExecutivo); }
      if (propostaRepresentante) { formData.append('propostaRepresentante', propostaRepresentante); }

      const response = await api.post('/criar_versao', formData);
      const { data } = response;

      if (data && data.result === 'success' && data.versao_id) {
        return data.versao_id;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }, []);

  const atualizarVersao = useCallback(async (versao_id, proposta_id, proposta, propostaCliente = null, propostaValor = null, propostaExecutivo = null, propostaRepresentante = null) => {
    try {
      const { user_id, hash } = authenticated;
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('versao_id', versao_id);
      formData.append('proposta_id', proposta_id);
      formData.append('proposta', proposta);
      if (propostaCliente) { formData.append('propostaCliente', propostaCliente); }
      if (propostaValor) { formData.append('propostaValor', propostaValor); }
      if (propostaExecutivo) { formData.append('propostaExecutivo', propostaExecutivo); }
      if (propostaRepresentante) { formData.append('propostaRepresentante', propostaRepresentante); }

      const response = await api.post('/atualizar_versao', formData);
      const { data } = response;

      if (data && data.result === 'success') {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }, []);

  const salvarProposta = useCallback(async () => {
    toggleLoading(true);

    let localPropostaId = null;
    let localVersaoId = null;
    let message;
    const saveVersaoNumber = versaoNumber;

    if (newVersao === false) {
      // NOVA PROPOSTA ou EDIÇÃO DE VERSÃO
      if (propostaId === false) {
        const newPropostaId = await criarProposta(proposta.nome_do_cliente);
        if (newPropostaId) {
          setPropostaId(newPropostaId);
          localPropostaId = newPropostaId;
        }
      } else {
        localPropostaId = propostaId;
      }
      if (localPropostaId) {

        if (versaoId === false) {
          localVersaoId = await criarVersao(localPropostaId, saveVersaoNumber, JSON.stringify(proposta), proposta.nome_do_cliente, proposta.total, proposta.executivo_de_vendas, proposta.representante);
          if (localVersaoId) {
            setVersaoId(localVersaoId);
            message = 'Dados da proposta salvos';
          }
        } else {
          localVersaoId = versaoId;
          const versaoUpdated = await atualizarVersao(localVersaoId, localPropostaId, JSON.stringify(proposta), proposta.nome_do_cliente, proposta.total, proposta.executivo_de_vendas, proposta.representante);
          if (versaoUpdated) {
            message = 'Dados da proposta atualizados';
          }
        }
      }
    } else {
      // NOVA VERSÃO
      localPropostaId = propostaId;

      if (localPropostaId) {
        if (versaoId === false) {
          localVersaoId = await criarVersao(localPropostaId, saveVersaoNumber, JSON.stringify(proposta), proposta.nome_do_cliente, proposta.total, proposta.executivo_de_vendas, proposta.representante);
          if (localVersaoId) {
            setVersaoId(localVersaoId);
            message = `Nova versão da proposta salva [${versaoNumber}]`;
          }
        } else {
          localVersaoId = versaoId;
          const versaoUpdated = await atualizarVersao(localVersaoId, localPropostaId, JSON.stringify(proposta), proposta.nome_do_cliente, proposta.total, proposta.executivo_de_vendas, proposta.representante);
          if (versaoUpdated) {
            message = 'Dados da proposta atualizados';
          }
        }
      }
    }

    if (localPropostaId && localVersaoId && message) {
      toast.success(message);
    } else {
      toast.error('Ocorreu um erro ao salvar os dados da proposta');
    }

    toggleLoading(false);

    setTimeout(() => fillFormValues(proposta), 500);
  }, [proposta, propostaId, setPropostaId, versaoNumber, versaoId, setVersaoId, criarProposta, criarVersao, atualizarVersao, fillFormValues]);

  const gerarProposta = useCallback(async (propostaMeta) => {
    if (DEMO_MODE) {
      Gerador({
        propostaData: proposta,
        demo: true
      });
    } else {
      toggleLoading(true);

      if (propostaMeta.propostaId && propostaMeta.versaoId) {
        const propostaToGenerate = proposta;
        propostaToGenerate.id = propostaMeta.propostaId;

        // Obtendo informações do vendedor
        const vendedor = {};
        if (propostaToGenerate.executivo_de_vendas) {
          // Request para obter informações do vendedor e mostrar
          const { hash } = authenticated;
          const formData = new FormData();
          formData.append('access_key', ACCESS_KEY);
          formData.append('login_key', hash);
          formData.append('user_id', propostaToGenerate.executivo_de_vendas);

          try {
            const response = await api.post('/get_user_info', formData);
            const { data } = response;
            if (data && data.result === 'success' && data.data) {
              vendedor.nome = (data.data.nome) ? data.data.nome : '';
              vendedor.celular = (data.data.telefone) ? data.data.telefone : '';
              vendedor.fixo = '';
              vendedor.codigo = (data.data.codigo) ? data.data.codigo : '';
            }
          } catch (err) {
            console.error(err);
          }
        } else {
          vendedor.nome = (vendedorData && vendedorData.name) ? vendedorData.name : '';
          vendedor.celular = (vendedorData && vendedorData.telefone) ? vendedorData.telefone : '';
          vendedor.fixo = '';
          vendedor.codigo = (vendedorData && vendedorData.codigo) ? vendedorData.codigo : '';
        }

        // Recuperando total kWp para PDF
        let totalKwp = 0;
        if (totalKwp === 0) {
          const formData = new FormData();
          formData.append('access_key', ACCESS_KEY);
          formData.append('config', 'total_kwp');
          try {
            const response = await api.post('/get_config', formData);
            const { data } = response;
            if (data && data.data) totalKwp = data.data;
          } catch (err) {
            console.error(err);
          }
        }

        // Validando dados para gerar o PDF
        const tmpNomeDoCliente = propostaToGenerate.nome_do_cliente;
        const tmpTipoDeInstalacao = propostaToGenerate.tipo_de_instalacao;
        if (tmpNomeDoCliente === '' || tmpNomeDoCliente === ' ' || tmpNomeDoCliente === null || tmpNomeDoCliente === undefined) {
          toast.warning('Preencha o Nome do Cliente');
        }
        if (tmpTipoDeInstalacao === '' || tmpTipoDeInstalacao === null || tmpTipoDeInstalacao === undefined) {
          toast.warning('Preencha o Tipo de Instalação do Projeto');
        }

        try {
          await Gerador({
            propostaData: propostaToGenerate,
            demo: false,
            versao: propostaMeta.versaoNumber,
            vendedor,
            telefoneRepensa,
            totalKwp
          });
        } catch (err) {
          console.error(err);
          toast.error('Erro ao gerar PDF');
        }
      }

      toggleLoading(false);
    }    
  }, [vendedorData, proposta, telefoneRepensa]);

  const gerarPropostaCallback = useCallback(async () => {
    if (propostaId === false && versaoId === false) {
      toast.warning('Salve os dados da proposta antes de gerar o documento');
    } else {
      const localPropostaId = propostaId;
      const localVersaoId = versaoId;

      const propostaMeta = { propostaId: localPropostaId, versaoId: localVersaoId };
      await gerarProposta(propostaMeta);

      fillFormValues(proposta);
    }
  }, [propostaId, versaoId, gerarProposta, fillFormValues, proposta]);

  const getEquipamentos = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);

    try {
      const response1 = await api.post('/get_placas', formData);
      const data1 = response1.data;
      
      if (data1 && data1.data) { setPlacas(data1.data); }
    } catch (err) {
      let errorMessage = 'Erro ao realizar operação';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      console.error(errorMessage);
    }

    try {
      const response2 = await api.post('/get_inversores', formData);
      const data2 = response2.data;
      
      if (data2 && data2.data) {
        setInversores(data2.data);

        const options = [];
        data2.data.forEach((item) => {
          options.push({value: item.nome, label: item.nome});
        });
        setInversoresOptions(options);
      }
    } catch (err) {
      let errorMessage = 'Erro ao realizar operação';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      console.error(errorMessage);
    }

    try {
      const response3 = await api.post('/get_transformadores', formData);
      const data3 = response3.data;
      
      if (data3 && data3.data) { setTransformadores(data3.data); }
    } catch (err) {
      let errorMessage = 'Erro ao realizar operação';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      console.error(errorMessage);
    }
  }, [setPlacas, setInversores, setTransformadores, setInversoresOptions]);

  const getExecutivos = useCallback(async () => {
    try {
      const formData = new FormData()
      formData.append('access_key', ACCESS_KEY)

      const response = await api.post('/list_users', formData)
      const { data } = response
      if (data && data.data) {
        setExecutivos(data.data);
      }
    } catch (err) {
      console.error('Erro ao recuperar usuários')
    }
  }, [setExecutivos]);

  const getRepresentantes = useCallback(async () => {
    try {
      const formData = new FormData()
      formData.append('access_key', ACCESS_KEY)

      const response = await api.post('/listar_representantes', formData)
      const { data } = response
      if (data && data.data) {
        setRepresentantes(data.data);
      }
    } catch (err) {
      console.error('Erro ao recuperar usuários')
    }
  }, [setRepresentantes]);

  useEffect(() => {
    getEquipamentos();
  }, [getEquipamentos]);

  useEffect(() => {
    toggleLoading(true);
    const userData = authenticated;
    if (userData && userData.level) {
      setUserRole(parseInt(userData.level));
    }
    toggleLoading(false);

    getParametrosProposta();
    getTelefoneRepensa();
    getExecutivos();
    getRepresentantes();

    const { user_id } = authenticated;
    getVendedorInfo(user_id);
  }, []);

  useEffect(() => {
    if (propostaEdit && propostaEdit !== null) {
      if (propostaEdit.proposta) {
        const parsedProposta = JSON.parse(propostaEdit.proposta);
        setProposta(parsedProposta);
        setPropostaId(propostaEdit.proposta_id);

        if (newVersao === false) {
          setVersaoId(propostaEdit.id);
          setVersaoNumber(propostaEdit.versao);
        } else {
          setVersaoNumber(vNumber);
        }

        // const currentVersao = propostaEdit.versao.versao;
        // let versaoToSet = '1';
        // if (currentVersao) {
        //   versaoToSet = parseInt(currentVersao);
        //   setVersaoNumber(`${versaoToSet}`);
        // }

        // if (propostaEdit.id) {
        //   setPropostaId(propostaEdit.id);
        // }

        // if (propostaEdit.versao.id) {
        //   setVersaoId(propostaEdit.versao.id);
        // }

        setTimeout(() => fillFormValues(parsedProposta), 500);
      }
    }
  }, [propostaEdit]);

  const DadosCliente = ({ stepper, type }) => {
    return (
      <Fragment>
        <div className='content-header'>
          <h5 className='mb-0'>Dados do Cliente</h5>
          <small className='text-muted'>Preencha os dados do cliente</small>
        </div>
        <Form onSubmit={e => e.preventDefault()}>
          <Row>
            <FormGroup tag={Col} md={7}>
              <Label className='form-label' for='nome_do_cliente'>
                Nome do cliente
              </Label>
              <Input
                name='nome_do_cliente'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
            <FormGroup tag={Col} md={5}>
              <Label className='form-label' for='cpf_cliente'>
                CPF/CNPJ
              </Label>
              <Input
                name='cpf_cliente'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md='6'>
              <Label className='form-label' for='email_cliente'>
                Contato (Email)
              </Label>
              <Input
                type='email'
                name='email_cliente'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
            <FormGroup tag={Col} md='6'>
              <Label className='form-label' for='telefone_cliente'>
                Contato (Telefone)
              </Label>
              <Input
                name='telefone_cliente'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md={12}>
              <Label className='form-label' for='tipo_projeto'>
                Tipo de projeto
              </Label>
              <Input
                type='select'
                name='tipo_projeto'
                innerRef={register}
                onChange={calculate}
              >
                <option value='residencial'>Residencial</option>
                <option value='comercial'>Comercial</option>
                <option value='industrial'>Industrial</option>
                <option value='rural'>Rural</option>
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md='6'>
              <Label className='form-label' for='prospeccao'>
                Negócio através de representante?
              </Label>
              <Input
                type='select'
                name='prospeccao'
                innerRef={register}
                onChange={calculate}
              >
                <option value='sim'>Sim</option>
                <option value='nao'>Não</option>
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md='6'>
              <Label className='form-label' for='faturamento'>
                Tipo de faturamento
              </Label>
              <Input
                type='select'
                name='faturamento'
                innerRef={register}
                onChange={calculate}
              >
                <option value='direto'>Direto</option>
                <option value='completo'>Completo</option>
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md='6'>
              <Label className='form-label'>
                Executivo de Vendas
              </Label>
              <Input
                type='select'
                name='executivo_de_vendas'
                innerRef={register}
                onChange={calculate}
              >
                <option value=''>Selecione</option>
                {executivos && executivos.map((value) => (
                  <option key={value.id} value={value.id}>{value.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md='6'>
              <Label className='form-label'>
                Representante
              </Label>
              <Input
                type='select'
                name='representante'
                innerRef={register}
                onChange={calculate}
              >
                <option value=''>Selecione</option>
                {representantes && representantes.map((value) => (
                  <option key={value.id} value={value.id}>{value.nome}</option>
                ))}
              </Input>
            </FormGroup>
          </Row>

          <div className='d-flex justify-content-between'>
            <Button.Ripple color='secondary' className='btn-prev' outline disabled>
              <ArrowLeft size={14} className='align-middle mr-sm-25 mr-0'></ArrowLeft>
              <span className='align-middle d-sm-inline-block d-none'>Voltar</span>
            </Button.Ripple>
            <Button.Ripple color='primary' className='btn-next' onClick={() => stepper.next()}>
              <span className='align-middle d-sm-inline-block d-none'>Avançar</span>
              <ArrowRight size={14} className='align-middle ml-sm-25 ml-0'></ArrowRight>
            </Button.Ripple>
          </div>
        </Form>
      </Fragment>
    )
  }

  const DadosProjeto = ({ stepper, type }) => {
    return (
      <Fragment>
        <div className='content-header'>
          <h5 className='mb-0'>Dados do projeto</h5>
          <small>Preencha os dados do projeto</small>
        </div>
        <Form onSubmit={e => e.preventDefault()}>
          <Row>
            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='cep_cliente'>CEP</Label>
              <Input
                type='text'
                name='cep_cliente'
                innerRef={register}
                onBlur={async (evt) => {
                  const busca = await buscaCep(evt.target.value);
                  if (busca) {
                    if (busca.cep) setValue('cep_cliente', busca.cep);
                    if (busca.city) setValue('municipio_cliente', busca.city);
                    if (busca.street) setValue('logradouro_cliente', busca.street);
                    if (busca.neighborhood) setValue('bairro_cliente', busca.neighborhood);
                    if (busca.state) setValue('estado_cliente', busca.state);

                    calculate();

                    if (busca.location?.coordinates?.latitude && busca.location?.coordinates?.longitude) {
                      const lat = busca.location.coordinates.latitude;
                      const log = busca.location.coordinates.longitude;
                      const url = `https://api.globalsolaratlas.info/data/lta?loc=${lat},${log}`;

                      try {
                        const response = await axios.get(url);
                        if (response) {
                          const { data } = response;
                          if (data?.annual?.data?.GHI) {
                            let total = data.annual.data.GHI;
                            total = total / 365;
                            setValue('fator_irradiacao', formatFatorIrradiacao(total.toFixed(3)));
                          }
                          // if (data?.monthly?.data?.GHI) {
                          //   const values = data.monthly.data.GHI;
                          //   if (values && values.length > 0) {
                          //     let total = 0, media = 0, div = 0;
                          //     values.forEach((val) => { total += parseFloat(val); div++; });
                          //     media = (total / div) / 30;
                          //     if (media) setValue('fator_irradiacao', formatFatorIrradiacao(media.toFixed(3)));
                          //   }
                          // }
                        }
                      } catch (err) {
                        toast.warning('Erro ao recuperar dados de irradiação [api.globalsolaratlas]');
                      }
                    }
                  }
                }}
              />
            </FormGroup>
            <FormGroup tag={Col} md={8}>
              <Label className='form-label' for='logradouro_cliente'>Logradouro</Label>
              <Input
                type='text'
                name='logradouro_cliente'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='bairro_cliente'>Bairro</Label>
              <Input
                type='text'
                name='bairro_cliente'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='municipio_cliente'>Município</Label>
              <Input
                type='text'
                name='municipio_cliente'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='estado_cliente'>Estado</Label>
              <Input
                type='select'
                name='estado_cliente'
                innerRef={register}
                onChange={calculate}
              >
                <option value=''>Selecione</option>
                <option value='SP'>São Paulo</option>
                <option value='AC'>Acre</option>
                <option value='AL'>Alagoas</option>
                <option value='AP'>Amapá</option>
                <option value='AM'>Amazonas</option>
                <option value='BA'>Bahia</option>
                <option value='CE'>Ceara</option>
                <option value='DF'>Distrito Federal</option>
                <option value='ES'>Espírito Santo</option>
                <option value='GO'>Goiás</option>
                <option value='MA'>Maranhão</option>
                <option value='MT'>Mato Grosso</option>
                <option value='MS'>Mato Grosso do Sul</option>
                <option value='MG'>Minas Gerais</option>
                <option value='PA'>Pará</option>
                <option value='PB'>Paraíba</option>
                <option value='PR'>Paraná</option>
                <option value='PE'>Pernambuco</option>
                <option value='PI'>Piauí</option>
                <option value='RJ'>Rio de Janeiro</option>
                <option value='RN'>Rio Grande do Norte</option>
                <option value='RS'>Rio Grande do Sul</option>
                <option value='RO'>Rondônia</option>
                <option value='RR'>Roraima</option>
                <option value='SC'>Santa Catarina</option>
                <option value='SE'>Sergipe</option>
                <option value='TO'>Tocantins</option>
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md={6}>
              <Label className='form-label' for='concessionaria'>
                Concessionária
              </Label>
              <Input
                type='select'
                name='concessionaria'
                innerRef={register}
                onChange={calculate}
              >
                <option value=''>Selecione</option>
                <option value='ENEL'>ENEL</option>
                <option value='CPFL'>CPFL</option>
                <option value='ELEKTRO'>ELEKTRO</option>
                <option value='EDP Bandeirante'>EDP Bandeirante</option>
                <option value='CEMIG'>CEMIG</option>
                <option value='LIGHT'>LIGHT</option>
                <option value='CELPA'>CELPA</option>
                <option value='CELPE'>CELPE</option>
                <option value='CELTINS'>CELTINS</option>
                <option value='CELESC'>CELESC</option>
                <option value='ENERGISA'>ENERGISA</option>
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md={3}>
              <Label className='form-label' for='classificacao'>
                Classificação
              </Label>
              <Input
                type='select'
                name='classificacao'
                innerRef={register}
                onChange={calculate}
              >
                <option value=''>Selecione</option>
                <option value='baixa_tensao'>Baixa tensão</option>
                <option value='media_tensao'>Média tensão</option>
              </Input>
            </FormGroup>

            <FormGroup tag={Col} md='3'>
              <Label className='form-label' for='demanda_contratada'>
                Demanda contratada
              </Label>
              <Input
                type='text'
                name='demanda_contratada'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md={6}>
              <Label className='form-label'>
                Tipo de instalação
              </Label>
              <Input
                type='select'
                name='tipo_de_instalacao'
                innerRef={register}
                onChange={calculate}
              >
                <option value=''>Selecione</option>
                <option value='telhado_de_barro'>Telhado de Barro</option>
                <option value='telhado_de_cimento'>Telhado de Cimento</option>
                <option value='telhado_de_fibrocimento'>Telhado de Fibrocimento</option>
                <option value='telhado_de_chapa_metalica'>Telhado de Chapa Metálica</option>
                <option value='laje'>Laje</option>
                <option value='solo'>Solo</option>
                <option value='carport'>Carport</option>
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md={3}>
              <Label className='form-label' for='custo_de_disponibilidade'>
                Custo de disponibilidade (kWh)
              </Label>
              <Input
                type='number'
                name='custo_de_disponibilidade'
                innerRef={register}
                onBlur={calculate}
                className='text-right'
              />
            </FormGroup>
            <FormGroup tag={Col} md={3}>
              <Label className='form-label' for='custo_de_disponibilidade'>
                N. Unidade Consumidora
              </Label>
                <Input
                  name='n_unidade_consumidora'
                  innerRef={register}
                  onBlur={calculate}
                />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md={6}>
              <Label className='form-label' for='tarifa_media_energia'>
                Tarifa média de energia
              </Label>
              <Input
                name='tarifa_media_energia'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
            <FormGroup tag={Col} md={6}>
              <Label className='form-label' for='fator_irradiacao'>
                Fator Irradiação (SWERA)
              </Label>
              <Input
                name='fator_irradiacao'
                innerRef={register}
                onBlur={calculate}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='consumo_medio_mensal'>
                Consumo médio mensal
              </Label>
              <Input
                name='consumo_medio_mensal'
                innerRef={register}
                disabled
              />
            </FormGroup>
            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='consumo_medio_anual_suprido'>
                Consumo médio anual a ser suprido:
              </Label>
              <Input
                name='consumo_medio_anual_suprido'
                innerRef={register}
                disabled
              />
            </FormGroup>
            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='geracao_media_anual_swera'>
                Geração média anual SWERA (PR 75%)
              </Label>
              <Input
                name='geracao_media_anual_swera'
                innerRef={register}
                disabled
              />
            </FormGroup>
          </Row>

          <hr />

          <Row>
            <Col md={12} className="mb-1">
              <b>Inputs - Consumo</b>
            </Col>
          </Row>

          {/* Inputs da geração */}
          <Fragment>
            <Row>
              <Col md={2}>Mês</Col>
              <Col md={2}>UC 1</Col>
              <Col md={2}>UC 2</Col>
              <Col md={2}>UC 3</Col>
              <Col md={2}>UC 4</Col>
              <Col md={2}>UC 5</Col>
            </Row>

            <Row>
              {/* Labels */}
              <Col md={2}>
                <Input
                  type='text'
                  defaultValue='Jan'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Fev'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Mar'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Abr'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Mai'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Jun'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Jul'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Ago'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Set'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Out'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Nov'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  type='text'
                  defaultValue='Dez'
                  disabled
                  style={{ marginBottom: '3px' }}
                />
              </Col>
              {/* UC 1 */}
              <Col md={2}>
                <Input
                  name='uc_1_janeiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_fevereiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_marco'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_abril'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_maio'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_junho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_julho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_agosto'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_setembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_outubro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_novembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_dezembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_1_total'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
              {/* UC 2 */}
              <Col md={2}>
                <Input
                  name='uc_2_janeiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_fevereiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_marco'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_abril'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_maio'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_junho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_julho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_agosto'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_setembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_outubro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_novembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_dezembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_2_total'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
              {/* UC 3 */}
              <Col md={2}>
                <Input
                  name='uc_3_janeiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_fevereiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_marco'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_abril'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_maio'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_junho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_julho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_agosto'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_setembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_outubro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_novembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_dezembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_3_total'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
              {/* UC 4 */}
              <Col md={2}>
                <Input
                  name='uc_4_janeiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_fevereiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_marco'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_abril'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_maio'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_junho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_julho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_agosto'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_setembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_outubro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_novembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_dezembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_4_total'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
              {/* UC 5 */}
              <Col md={2}>
                <Input
                  name='uc_5_janeiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_fevereiro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_marco'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_abril'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_maio'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_junho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_julho'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_agosto'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_setembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_outubro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_novembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_dezembro'
                  autoComplete='off'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                  style={{ marginBottom: '3px' }}
                />
                <Input
                  name='uc_5_total'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
            </Row>
            
          </Fragment>

          <hr />

          <div className={classnames({ 'd-none': userRole && userRole === 3 })}>
            <Row className="mb-1">
              <Col md={6}>
                <b>Inputs - Geração</b>
              </Col>
              <Col md={6} className="text-right">
                <Button
                  onClick={toggleCamposAdicionais}
                  className="mr-1"
                >
                  Campos adicionais
                </Button>
                <Button
                  onClick={toggleCamposIrradiacao}
                >
                  Dados Irradiação
                </Button>
              </Col>
            </Row>

            <Fragment>
              <Row className="mt-1">

                <Col md={2}>
                  Mês
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  Consumo atual
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      Consumo a compensar
                    </Col>
                    <Col md={2}>
                      Geração
                    </Col>
                    <Col md={2}>
                      Autonomia
                    </Col>
                    <Col md={2}>
                      Excedente
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Jan'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_janeiro_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_janeiro_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_janeiro_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_janeiro_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_janeiro_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Fev'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_fevereiro_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_fevereiro_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_fevereiro_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_fevereiro_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_fevereiro_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Mar'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_marco_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_marco_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_marco_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_marco_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_marco_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Abr'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_abril_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_abril_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_abril_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_abril_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_abril_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Mai'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_maio_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_maio_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_maio_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_maio_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_maio_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Jun'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_junho_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_junho_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_junho_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_junho_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_junho_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Jul'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_julho_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_julho_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_julho_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_julho_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_julho_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Ago'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_agosto_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_agosto_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_agosto_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_agosto_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_agosto_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Set'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_setembro_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_setembro_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_setembro_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_setembro_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_setembro_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Out'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_outubro_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_outubro_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_outubro_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_outubro_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_outubro_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Nov'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_novembro_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_novembro_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_novembro_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_novembro_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_novembro_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Dez'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}>
                  <Input
                    name='proposta_geracao_dezembro_consumo_atual'
                    innerRef={register}
                    disabled
                    className='text-right'
                  />
                </Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_dezembro_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_dezembro_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_dezembro_autonomia'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_dezembro_excedente'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginBottom: '3px' }}>
                <Col md={2}>
                  <Input
                    defaultValue='Total'
                    disabled
                  />
                </Col>
                <Col md={camposAdicionais ? 2 : 10}></Col>
                {camposAdicionais && (
                  <Fragment>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_total_consumo_a_compensar'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        name='proposta_geracao_total_geracao'
                        innerRef={register}
                        disabled
                        className='text-right'
                      />
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                  </Fragment>
                )}
              </Row>
            </Fragment>

            <hr />

            {camposIrradiacao && (
              <Fragment>
                <Row>
                  <Col md={12} className="mb-1">
                    <b>Inputs - Irradiação detalhado</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <table
                      style={{
                        width: '100%'
                      }}
                      className="table-bordered"
                    >
                      <thead>
                        <tr>
                          <td>Janeiro</td>
                          <td>Fevereiro</td>
                          <td>Março</td>
                          <td>Abril</td>
                          <td>Maio</td>
                          <td>Junho</td>
                          <td>Julho</td>
                          <td>Agosto</td>
                          <td>Setembro</td>
                          <td>Outubro</td>
                          <td>Novembro</td>
                          <td>Dezembro</td>
                          <td>Média</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[1]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[2]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[3]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[4]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[5]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[6]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[7]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[8]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[9]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[10]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[11]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses[12]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[1].meses.media}</td>
                        </tr>
                        <tr>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[1]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[2]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[3]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[4]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[5]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[6]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[7]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[8]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[9]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[10]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[11]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses[12]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[2].meses.media}</td>
                        </tr>
                        <tr>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[1]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[2]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[3]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[4]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[5]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[6]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[7]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[8]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[9]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[10]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[11]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses[12]}</td>
                          <td>{proposta.irradiacao.detalhado.cidades[3].meses.media}</td>
                        </tr>
                        <tr>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[1] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[2] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[3] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[4] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[5] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[6] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[7] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[8] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[9] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[10] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[11] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[1].desvio_media[12] * 100).toFixed(0)} %`}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[1] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[2] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[3] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[4] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[5] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[6] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[7] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[8] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[9] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[10] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[11] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[2].desvio_media[12] * 100).toFixed(0)} %`}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[1] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[2] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[3] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[4] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[5] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[6] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[7] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[8] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[9] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[10] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[11] * 100).toFixed(0)} %`}</td>
                          <td>{`${(proposta.irradiacao.detalhado.cidades[3].desvio_media[12] * 100).toFixed(0)} %`}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="mb-1">
                    <b>Inputs - Irradiação Simples</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <table
                      style={{
                        width: '100%'
                      }}
                      className="table-bordered"
                    >
                      <thead>
                        <tr>
                          <td>Janeiro</td>
                          <td>Fevereiro</td>
                          <td>Março</td>
                          <td>Abril</td>
                          <td>Maio</td>
                          <td>Junho</td>
                          <td>Julho</td>
                          <td>Agosto</td>
                          <td>Setembro</td>
                          <td>Outubro</td>
                          <td>Novembro</td>
                          <td>Dezembro</td>
                          <td>Média</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{(proposta.irradiacao.simples[1]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[2]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[3]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[4]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[5]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[6]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[7]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[8]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[9]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[10]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[11]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples[12]).toFixed(3)}</td>
                          <td>{(proposta.irradiacao.simples.media).toFixed(1)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col md={12}>
                    <table
                      style={{
                        width: '100%'
                      }}
                      className="table-bordered"
                    >
                      <tbody>
                        <tr>
                          <td>Potencial médio diário</td>
                          <td>{(proposta.irradiacao.potencial_medio_diario).toFixed(2)}</td>
                          <td>kwh/m2</td>
                        </tr>
                        <tr>
                          <td>Potencial médio mensal</td>
                          <td>{(proposta.irradiacao.pontecial_medio_mensal).toFixed(2)}</td>
                          <td>kwh/m2</td>
                        </tr>
                        <tr>
                          <td>Potencial médio anual</td>
                          <td>{(proposta.irradiacao.potencial_medio_anual).toFixed(2)}</td>
                          <td>kwh/m2</td>
                        </tr>
                        <tr>
                          <td>Geração média anual SWERA (PR 75%)</td>
                          <td>{(proposta.irradiacao.geracao_media_anual_swera).toFixed(2)}</td>
                          <td>kwh/kwp/ano</td>
                        </tr>
                        <tr>
                          <td>Tamanho do sistema necessario</td>
                          <td>{(proposta.potencia_sistema_sugerida).toFixed(2)}</td>
                          <td>KWp</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <hr />
              </Fragment>
            )}
          </div>

          <Row>
            <Col md={12} className='mb-2'>
              <b>Componentes do sistema</b>
            </Col>
          </Row>

          <Row>
            <FormGroup tag={Col} md={3}>
              <Label className='form-label' for='placa'>Placa</Label>
              <Input
                type='select'
                name='placa'
                innerRef={register}
                onChange={calculate}
              >
                <option value=''>Selecione</option>
                {placas && placas.map((value) => (
                  <option key={value.nome} value={`${value.nome} [${value.potencia}]`}>{`${value.nome} [${value.potencia}]`}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md={1}>
              <Label className='form-label'>Qtd.</Label>
              <Input
                name='placa_qtd'
                id='placa_qtd'
                innerRef={register}
                onChange={() => setCustomDataField('placa_qtd')}
                onBlur={calculate}
              />
            </FormGroup>

            <Col md={4}>
              <Row>
                <Col md={9}>
                  <Label className='form-label'>Inversor 1</Label>
                  <Input
                    type='select'
                    name='inversor_1'
                    innerRef={register}
                    onChange={calculate}
                  >
                    <option value=''>Selecione</option>
                    {inversores && inversores.map((value) => (
                      <option key={value.nome} value={value.nome}>{value.nome}</option>
                    ))}
                  </Input>
                </Col>
                <Col md={3}>
                  <Label className='form-label'>Qtd.</Label>
                  <Input
                    name='inversor_1_qtd'
                    innerRef={register}
                    onBlur={calculate}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <Label className='form-label'>Inversor 2</Label>
                  <Input
                    type='select'
                    name='inversor_2'
                    innerRef={register}
                    onChange={calculate}
                  >
                    <option value=''>Selecione</option>
                    {inversores && inversores.map((value) => (
                      <option key={value.nome} value={value.nome}>{value.nome}</option>
                    ))}
                  </Input>
                </Col>
                <Col md={3}>
                  <Label className='form-label'>Qtd.</Label>
                  <Input
                    name='inversor_2_qtd'
                    innerRef={register}
                    onBlur={calculate}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <Label className='form-label'>Inversor 3</Label>
                  <Input
                    type='select'
                    name='inversor_3'
                    innerRef={register}
                    onChange={calculate}
                  >
                    <option value=''>Selecione</option>
                    {inversores && inversores.map((value) => (
                      <option key={value.nome} value={value.nome}>{value.nome}</option>
                    ))}
                  </Input>
                </Col>
                <Col md={3}>
                  <Label className='form-label'>Qtd.</Label>
                  <Input
                    name='inversor_3_qtd'
                    innerRef={register}
                    onBlur={calculate}
                  />
                </Col>
              </Row>
            </Col>

            <FormGroup tag={Col} md={4}>
              <Label className='form-label' for='transformador'>
                Transformador
              </Label>
              <Input
                type='select'
                name='transformador'
                innerRef={register}
                onBlur={calculate}
              >
                <option value=''>Selecione</option>
                {transformadores && transformadores.map((value) => (
                  <option key={value.nome} value={`${value.preco} || ${value.nome}`}>{value.nome}</option>
                ))}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <Col md={12}>
              <b>Itens opcionais</b>
            </Col>
          </Row>

          <Row>
            <FormGroup tag={Col} md={2}>
              <Label className='form-label' for='opcional_cerca'>
                Cerca
              </Label>
              <Input
                type='select'
                name='opcional_cerca'
                innerRef={register}
                onChange={calculate}
                disabled={!ENABLE_ITENS_OPCIONAIS}
              >
                <option value='nao'>Não</option>
                <option value='sim'>Sim</option>
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md={2}>
              <Label className='form-label' for='opcional_brita'>
                Brita
              </Label>
              <Input
                type='select'
                name='opcional_brita'
                innerRef={register}
                onChange={calculate}
                disabled={!ENABLE_ITENS_OPCIONAIS}
              >
                <option value='nao'>Não</option>
                <option value='sim'>Sim</option>
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md={3}>
              <Label className='form-label' for='opcional_garantia_estendida'>
                Garantia estendida
              </Label>
              <Input
                type='select'
                name='opcional_garantia_estendida'
                innerRef={register}
                onChange={calculate}
                disabled={!ENABLE_ITENS_OPCIONAIS}
              >
                <option value='nao'>Não</option>
                <option value='sim'>Sim</option>
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md={3}>
              <Label className='form-label' for='opcional_estacao_solarimetrica'>
                Estacação Solarimétrica
              </Label>
              <Input
                type='select'
                name='opcional_estacao_solarimetrica'
                innerRef={register}
                onChange={calculate}
                disabled={!ENABLE_ITENS_OPCIONAIS}
              >
                <option value='nao'>Não</option>
                <option value='sim'>Sim</option>
              </Input>
            </FormGroup>
            <FormGroup tag={Col} md={2}>
              <Label className='form-label' for='opcional_abrigo'>
                Abrigo
              </Label>
              <Input
                type='select'
                name='opcional_abrigo'
                innerRef={register}
                onChange={calculate}
                disabled={!ENABLE_ITENS_OPCIONAIS}
              >
                <option value='nao'>Não</option>
                <option value='sim'>Sim</option>
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <Col md={12}>
              <Label className='form-label' for='preco_do_kit_custom'>
                Preço do KIT
              </Label>
              <Input
                className='text-right'
                name='preco_do_kit_custom'
                innerRef={register}
                onBlur={calculate}
              />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md={12} className='mb-2'>
              <b>Resultados do Projeto</b>
            </Col>
          </Row>

          <Row>
            <FormGroup tag={Col} md='8'>
              <Label className='form-label'>Potência do sistema sugerida para 100% de autonomia</Label>
              <Input
                name='potencia_sistema_sugerida'
                innerRef={register}
                disabled
              />
            </FormGroup>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label' for='autonomia_desejada'>Autonomia desejada(%)</Label>
              <Input
                type='number'
                name='autonomia_desejada'
                innerRef={register}
                onBlur={calculate}
                className='text-right'
                defaultValue='100'
                disabled
              />
            </FormGroup>
          </Row>

          <Row className='mt-2'>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Potência instalada</Label>
              <Input name='potencia_instalada' innerRef={register} disabled />
            </FormGroup>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Autonomia atendida</Label>
              <Input name='autonomia_atendida' innerRef={register} disabled />
            </FormGroup>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Área aprox. necessária para instalação</Label>
              <Input name='area_aproximada_instalacao' innerRef={register} disabled />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Consumo médio antes da instalação do sistema</Label>
              <Input name='consumo_medio_mensal_2' innerRef={register} disabled />
            </FormGroup>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Estimativa de geração média mensal</Label>
              <Input name='estimativa_geracao_media_mensal' innerRef={register} disabled />
            </FormGroup>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Estimativa de geração média anual</Label>
              <Input name='estimativa_geracao_media_anual' innerRef={register} disabled />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Custo médio aprox. da nova fatura de energia</Label>
              <Input name='custo_medio_aproximado_nova_fatura_energia' innerRef={register} disabled />
            </FormGroup>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Economia média mensal estimada</Label>
              <Input name='economia_media_mensal_estimada' innerRef={register} disabled />
            </FormGroup>
            <FormGroup tag={Col} md='4'>
              <Label className='form-label'>Economia média anual estimada</Label>
              <Input name='economia_media_anual_estimada' innerRef={register} disabled />
            </FormGroup>
          </Row>

          <div className='d-flex justify-content-between'>
            <Button.Ripple color='primary' className='btn-prev' onClick={() => stepper.previous()}>
              <ArrowLeft size={14} className='align-middle mr-sm-25 mr-0'></ArrowLeft>
              <span className='align-middle d-sm-inline-block d-none'>Voltar</span>
            </Button.Ripple>
            <Button.Ripple color='primary' className='btn-next' onClick={() => stepper.next()}>
              <span className='align-middle d-sm-inline-block d-none'>Avançar</span>
              <ArrowRight size={14} className='align-middle ml-sm-25 ml-0'></ArrowRight>
            </Button.Ripple>
          </div>
        </Form>
      </Fragment>
    )
  }

  const DadosDetalhes = ({ stepper, type }) => {
    return (
      <Fragment>
        <div className={classnames({ 'd-none': userRole && userRole !== 3 })}>
          <Row>
            <Col>
              <p>Sua proposta está quase pronta! Avance para finalizar</p>
            </Col>
          </Row>
        </div>

        <div className={classnames({ 'd-none': userRole && userRole === 3 })}>
          <div className='content-header'>
            <h5 className='mb-0'>Orçamento</h5>
            <small>Informações detalhadas do projeto</small>
          </div>
          <Form onSubmit={e => e.preventDefault()}>
            <Row>
              <Col md={9}>
                <Label className='form-label'>
                  Potência do sistema
                </Label>
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_potencia_do_sistema'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
            </Row>

            <hr />

            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Elaboração e dimensionamento do projeto [TOTAL]
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  name='proposta_elaboracao_dimensionamento_projeto_total'
                  innerRef={register}
                  className='text-right'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Visita técnica-comercial
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='visita_tecnica_comercial'
                  innerRef={register}
                  onChange={() => setCustomDataField('visita_tecnica_comercial')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Análise e elaboração da proposta
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='analise_elaboracao_proposta'
                  innerRef={register}
                  onChange={() => setCustomDataField('analise_elaboracao_proposta')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Visita técnica (Levantamento)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='visita_tecnica_levantamento'
                  innerRef={register}
                  onChange={() => setCustomDataField('visita_tecnica_levantamento')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Elaboração e dimensionamento do projeto
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='elaboracao_dimensionamento_projeto'
                  innerRef={register}
                  onChange={() => setCustomDataField('elaboracao_dimensionamento_projeto')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Processo de acesso a rede (Distribuidora, órgãos públicos)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='processo_acesso_rede'
                  innerRef={register}
                  onChange={() => setCustomDataField('processo_acesso_rede')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Seguros
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='seguros'
                  innerRef={register}
                  onChange={() => setCustomDataField('seguros')}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <hr />

            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  INSTALAÇÃO (CIVIL e ELÉTRICA)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  name='proposta_instalacao_civil_eletrica_total'
                  innerRef={register}
                  className='text-right'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Frete
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='frete'
                  innerRef={register}
                  onChange={() => setCustomDataField('frete')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Materiais Elétricos (Proteção AC / Eletrodutos, conexões e acessórios / Placas de identificação, advertência e sinalização)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='materiais_eletricos'
                  innerRef={register}
                  onChange={() => setCustomDataField('materiais_eletricos')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Transformador
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='transformador_field'
                  innerRef={register}
                  onChange={() => setCustomDataField('transformador_field')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Diarias (Equipe de instalação)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='diarias_equipe_instalacao'
                  innerRef={register}
                  onChange={() => setCustomDataField('diarias_equipe_instalacao')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Deslocamento/Hospedagem (Equipe de instalação)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='deslocamento_hospedagem_equipe_instalacao'
                  innerRef={register}
                  onChange={() => setCustomDataField('deslocamento_hospedagem_equipe_instalacao')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Alimentação (Equipe de instalação)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='alimentacao_equipe_instalacao'
                  innerRef={register}
                  onChange={() => setCustomDataField('alimentacao_equipe_instalacao')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Andaime
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='andaime'
                  innerRef={register}
                  onChange={() => setCustomDataField('andaime')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Descarga Kit Fotovoltaico
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='descarga_kit_fotovoltaico'
                  innerRef={register}
                  onChange={() => setCustomDataField('descarga_kit_fotovoltaico')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Reserva Serviços
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='reserva_servicos'
                  id='reserva_servicos'
                  innerRef={register}
                  onChange={() => setCustomDataField('reserva_servicos')}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <hr />

            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  OBRAS CIVIS
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  name='proposta_obras_civis_total'
                  innerRef={register}
                  className='text-right'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  CIVIL (Terraplanagem e Fundação)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='civil'
                  innerRef={register}
                  onChange={() => setCustomDataField('civil')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Alvenaria (Construção de abrigo /Tubulação embutida) / Contrapeso Laje)
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='alvenaria'
                  innerRef={register}
                  onChange={() => setCustomDataField('alvenaria')}
                  onBlur={calculate}
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  Aterramento
                </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='aterramento'
                  innerRef={register}
                  onChange={() => setCustomDataField('aterramento')}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <hr />

            <Row className='mb-1'>
              <Col md={3}>
                <Label className='form-label'>
                  ADICIONAIS
                </Label>
              </Col>
              <Col md={6}>
                <Input
                  className='text-left'
                  name='descricao_adicionais'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='adicionais'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <Row className='mb-1'>
              <Col md={3}>
                <Label className='form-label'>
                  ADICIONAIS
                </Label>
              </Col>
              <Col md={6}>
                <Input
                  className='text-left'
                  name='descricao_adicionais_2'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='adicionais_2'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <Row className='mb-1'>
              <Col md={3}>
                <Label className='form-label'>
                  ADICIONAIS
                </Label>
              </Col>
              <Col md={6}>
                <Input
                  className='text-left'
                  name='descricao_adicionais_3'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='adicionais_3'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <Row className='mb-1'>
              <Col md={3}>
                <Label className='form-label'>
                  ADICIONAIS
                </Label>
              </Col>
              <Col md={6}>
                <Input
                  className='text-left'
                  name='descricao_adicionais_4'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='adicionais_4'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <Row className='mb-1'>
              <Col md={3}>
                <Label className='form-label'>
                  ADICIONAIS
                </Label>
              </Col>
              <Col md={6}>
                <Input
                  className='text-left'
                  name='descricao_adicionais_5'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
              <Col md={3}>
                <Input
                  className='text-right'
                  name='adicionais_5'
                  innerRef={register}
                  onBlur={calculate}
                />
              </Col>
            </Row>

            <hr />

            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>OUTRAS DESPESAS E TAXAS</Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}></Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>IMPOSTOS E TAXAS</Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}></Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>Impostos</Label>
              </Col>
              <Col md={3}>
                <Input
                  name='iss'
                  innerRef={register}
                  onChange={() => setCustomDataField('iss')}
                  onBlur={calculate}
                  className='text-right'
                />
              </Col>
              <Col md={3}>
                <Input name='proposta_iss' innerRef={register} className='text-right' disabled />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>CUSTOS FIXOS E VARIÁVEIS</Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input
                  name='proposta_custos_fixos_variaveis_total'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>Custo administrativo</Label>
              </Col>
              <Col md={3}>
                <Input
                  name='custo_administrativo'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                />
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_custo_administrativo'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>Comissão (VENDEDOR INTERNO)</Label>
              </Col>
              <Col md={3}>
                <Input
                  name='comissao_vendedor_interno'
                  innerRef={register}
                  onBlur={calculate}
                  className='text-right'
                />
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_comissao_vendedor_interno'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>Comissão (Representante/ Indicação/Arquiteto)</Label>
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_comissao_representante_perc'
                  innerRef={register}
                  onChange={() => setCustomDataField('comissao_representante')}
                  onBlur={calculate}
                  className='text-right'
                />
              </Col>
              <Col md={3}>
                <Input name='proposta_comissao_representante' innerRef={register} className='text-right' disabled />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>LUCRO LÍQUIDO (do VALOR TOTAL)</Label>
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_lucro_liquido_valor_total_perc'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_lucro_liquido_valor_total'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>
                  LUCRO LÍQUIDO (do VALOR SERVIÇOS)
                </Label>
              </Col>
              <Col md={2}>
                <Input
                  className='text-right'
                  name='lucro_liquido_servicos_perc'
                  innerRef={register}
                  onChange={() => setCustomDataField('lucro')}
                  onBlur={calculate}
                />
              </Col>
              <Col md={3}></Col>
            </Row>

            <hr />

            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'> EQUIPAMENTOS (KIT FOTOVOLTAICO) </Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}></Col>
            </Row>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'> Preço total dos equipamentos </Label>
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_taxas_fixas_preco_kwp_equipamentos'
                  innerRef={register}
                  disabled
                  className='text-right d-none'
                />
              </Col>
              <Col md={3}>
                <Input
                  name='proposta_equipamentos'
                  innerRef={register}
                  disabled
                  className='text-right'
                />
              </Col>
            </Row>
          </Form>
        </div>
        <div className='d-flex justify-content-between'>
          <Button.Ripple color='primary' className='btn-prev' onClick={() => stepper.previous()}>
            <ArrowLeft size={14} className='align-middle mr-sm-25 mr-0'></ArrowLeft>
            <span className='align-middle d-sm-inline-block d-none'>Voltar</span>
          </Button.Ripple>
          <Button.Ripple color='primary' className='btn-next' onClick={() => stepper.next()}>
            <span className='align-middle d-sm-inline-block d-none'>Avançar</span>
            <ArrowRight size={14} className='align-middle ml-sm-25 ml-0'></ArrowRight>
          </Button.Ripple>
        </div>
      </Fragment>
    )
  }

  const DadosFinal = ({ stepper, type }) => {
    return (
      <Fragment>
        <div className='content-header'>
          <h5 className='mb-0'>Finalizar</h5>
          <small>Overview do projeto e finalização</small>
        </div>

        {userRole && userRole === 3 && (
          <div className={classnames({ 'd-none': (userRole && userRole === 3 && parametrosProposta && parametrosProposta.limitador_kwp && proposta.potencia_do_sistema && proposta.potencia_do_sistema < parametrosProposta.limitador_kwp) })}>
            A sua proposta será elaborada por um orçamentista
          </div>
        )}

        <div className={classnames({ 'd-none': (userRole && userRole === 3 && parametrosProposta && parametrosProposta.limitador_kwp && proposta.potencia_do_sistema && proposta.potencia_do_sistema > parametrosProposta.limitador_kwp) })}>
          <Form onSubmit={e => e.preventDefault()}>
            <Row className='mb-1'>
              <Col md={6}>
                <Label className='form-label'>TOTAL GERAL</Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input name='total_geral' innerRef={register} className='text-right' disabled />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col md={6}>
                <Label className='form-label'>R$/Wp</Label>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Input name='total_rs_wp' innerRef={register} className='text-right' disabled />
              </Col>
            </Row>

            <div className={classnames({ 'd-none': userRole && userRole === 3 })}>
              <Row className='mb-1'>
                <Col md={6}>
                  <Label className='form-label'>Projeto Repensa</Label>
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <Input name='total_projeto_repensa' innerRef={register} className='text-right' disabled />
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <Label className='form-label'>Projeto Direto</Label>
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <Input name='total_projeto_direto' innerRef={register} type='text'  className='text-right' disabled />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <canvas id="chart_01" width="450" height="350" style={{display: 'none'}}></canvas>
                  <canvas id="chart_02" width="450" height="350" style={{display: 'none'}}></canvas>
                </Col>
              </Row>

            </div>

            <div className='d-flex justify-content-between'>
              <Button.Ripple color='primary' className='btn-prev' onClick={() => stepper.previous()}>
                <ArrowLeft size={14} className='align-middle mr-sm-25 mr-0'></ArrowLeft>
                <span className='align-middle d-sm-inline-block d-none'>Voltar</span>
              </Button.Ripple>
              <div>
                <Button.Ripple
                  color='success mr-1'
                  className='btn-submit'
                  onClick={salvarProposta}
                >
                  Salvar
                </Button.Ripple>
                <Button.Ripple
                  color='secondary'
                  onClick={gerarPropostaCallback}
                >
                  Gerar PDF
                </Button.Ripple>
              </div>
            </div>
          </Form>
        </div>

      </Fragment>
    )
  }

  const steps = [
    {
      id: 'dados-do-cliente',
      title: 'Dados do cliente',
      subtitle: '',
      icon: <User size={18} />,
      content: <DadosCliente stepper={stepper} type='wizard-modern' />
    },
    {
      id: 'dados-do-projeto',
      title: 'Dados do projeto',
      subtitle: '',
      icon: <FileText size={18} />,
      content: <DadosProjeto stepper={stepper} type='wizard-modern' />
    },
    {
      id: 'orcamento',
      title: 'Orçamento',
      subtitle: '',
      icon: <DollarSign size={18} />,
      content: <DadosDetalhes stepper={stepper} type='wizard-modern' />
    },
    {
      id: 'final',
      title: 'Final',
      subtitle: '',
      icon: <CheckCircle size={18} />,
      content: <DadosFinal stepper={stepper} type='wizard-modern' />
    }
  ]

  return (
    <div className='modern-horizontal-wizard'>
      <Wizard
        type='modern-horizontal'
        ref={ref}
        steps={steps}
        options={{
          linear: false
        }}
        instance={el => setStepper(el)}
      />
    </div>
  )
}

export default Formulario2;
