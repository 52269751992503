import { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, Input, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../context/Auth';
import DataTable from 'react-data-table-component';
import { ChevronDown } from 'react-feather';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const ConfigTransformadores = () => {
  const { toggleLoading, authenticated } = useAuth();
  const { register, handleSubmit, setValue } = useForm();
  const [transformadores, setTransformadores] = useState();

  const getData = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);

    try {
      const response = await api.post('/get_transformadores', formData);
      const { data } = response;
      
      if (data && data.data) {
        setTransformadores(data.data);
      }
    } catch (err) {
      let errorMessage = 'Erro ao realizar operação';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
  }, [setTransformadores]);

  const onSubmit = useCallback(async (data) => {
    if (data.nome && data.preco) {
      const { hash } = authenticated;

      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('nome', data.nome);
      formData.append('preco', parseFloat(data.preco));
  
      toggleLoading(true);

      try {
        const response = await api.post('/criar_transformador', formData);
        if (response && response.data && response.data.message) {
          toast.success(response.data.message);
          setValue('nome', '');
          setValue('preco', '');
        }
        getData();
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }

      toggleLoading(false);
    }
  }, [toggleLoading, authenticated, getData, setValue]);

  const onRemove = useCallback(async (id) => {
    if (id) {
      const { hash } = authenticated;

      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('login_key', hash);
      formData.append('id', id);
  
      toggleLoading(true);

      try {
        const response = await api.post('/remove_transformador', formData);
        if (response && response.data && response.data.message) {
          toast.success(response.data.message);
        }
        getData();
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }

      toggleLoading(false);
    }
  }, [toggleLoading, authenticated, getData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = [
    {
      selector: 'nome',
      name: 'Nome'
    },
    {
      selector: 'preco',
      name: 'Preço'
    },
    {
      name: 'Ações',
      cell: (row) => (
        <Fragment>
          <Button
            color='danger'
            className='mr-1'
            onClick={() => onRemove(row.id)}
          >
            Remover
          </Button>
        </Fragment>
      )
    }
  ];

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Label>
                Adicionar inversor
              </Label>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col md={6}>
              <Input
                placeholder='Nome'
                name='nome'
                innerRef={register}
              />
            </Col>
            <Col md={6}>
              <Input
                type='number'
                placeholder='Preço'
                name='preco'
                innerRef={register}
              />
            </Col>
          </Row>
          <Row>
            <Col>
            <Button
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row className='mb-4'>
            <DataTable
              noHeader
              pagination
              subHeader
              responsive
              columns={columns}
              sortIcon={<ChevronDown />}
              className='react-dataTable'
              data={transformadores}
              noDataComponent={<p>Nenhuma informação para exibir</p>}
            />
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default ConfigTransformadores;
