/* Dashboard.js */

import React from 'react';
import { Row, Col } from 'reactstrap';
import CardAcoesRapidas from './components/CardAcoesRapidas';
import AnalyticsDashboard from './AnalyticDashboard';
import '@styles/react/libs/charts/apex-charts.scss';
import '@styles/base/pages/dashboard-ecommerce.scss';

const Dashboard = () => {
  return (
    <div id='dashboard-ecommerce'>
      <Row className='match-height'>
        <Col xl='12' md='12' xs='12'>
          <CardAcoesRapidas />
        </Col>
      </Row>

      <Row>
        <Col>
          <AnalyticsDashboard />
        </Col>
      </Row>
      
    </div>
  );
}

export default Dashboard;
