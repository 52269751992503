import React from 'react'
import '../assets/loader.css'

import { useAuth } from '../context/Auth'

export default () => {
  const { loading } = useAuth()

  return (
    <>
      {loading && (
        <div className={"loader_container"}>
          <div className="loader"></div>
        </div>
      )}
    </>
  )
}