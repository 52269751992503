import React, { createContext, useState, useCallback, useContext } from 'react'
import api from '../services/api'
import { toast } from 'react-toastify'

const AuthContext = createContext()
const localStorageUserData = 'repensa_userdata'

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY

function AuthProvider({ children }) {
  const [loading, setLoading] = useState(null)
  const toggleLoading = useCallback((state) => {
    setLoading(state)
  }, [])

  const [authenticated, setAuthenticated] = useState(() => {
    const user = localStorage.getItem(localStorageUserData)
    if (user) {
      const userData = JSON.parse(user)
      return userData
    } else {
      return false
    }
  })

  const login = useCallback(async ({ email, password }) => {
    try {
      const formData = new FormData()
      formData.append('access_key', ACCESS_KEY)
      formData.append('email', email)
      formData.append('password', password)

      const response = await api.post('/login', formData)
      const { data } = response
      if (data && data.result && data.result === 'success') {
        localStorage.setItem(localStorageUserData, JSON.stringify(data))
        setAuthenticated(data)
        toast.success(`Bem vindo ${data.name}!`)
      } else {
        return Promise.reject()
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }, [setAuthenticated])

  const logout = useCallback(async () => {
    setAuthenticated()

    localStorage.setItem(localStorageUserData, '')
    localStorage.removeItem(localStorageUserData)
  }, [setAuthenticated])
  
  return (
    <AuthContext.Provider
      value={{
        loading,
        toggleLoading,
        authenticated,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  return context
}

export { useAuth, AuthProvider }
