/* AnalyticDashboard.js */

import { useCallback, useContext, useEffect, useState } from 'react';
import { kFormatter } from '@utils';
import Avatar from '@components/avatar';
import AvatarGroup from '@components/avatar-group';
import jsonImg from '@src/assets/images/icons/json.png';
import ceo from '@src/assets/images/portrait/small/avatar-s-9.jpg';
import { ThemeColors } from '@src/utility/context/ThemeColors';
import SupportTracker from '@src/views/ui-elements/cards/analytics/SupportTracker';
import { Row, Col, Media } from 'reactstrap';
import OrdersReceived from '@src/views/ui-elements/cards/statistics/OrdersReceived';
import SubscribersGained from '@src/views/ui-elements/cards/statistics/SubscribersGained';
import '@styles/react/libs/charts/apex-charts.scss';
import api from '../../services/api';
import { toast } from 'react-toastify';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const AnalyticsDashboard = () => {
  const { colors } = useContext(ThemeColors);

  const [estatisticas, setEstatisticas] = useState({
    logins_7_dias: 0,
    propostas: 0,
    propostas_7_dias: 0
  });

  const getEstatisticas = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);

    try {
      const response = await api.post('/get_estatisticas', formData);
      const { data } = response;
      if (data) { setEstatisticas(data); }
    } catch (err) {
      let errorMessage = 'Erro ao realizar operação';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
  }, [api, setEstatisticas]);

  useEffect(() => {
    getEstatisticas();
  }, [getEstatisticas]);

  const avatarGroupArr = [
    {
      title: 'Billy Hopkins',
      img: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
      placement: 'bottom',
      imgHeight: 33,
      imgWidth: 33
    },
    {
      title: 'Amy Carson',
      img: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
      placement: 'bottom',
      imgHeight: 33,
      imgWidth: 33
    },
    {
      title: 'Brandon Miles',
      img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
      placement: 'bottom',
      imgHeight: 33,
      imgWidth: 33
    },
    {
      title: 'Daisy Weber',
      img: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      placement: 'bottom',
      imgHeight: 33,
      imgWidth: 33
    },
    {
      title: 'Jenny Looper',
      img: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
      placement: 'bottom',
      imgHeight: 33,
      imgWidth: 33
    }
  ],
    data = [
      {
        title: '12 Invoices have been paid',
        content: 'Invoices have been paid to the company.',
        meta: '',
        metaClassName: 'mr-1',
        customContent: (
          <Media>
            <img className='mr-1' src={jsonImg} alt='data.json' height='23' />
            <Media className='mb-0' body>
              data.json
            </Media>
          </Media>
        )
      },
      {
        title: 'Client Meeting',
        content: 'Project meeting with john @10:15am.',
        meta: '',
        metaClassName: 'mr-1',
        color: 'warning',
        customContent: (
          <Media className='align-items-center'>
            <Avatar img={ceo} />
            <Media className='ml-50' body>
              <h6 className='mb-0'>John Doe (Client)</h6>
              <span>CEO of Infibeam</span>
            </Media>
          </Media>
        )
      },
      {
        title: 'Create a new project for client',
        content: 'Add files to new design folder',
        color: 'info',
        meta: '',
        metaClassName: 'mr-1',
        customContent: <AvatarGroup data={avatarGroupArr} />
      },
      {
        title: 'Create a new project for client',
        content: 'Add files to new design folder',
        color: 'danger',
        meta: '',
        metaClassName: 'mr-1'
      }
    ]

  return (
    <Row className='match-height'>
      <Col lg='4' sm='6'>
        <SubscribersGained kFormatter={kFormatter} logins={estatisticas.logins_7_dias} />
      </Col>
      <Col lg='4' sm='6'>
        <OrdersReceived kFormatter={kFormatter} warning={colors.warning.main} propostas={estatisticas.propostas} />
      </Col>
      <Col lg='4' xs='12'>
        <SupportTracker primary={colors.primary.main} danger={colors.danger.main} propostas={estatisticas.propostas_7_dias} />
      </Col>
    </Row>
  )
}

export default AnalyticsDashboard;
