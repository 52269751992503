import { Fragment } from 'react'
import { Row, Col, Card, CardHeader, CardBody, Button, ListGroup, ListGroupItem } from 'reactstrap'

const Documentos = () => {
  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Documentos</h1>
        </Col>
      </Row>

      <Row className="mt-2">

        <Col md={12}>
          <ListGroup>
            <ListGroupItem tag='a' href='#'>
              <div className='d-flex justify-content-between w-100'>
                <h5 className='mb-1'>Regras de utilização de nome</h5>
                <small>.pdf</small>
              </div>
              <p className='mb-1'>
                Documento com regras da utilização do nome da Repensa Energia
              </p>
            </ListGroupItem>
            <ListGroupItem tag='a' href='#'>
              <div className='d-flex justify-content-between w-100'>
                <h5 className='mb-1'>Logo Repensa Energia</h5>
                <small>.png</small>
              </div>
              <p className='mb-1'>
                Logo em PGN da Repensa Energia
              </p>
            </ListGroupItem>
          </ListGroup>
        </Col>

      </Row>
    </Fragment>
  )
}

export default Documentos
