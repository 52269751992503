import classnames from 'classnames'
import Avatar from '@components/avatar'
import { TrendingUp, User, Box, DollarSign, Plus } from 'react-feather'
import { Card, CardHeader, CardTitle, CardBody, CardText, Row, Col, Media, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

const CardAcoesRapidas = ({ cols }) => {
  const data = [
    {
      title: '0',
      subtitle: 'Propostas',
      color: 'light-primary',
      icon: <TrendingUp size={24} />
    },
    {
      title: '0',
      subtitle: 'Usuários',
      color: 'light-info',
      icon: <User size={24} />
    },
    {
      title: 'R$ 0',
      subtitle: 'Valor',
      color: 'light-success',
      icon: <DollarSign size={24} />
    }
  ]

  const renderData = () => {
    return data.map((item, index) => {
      const margin = Object.keys(cols)
      return (
        <Col
          key={index}
          {...cols}
          className={classnames({
            [`mb-2 mb-${margin[0]}-0`]: index !== data.length - 1
          })}
        >
          <Media>
            <Avatar color={item.color} icon={item.icon} className='mr-2' />
            <Media className='my-auto' body>
              <h4 className='font-weight-bolder mb-0'>{item.title}</h4>
              <CardText className='font-small-3 mb-0'>{item.subtitle}</CardText>
            </Media>
          </Media>
        </Col>
      )
    })
  }

  return (
    <Card className='card-statistics'>
      <CardHeader>
        <CardTitle tag='h4'>Acesso rápido</CardTitle>
      </CardHeader>
      <CardBody className='statistics-body'>
        <Row>
          <Col>
            <Button
              color='primary'
              tag={Link}
              to='proposta'
              className='mr-2'
            >
              Nova proposta <Plus />
            </Button>
            <Button
              tag={Link}
              to='propostas'
            >
              Propostas
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CardAcoesRapidas;
