import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { User, HelpCircle, Power, CreditCard, Mail, CheckSquare, MessageSquare, Settings } from 'react-feather'
import { useAuth } from '../../../../context/Auth'
import Avatar from '@components/avatar'
import defaultAvatar from '@src/assets/images/portrait/small/profile.png'
import UserRoles from '../../../../enums/UserRoles'

const UserDropdown = () => {
  const [userData, setUserData] = useState(null)
  const [userRole, setUserRole] = useState('')
  const { logout, authenticated } = useAuth()

  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  useEffect(() => {
    const data = authenticated
    if (data) {
      setUserRole(UserRoles[parseInt(data.level)])
      setUserData(data)
    }
  }, [authenticated, setUserData, setUserRole])

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name font-weight-bold'>{userData && userData['name']}</span>
          <span className='user-status'>{userRole && userRole}</span>
        </div>
        <Avatar img={defaultAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag={Link} to='/perfil'>
          <User size={14} className='mr-75' />
          <span className='align-middle'>Perfil</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to='/apps/email'>
          <Mail size={14} className='mr-75' />
          <span className='align-middle'>Inbox</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to='/apps/todo'>
          <CheckSquare size={14} className='mr-75' />
          <span className='align-middle'>Tasks</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to='/apps/chat'>
          <MessageSquare size={14} className='mr-75' />
          <span className='align-middle'>Chats</span>
        </DropdownItem> */}
        <DropdownItem divider />
        {/* <DropdownItem tag={Link} to='/pages/account-settings'>
          <Settings size={14} className='mr-75' />
          <span className='align-middle'>Conta</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to='/pages/pricing'>
          <CreditCard size={14} className='mr-75' />
          <span className='align-middle'>Pricing</span>
        </DropdownItem> */}
        <DropdownItem tag={Link} to='/faq'>
          <HelpCircle size={14} className='mr-75' />
          <span className='align-middle'>FAQ</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/' onClick={handleLogout}>
          <Power size={14} className='mr-75' />
          <span className='align-middle'>Sair</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
