/* Routes.js */

import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Login from '../views/pages/authentication/Login';
import Register from '../views/pages/authentication/Register';
import ForgotPassword from '../views/pages/authentication/ForgotPassword';
import ResetPassword from '../views/pages/authentication/ResetPassword';
import Perfil from '../views/pages/conta/Perfil';
import Dashboard from '../views/dashboard/Dashboard';
import AnalyticsDashboard from '../views/dashboard/AnalyticDashboard';
import Faq from '../views/pages/faq/Faq';
import Documentos from '../views/pages/documentos/Documentos';
import Proposta from '../views/pages/proposta/Proposta';
import Propostas from '../views/pages/proposta/Propostas';
import AdminDashboard from '../views/pages/admin/AdminDashboard';
import UsersPage from '../views/pages/users/UsersPage';
import EditUser from '../views/pages/users/EditUser';
import CreateUser from '../views/pages/users/CreateUser';
import GerenciarDocumentos from '../views/pages/documentos/GerenciarDocumentos';
import Configuracoes from '../views/pages/admin/Configuracoes';
import ConfiguracoesSistema from '../views/pages/admin/ConfiguracoesSistema';
import Representantes from '../views/pages/representantes/Representantes';
import PropostaEdicao from '../views/pages/proposta/PropostaEdicao';
import PropostaEdicaoForm from '../views/pages/proposta/PropostaEdicaoForm';

export default function Routes() {
  return (
    <Switch>
      <Route path='/' isLogin exact component={Login} />
      <Route path='/cadastro' isLogin exact component={Register} />
      <Route path='/esqueci-a-senha' isPublic exact component={ForgotPassword} />
      <Route path='/redefinir-senha/:hash' isPublic exact component={ResetPassword} />

      <Route path='/perfil' isPrivate exact component={Perfil} />

      <Route path='/dashboard' isPrivate exact component={Dashboard} />
      <Route path='/dashboard-analitico' isPrivate exact component={AnalyticsDashboard} />
      <Route path='/faq' isPrivate exact component={Faq} />
      <Route path='/documentos' isPrivate exact component={Documentos} />
      <Route path='/proposta' isPrivate exact component={Proposta} />
      <Route path='/edicao-proposta/:id' isPrivate exact component={PropostaEdicao} />
      <Route path='/edicao-proposta-form/:id/:v' isPrivate exact component={PropostaEdicaoForm} />
      <Route path='/propostas' isPrivate exact component={Propostas} />

      <Route path='/admin' isPrivate isAdmin exact component={AdminDashboard} />
      <Route path='/usuarios' isPrivate isAdmin exact component={UsersPage} />
      <Route path='/usuario/:id' isPrivate isAdmin exact component={EditUser} />
      <Route path='/representantes' isPrivate isAdmin exact component={Representantes} />
      <Route path='/criar-usuario' isPrivate isAdmin exact component={CreateUser} />
      <Route path='/gerenciar-documentos' isPrivate isAdmin exact component={GerenciarDocumentos} />
      <Route path='/configuracoes' isPrivate isAdmin exact component={Configuracoes} />
      <Route path='/configuracoes-sistema' isPrivate isAdmin exact component={ConfiguracoesSistema} />
    </Switch>
  );
}
