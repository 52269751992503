/* PropostaEdicaoForm.js */

import { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import FormProposta from './components/Formulario2';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const PropostaEdicaoForm = () => {
  const { id, v } = useParams();

  const [proposta, setProposta] = useState(null);
  const [versaoNumber, setVersaoNumber] = useState(0);
  const [newVersao, setNewVersao] = useState(false);

  const getData = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('id', id);

    try {
      const response = await api.post('/get_versao', formData);
      const { data } = response;
      
      if (data && data.data) {
        setProposta(data.data);
      }
    } catch (err) {
      let errorMessage = 'Erro ao recuperar proposta';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setVersaoNumber(parseInt(v));
    if (parseInt(v) > 0) {
      setNewVersao(true);
    }
  }, [v, setVersaoNumber, setNewVersao]);

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Editar Proposta</h1>
        </Col>
      </Row>

      {proposta && (
        <>
          <Row className='mt-2'>
            <Col>
              <Card>
                <CardBody>
                <h4>ID: {proposta.proposta_id}</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col sm='12'>
          <FormProposta propostaEdit={proposta} newVersao={newVersao} vNumber={versaoNumber} />
        </Col>
      </Row>
    </Fragment>
  )
}

export default PropostaEdicaoForm;
