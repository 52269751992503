import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap'

const AdminDashboard = () => {
  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Painel de administração</h1>
        </Col>
      </Row>

      <Row className="mt-2">

        <Col md={6}>
          <Card>
            <CardHeader>
              <h2>Usuários</h2>
            </CardHeader>
            <CardBody>
              <Button
                color='primary'
                tag={Link}
                to='/usuarios'
              >
                Gerenciar usuários
              </Button>
              <Button
                color='primary'
                tag={Link}
                to='/representantes'
                className='ml-1'
              >
                Gerenciar representantes
              </Button>
            </CardBody>
          </Card>
        </Col>

        {/* <Col md={6}>
          <Card>
            <CardHeader>
              <h2>Documentos</h2>
            </CardHeader>
            <CardBody>
              <Button
                color='primary'
                tag={Link}
                to='/gerenciar-documentos'
              >
                Gerenciar documentos
              </Button>
              <Button
                color='success'
                className='ml-1'
              >
                Adicionar documento
              </Button>
            </CardBody>
          </Card>
        </Col> */}

        <Col md={6}>
          <Card>
            <CardHeader>
              <h2>Configurações</h2>
            </CardHeader>
            <CardBody>
              <Button
                color='primary'
                tag={Link}
                to='/configuracoes'
              >
                Configurações da proposta
              </Button>
              <Button
                color='primary'
                tag={Link}
                to='/configuracoes-sistema'
                className='ml-1'
              >
                Sistema
              </Button>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </Fragment>
  )
}

export default AdminDashboard
