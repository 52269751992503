import { Fragment } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import DataTableRepensa from '../../../@core/components/datatable/DataTableRepensa';

const GerenciarDocumentos = () => {
  // TODO

  const columns = [
    { selector: 'id', name: 'ID' },
    { selector: 'documento', name: 'Documento' },
    {
      selector: 'actions',
      name: 'Ações',
      cell: row => (
        <Fragment>
          <Button
            color='primary'
            className='mr-1'
          >
            Editar
          </Button>
          <Button
            color='danger'
          >
            Remover
          </Button>
        </Fragment>
      )
    }
  ];

  const data = [
    {
      id: 1,
      documento: 'Regras de utilização de nome'
    },
    {
      id: 2,
      documento: 'Logo Repensa Energia'
    }
  ]

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Gerenciar Documentos</h1>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <DataTableRepensa columns={columns} data={data} />
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default GerenciarDocumentos
