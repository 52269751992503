/* Configuracoes.js */

import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import ConfigInversores from './components/ConfigInversores';
import ConfigParametros from './components/ConfigParametros';
import ConfigPlacas from './components/ConfigPlacas';
import ConfigTransformadores from './components/ConfigTransformadores';

const Configuracoes = () => {
  const [active, setActive] = useState('1');
  const toggle = tab => { if (active !== tab) { setActive(tab); } }

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Configurações da proposta</h1>
        </Col>
      </Row>

      <Row className='mt-2'>

        <Col md={12}>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={active === '1'}
                    onClick={() => toggle('1')}
                  >
                    Geral
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={active === '2'}
                    onClick={() => toggle('2')}
                  >
                    Placas
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={active === '3'}
                    onClick={() => toggle('3')}
                  >
                    Inversores
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={active === '4'}
                    onClick={() => toggle('4')}
                  >
                    Transformadores
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
        </Col>

        <Col md={12}>
          <TabContent className='py-20' activeTab={active}>

            <TabPane tabId='1'>
              <ConfigParametros />
            </TabPane>

            <TabPane tabId='2'>
              <ConfigPlacas />
            </TabPane>

            <TabPane tabId='3'>
              <ConfigInversores />
            </TabPane>

            <TabPane tabId='4'>
              <ConfigTransformadores />
            </TabPane>

          </TabContent>
        </Col>

      </Row>

      <Row className='mt-4'>
        <Col>
          <Button
            color='light'
            tag={Link}
            to='/admin'
            className='ml-1'
          >
            Retornar ao painel de administração
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Configuracoes;
