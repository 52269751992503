import { useCallback, useEffect } from 'react';
import { Row, Col, Card, Button, Input, Label, Alert, FormGroup } from 'reactstrap';
import api from '../../../../services/api';
import { useAuth } from '../../../../context/Auth';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const CodigoUsuario = ({user_id, codigo}) => {
  const { toggleLoading } = useAuth();
  const { register, handleSubmit, errors, setValue } = useForm();

  const onSubmit = useCallback(async (data) => {
    if (user_id && codigo !== data.codigo) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('user_id', user_id);
      formData.append('codigo', data.codigo);

      toggleLoading(true);

      try {
        const response = await api.post('/update_user_codigo_admin', formData);
        const { data } = response;
        if (data && data.result && data.result === 'success' && data.message) {
          toast.success(data.message);
        }
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }

      toggleLoading(false);
    }
  }, [user_id, codigo]);

  useEffect(() => {
    if (codigo) {
      setValue('codigo', codigo);
    }
  }, [codigo, setValue]);

  return (
    <Card className='p-3'>
      <h3>Código do usuário</h3>

      <Row className='mt-2'>
        <Col md={12}>
          <FormGroup>
            <Label>Alterar código do usuário</Label>
            <Input
              name='codigo'
              innerRef={register({
                required: 'Preencha o código'
              })}
            />
          </FormGroup>
          {errors.codigo && (
            <Alert  color='danger'>
              <div className='alert-body'>
                <span>{errors.codigo.message}</span>
              </div>
            </Alert>
          )}
          <Button
            color='primary'
            onClick={handleSubmit(onSubmit)}
          >
            Alterar
          </Button>
        </Col>

      </Row>
    </Card>
  )
}

export default CodigoUsuario;
