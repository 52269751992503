import DataTable from 'react-data-table-component';
import { ChevronDown } from 'react-feather';

const DataTableRepensa = ({columns, data}) => {
  const paginationComponentOptions = {
    rowsPerPageText: 'Linhas por página',
    rangeSeparatorText: 'de',
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos'
  }

  const noDataComponent = <p>Nenhuma informação para exibir</p>;

  return (
    <DataTable
      columns={columns}
      data={data}
      className='react-dataTable mt-1 mb-1'
      noHeader
      pagination
      responsive
      sortIcon={<ChevronDown />}
      noDataComponent={noDataComponent}
      paginationComponentOptions={paginationComponentOptions}
    />
  )
}

export default DataTableRepensa;
