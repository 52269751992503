// ** Icons Import
import { Heart } from 'react-feather'

const Footer = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-left d-block d-md-inline-block mt-25'>
        Copyright © {new Date().getFullYear()}{' '}
        <span className='d-none d-sm-inline-block'>, Todos os direitos reservados</span>
      </span>
      <span className='float-md-right d-none d-md-block'>
        Desenvolvimento <a href="https://dropco.com.br/" target="_blank" rel="noreferrer noopener">Drop</a>
      </span>
    </p>
  )
}

export default Footer
