import { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import FormProposta2 from './components/Formulario2';

const Proposta = () => {
  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Gerar proposta</h1>
        </Col>
      </Row>

      <Row>
        <Col sm='12'>
          <FormProposta2 />
        </Col>
      </Row>
    </Fragment>
  );
}
export default Proposta;
