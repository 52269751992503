import { useCallback, useRef } from 'react';
import { Row, Col, Card, Button, Input, Label, FormGroup, CardBody, CardHeader, Alert } from 'reactstrap'
import api from '../../../../services/api'
import { useAuth } from '../../../../context/Auth'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY

const AlterarSenha = () => {
  const { toggleLoading, authenticated } = useAuth();
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const current_password = useRef({});
  current_password.current = watch('new_password', '');

  const onSubmit = useCallback(async (data) => {
    const { user_id } = authenticated;

    if (user_id) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('user_id', user_id);
      formData.append('current_password', data.current_password);
      formData.append('new_password', data.new_password);

      toggleLoading(true);

      try {
        const response = await api.post('/update_user_password', formData);
        const { data } = response;
        if (data && data.result && data.result === 'success' && data.message) {
          toast.success(data.message);
          setValue('current_password', '');
          setValue('new_password', '');
          setValue('confirm_new_password', '');
        }
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }

      toggleLoading(false);
    }
  }, [authenticated, toggleLoading, setValue]);

  return (
    <Card>
      <CardHeader>
        <h3>Alterar Senha</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Senha atual</Label>
              <Input
                type='password'
                name='current_password'
                innerRef={register({
                  required: 'Preencha a senha atual'
                })}
              />
            </FormGroup>
            {errors.current_password && (
              <Alert color='danger'>
                <div className='alert-body'>
                  <span>{errors.current_password.message}</span>
                </div>
              </Alert>
            )}
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Nova senha</Label>
              <Input
                type='password'
                name='new_password'
                innerRef={register({
                  required: 'Preencha a nova senha'
                })}
              />
            </FormGroup>
            {errors.new_password && (
              <Alert color='danger'>
                <div className='alert-body'>
                  <span>{errors.new_password.message}</span>
                </div>
              </Alert>
            )}
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Confirmar nova senha</Label>
              <Input
                type='password'
                name='confirm_new_password'
                innerRef={register({
                  validate: value => value === current_password.current || 'As senhas não são iguais'
                })}
              />
            </FormGroup>
            {errors.confirm_new_password && (
              <Alert color='danger'>
                <div className='alert-body'>
                  <span>{errors.confirm_new_password.message}</span>
                </div>
              </Alert>
            )}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Button
              color='primary'
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </CardBody>
      
    </Card>
  )
}

export default AlterarSenha;
