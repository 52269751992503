const DateHelper = {

  convertServerDate: (date) => {
    let result = '';
    if (date) {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
      const hour = date.substring(11, 13);
      const minutes = date.substring(14, 16);
      result = `${day}/${month}/${year} ${hour}:${minutes}`;
    }
    return result;
  },

};

export default DateHelper;
