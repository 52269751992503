import { Fragment, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import InputPasswordToggle from '@components/input-password-toggle'
import { Card, CardBody, CardTitle, CardText, Form, FormGroup, Label, Input, CustomInput, Button, Row, Col, Alert } from 'reactstrap'
import '@styles/base/pages/page-auth.scss'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import logo from '@src/assets/images/logo/repensa.png'
import { useAuth } from '../../../context/Auth'

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY

const Register = () => {
  const estados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
  ]

  const { register, errors, handleSubmit, reset } = useForm()
  const { toggleLoading } = useAuth()

  const onSubmit = useCallback(async (fields) => {
    if (fields.politica_privacidade === false) {
      toast.warning('Aceite a política de privacidade')
      return false
    }

    toggleLoading(true)
    try {
      const formData = new FormData()
      formData.append('access_key', ACCESS_KEY)

      formData.append('bairro', fields.bairro)
      formData.append('cep', fields.cep)
      formData.append('cidade', fields.cidade)
      formData.append('email', fields.email)
      formData.append('estado', fields.estado)
      formData.append('experiencia', fields.experiencia)
      formData.append('indicacao', fields.indicacao)
      formData.append('nome', fields.nome)
      formData.append('senha', fields.senha)
      formData.append('telefone', fields.telefone)

      const response = await api.post('/create_user_commom', formData)
      const { data } = response

      toggleLoading(false)

      if (data && data.result && data.result === 'success') {
        toast.success(data.message)
        reset()
      } else {
        toast.error('Erro')
      }
    } catch (err) {
      toggleLoading(false)

      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error('Erro ao cadastrar. Tente novamente mais tarde')
      }
    }
  }, [toast, reset, toggleLoading])

  const Privacy = () => {
    return (
      <Fragment>
        Li e concordo com a
        <a className='ml-25' href='https://www.repensaenergia.com/' target='_blank' rel='noreferrer noopener'>
          política de privacidade
        </a>
      </Fragment>
    )
  }

  return (
    <div className='auth-wrapper auth-v1 px-2'>
      <div className='auth-inner py-2'>
        <Card className='mb-0'>
          <CardBody>
            <a className='brand-logo' href='https://www.repensaenergia.com/' target='_blank'>
              <img className='img-fluid' style={{ width: '200px' }} src={logo} alt='illustration' />
            </a>
            <CardTitle tag='h4' className='mb-1'>
              Cadastro 🚀
            </CardTitle>
            <CardText className='mb-2'>Preencha as informações abaixo para criar sua conta. Seu acesso será liberado após aprovação da Repensa Energia.</CardText>
            <Form className='auth-register-form mt-2' onSubmit={e => e.preventDefault()}>
              <FormGroup>
                <Label className='form-label' for='nome'>
                  Nome
                </Label>
                <Input
                  type='text'
                  id='nome'
                  name='nome'
                  autoFocus
                  innerRef={register({
                    required: 'Preencha o nome'
                  })}
                />
              </FormGroup>
              {errors.nome && (
                <Alert className='mt-1' color='danger'>
                  <div className='alert-body'>
                    <span>{errors.nome.message}</span>
                  </div>
                </Alert>
              )}

              <FormGroup>
                <Label className='form-label' for='email'>
                  E-mail
                </Label>
                <Input
                  type='email'
                  id='email'
                  name='email'
                  innerRef={register({
                    required: 'Preencha o e-mail'
                  })}
                />
              </FormGroup>
              {errors.email && (
                <Alert className='mt-1' color='danger'>
                  <div className='alert-body'>
                    <span>{errors.email.message}</span>
                  </div>
                </Alert>
              )}

              <FormGroup>
                <Label className='form-label' for='senha'>
                  Senha
                </Label>
                <InputPasswordToggle
                  className='input-group-merge'
                  id='senha'
                  name='senha'
                  innerRef={register({
                    required: 'Preencha a senha'
                  })}
                />
              </FormGroup>
              {errors.senha && (
                <Alert className='mt-1' color='danger'>
                  <div className='alert-body'>
                    <span>{errors.senha.message}</span>
                  </div>
                </Alert>
              )}

              <FormGroup>
                <Label className='form-label' for='telefone'>
                  Telefone
                </Label>
                <Input
                  type='text'
                  id='telefone'
                  name='telefone'
                  innerRef={register({
                    required: 'Preencha o telefone'
                  })}
                />
              </FormGroup>
              {errors.telefone && (
                <Alert className='mt-1' color='danger'>
                  <div className='alert-body'>
                    <span>{errors.telefone.message}</span>
                  </div>
                </Alert>
              )}

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className='form-label' for='cep'>
                      CEP
                    </Label>
                    <Input
                      type='text'
                      id='cep'
                      name='cep'
                      placeholder=''
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='form-label' for='bairro'>
                      Bairro
                    </Label>
                    <Input
                      type='text'
                      id='bairro'
                      name='bairro'
                      placeholder=''
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <FormGroup>
                    <Label className='form-label' for='cidade'>
                      Cidade
                    </Label>
                    <Input
                      type='text'
                      id='cidade'
                      name='cidade'
                      placeholder=''
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label className='form-label' for='estado'>
                      Estado
                    </Label>
                    <Input
                      type='select'
                      id='estado'
                      name='estado'
                      innerRef={register}
                    >
                      {estados.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label className='form-label' for='indicacao'>
                  Foi indicado por alguém?
                </Label>
                <Input
                  type='text'
                  id='indicacao'
                  name='indicacao'
                  placeholder=''
                  innerRef={register}
                />
              </FormGroup>
              <FormGroup>
                <Label className='form-label' for='experiencia'>
                  Possui experiência com vendas?
                </Label>
                <Input
                  type='text'
                  id='experiencia'
                  name='experiencia'
                  placeholder=''
                  innerRef={register}
                />
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type='checkbox'
                  className='custom-control-Primary'
                  id='politica_privacidade'
                  name='politica_privacidade'
                  innerRef={register}
                  label={<Privacy />}
                />
              </FormGroup>
              <Button.Ripple color='primary' block onClick={handleSubmit(onSubmit)}>
                Cadastrar
              </Button.Ripple>
            </Form>
            <p className='text-center mt-2'>
              <span className='mr-25'>Já tem uma conta?</span>
              <Link to='/'>
                <span>Faça login</span>
              </Link>
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Register
