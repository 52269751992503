import { PlusSquare, List } from 'react-feather'

export default [
  {
    id: 'proposta',
    title: 'Nova Proposta',
    icon: <PlusSquare size={20} />,
    navLink: '/proposta'
  },
  {
    id: 'propostas',
    title: 'Propostas',
    icon: <List size={20} />,
    navLink: '/propostas'
  }
]
