import { Link } from 'react-router-dom'
import { ChevronLeft } from 'react-feather'
import { Card, CardBody, CardTitle, CardText, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap'
import '@styles/base/pages/page-auth.scss'
import logo from '@src/assets/images/logo/repensa.png'
import { useForm } from 'react-hook-form';
import { useCallback } from 'react'
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../context/Auth'

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const ForgotPassword = () => {
  const { register, errors, handleSubmit, reset } = useForm();
  const { toggleLoading } = useAuth();

  const submitForm = useCallback(async (data) => {
    toggleLoading(true);

    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('email', data.email);

    try {
      const response = await api.post('/forgot_password', formData);
      const { data } = response;
      toggleLoading(false);

      if (data && data.result === 'success' && data.message) {
        toast.success(data.message);
        reset();
      }
    } catch (err) {
      toggleLoading(false);
      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error('Erro ao recuperar senha')
      }
    }
  }, [])

  return (
    <div className='auth-wrapper auth-v1 px-2'>
      <div className='auth-inner py-2'>
        <Card className='mb-0'>
          <CardBody>
            <a className='brand-logo' href='https://www.repensaenergia.com/' target='_blank'>
              <img className='img-fluid' style={{width: '200px'}} src={logo} alt='illustration' />
            </a>
            <CardTitle tag='h4' className='mb-1'>
              Esqueceu a senha? 🔒
            </CardTitle>
            <CardText className='mb-2'>
              Coloque o seu email e nós enviaremos instruções de como recuperar sua senha
            </CardText>
            <Form className='auth-forgot-password-form mt-2' onSubmit={e => e.preventDefault()}>
              <FormGroup>
                <Label className='form-label' for='login-email'>
                  E-mail
                </Label>
                <Input
                  type='email'
                  id='email'
                  name='email'
                  placeholder='voce@seuemail.com.br'
                  autoFocus
                  innerRef={register({
                    required: 'Preencha o email'
                  })}
                />
                {errors.email && (
                  <Alert className='mt-1' color='danger'>
                    <div className='alert-body'>
                      <span>{errors.email.message}</span>
                    </div>
                  </Alert>
                )}
              </FormGroup>
              <Button.Ripple
                color='primary'
                block
                onClick={handleSubmit(submitForm)}
              >
                Recuperar senha
              </Button.Ripple>
            </Form>
            <p className='text-center mt-2'>
              <Link to='/'>
                <ChevronLeft className='mr-25' size={14} />
                <span className='align-middle'>Retornar ao login</span>
              </Link>
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ForgotPassword
