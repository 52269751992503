/* PropostaEdicao.js */

import { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../../../context/Auth';
import api from '../../../services/api';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

const PropostaEdicao = () => {
  const { id } = useParams();
  const { toggleLoading } = useAuth();
  const history = useHistory();

  const [proposta, setProposta] = useState(null);
  const [maxVersao, setMaxVersao] = useState(0);
  // const [currentProposta, setCurrentProposta] = useState(null);
  // const [versao, setVersao] = useState(0);
  // const [versoes, setVersoes] = useState([]);

  const getData = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('id', id);

    toggleLoading(true);
    try {
      const response = await api.post('/get_proposta', formData);
      const { data } = response;
      
      if (data && data.data) {
        if (data.data.versoes) {
          let maxV = 0;
          data.data.versoes.map((v) => {
            if (v.versao) {
              maxV = v.versao;
            }
          });
          maxV = parseInt(maxV);
          setMaxVersao(maxV);
        }
        setProposta(data.data);
        // console.log(data.data);
        // if (data.data?.versao?.versao) {
        //   const versaoNumber = data.data.versao.versao;
        //   setVersao(versaoNumber);

        //   const versoesOptions = [];
        //   for (let i = 1; i <= versaoNumber; i++) {
        //     versoesOptions.push(i);
        //   }
        //   setVersoes(versoesOptions);
        // }
      }
    } catch (err) {
      let errorMessage = 'Erro ao recuperar proposta';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
    toggleLoading(false);
  }, [id, toggleLoading, setProposta, setMaxVersao]);

  useEffect(() => {
    getData();
  }, [getData]);

  // const updateVersaoNumber = useCallback((n) => {
  //   if (n) {
  //     setVersao(n);
  //   }
  // }, [setVersao]);

  const formatCurrency = useCallback((value) => {
    if (value === '') {
      value = 0
    }
    value = parseFloat(value)
    return currencyFormatter.format(value)
  }, []);

  const formatDate = useCallback((date) => {
    let formatted = '';
    if (date) {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
      const hour = date.substring(11, 13);
      const minute = date.substring(14, 16);
      formatted = `${day}/${month}/${year} ${hour}:${minute}`;
    }
    return formatted;
  }, []);

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Editar Proposta [Versões disponíveis]</h1>
        </Col>
      </Row>

      {proposta && (
        <>
          <Row className='mt-2'>
            <Col>
              <Card>
                <CardBody>
                <h4>ID: {proposta.id}</h4>
                <h2>Cliente: {proposta.cliente}</h2>
                
                <h5>Criação da proposta: {formatDate(proposta.date_created)}</h5>
                <h5>Orçamento: {formatCurrency(proposta.valor)}</h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {proposta.versoes && proposta.versoes.map((v) => <Col key={v.id} md={4}>
              <Card>
                <CardHeader>
                  <CardTitle>Versão [{v.versao}]</CardTitle>
                </CardHeader>
                <CardBody>
                  <h5>Criação: {formatDate(v.date_created)}</h5>
                  <h5>Modificação: {formatDate(v.date_modified)}</h5>
                </CardBody>
                <CardFooter>
                  <Button className='mr-2' onClick={() => history.push(`/edicao-proposta-form/${v.id}/0`)}>Editar</Button>
                  <Button onClick={() => history.push(`/edicao-proposta-form/${v.id}/${maxVersao + 1}`)}>Duplicar</Button>
                </CardFooter>
              </Card>
            </Col>)}
          </Row>
        </>
      )}
    </Fragment>
  )
}

export default PropostaEdicao;
