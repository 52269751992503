import { Fragment } from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

// TODO

const Faq = () => {
  return (
    <Fragment>
      <Row>
        <Col>
          <h1>FAQ</h1>
        </Col>
      </Row>

      <Card>
        <CardHeader>
          <CardTitle className='collapse-title'>Sed a neque iaculis?</CardTitle>
        </CardHeader>
        <CardBody>
          Aliquam at orci non neque semper vehicula. Donec pretium urna libero, nec facilisis ligula venenatis blandit. Duis nunc magna, convallis in elementum vel, efficitur vel tellus. Praesent vehicula fringilla lorem. Morbi vel molestie tortor, id facilisis mauris. Ut luctus ultricies velit, a vehicula metus congue non. Pellentesque laoreet facilisis libero in mollis. Vestibulum venenatis, tortor non ullamcorper iaculis, odio mauris dictum orci, sit amet venenatis leo nunc ac magna. Fusce faucibus sagittis lorem vel convallis. Integer sed eros id tellus imperdiet venenatis.
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className='collapse-title'>Sed a neque iaculis?</CardTitle>
        </CardHeader>
        <CardBody>
          Aliquam at orci non neque semper vehicula. Donec pretium urna libero, nec facilisis ligula venenatis blandit. Duis nunc magna, convallis in elementum vel, efficitur vel tellus. Praesent vehicula fringilla lorem. Morbi vel molestie tortor, id facilisis mauris. Ut luctus ultricies velit, a vehicula metus congue non. Pellentesque laoreet facilisis libero in mollis. Vestibulum venenatis, tortor non ullamcorper iaculis, odio mauris dictum orci, sit amet venenatis leo nunc ac magna. Fusce faucibus sagittis lorem vel convallis. Integer sed eros id tellus imperdiet venenatis.
        </CardBody>
      </Card>

    </Fragment>
  )
}

export default Faq;
