import { useCallback } from 'react';
import { Row, Col, Card, Button, Input, Label, Alert, FormGroup } from 'reactstrap';
import api from '../../../../services/api';
import { useAuth } from '../../../../context/Auth';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const SenhaUsuario = ({user_id}) => {
  const { toggleLoading } = useAuth();
  const { register, handleSubmit, errors, setValue } = useForm();

  const onSubmit = useCallback(async (data) => {
    if (user_id) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('user_id', user_id);
      formData.append('password', data.password);

      toggleLoading(true);

      try {
        const response = await api.post('/update_user_password_admin', formData);
        const { data } = response;
        if (data && data.result && data.result === 'success' && data.message) {
          toast.success(data.message);
          setValue('password', '');
        }
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }

      toggleLoading(false);
    }
  }, [user_id, toggleLoading]);

  return (
    <Card className='p-3'>
      <h3>Senha</h3>

      <Row className='mt-2'>
        <Col md={12}>
          <FormGroup>
            <Label>Definir nova senha para usuário</Label>
            <Input
              type='password'
              name='password'
              innerRef={register({
                required: 'Preencha a senha'
              })}
            />
          </FormGroup>
          {errors.password && (
            <Alert  color='danger'>
              <div className='alert-body'>
                <span>{errors.password.message}</span>
              </div>
            </Alert>
          )}
          <Button
            color='primary'
            onClick={handleSubmit(onSubmit)}
          >
            Definir senha
          </Button>
        </Col>

      </Row>
    </Card>
  )
}

export default SenhaUsuario;
