import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from '../context/Auth'
import VerticalLayout from '@src/layouts/VerticalLayout'
import LayoutWrapper from '@layouts/components/layout-wrapper'
import { useRouterTransition } from '@hooks/useRouterTransition'

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isPublic,
  isLogin,
  isAdmin,
  ...rest
}) {
  const { authenticated } = useAuth()
  const [transition] = useRouterTransition()

  let signed = null
  if (authenticated) {
    signed = true
  } else {
    signed = false
  }

  if (!signed && isPrivate) {
    return <Redirect to='/' />
  }
  if (signed && isLogin) {
    return <Redirect to='/dashboard' />
  }
  if (isAdmin) {
    const level = parseInt(authenticated.level);
    if (level !== 1) {
      return <Redirect to='/dashboard' />
    }
  }

  if (isLogin || isPublic) {
    return (
      <Route {...rest}>
        <Component />
      </Route>
    )
  } else {
    return (
      <Route {...rest}>
        <VerticalLayout>
          <LayoutWrapper
            appLayout={false}
            transition={transition}
          >
            <Component />
          </LayoutWrapper>
        </VerticalLayout>
      </Route>
    )
  }
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
}

RouteWrapper.defaultProps = {
  isPrivate: false
}