import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trash as IconDelete } from 'react-feather';
import { Row, Col, Card, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input } from 'reactstrap';
import api from '../../../services/api';
import { useAuth } from '../../../context/Auth';
import { toast } from 'react-toastify';
import DataTableRepensa from '../../../@core/components/datatable/DataTableRepensa';
import DateHelper from '../../../helpers/DateHelper';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const Propostas = () => {
  const history = useHistory();
  const { toggleLoading } = useAuth();
  const procurarRef = useRef(null);

  const [data, setData] = useState();
  const [currentProposta, setCurrentProposta] = useState();
  
  const [modalRemove, setModalRemove] = useState(false);
  const toggleModalRemove = useCallback(() => {
    setModalRemove(value => !value);
  }, [setModalRemove]);

  const getPropostas = useCallback(async (termo = null) => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    if (termo) { formData.append('cliente', termo); }

    toggleLoading(true);
    try {
      const response = await api.post('/get_propostas', formData);
      const { data } = response;
      
      if (data && data.data && data.data.length > 0) {
        setData(data.data);
      } else {
        setData([]);
      }
    } catch (err) {
      let errorMessage = 'Erro ao realizar operação';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
    toggleLoading(false);
  }, [setData, toggleLoading]);

  const removerProposta = useCallback(async () => {
    if (currentProposta && currentProposta.id) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('proposta_id', currentProposta.id);

      toggleLoading(true);
      try {
        await api.post('/remover_proposta', formData);
        toast.success('Proposta removida');
        toggleModalRemove();
        getPropostas();
      } catch (err) {
        let errorMessage = 'Erro ao realizar operação';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }
      toggleLoading(false);
    }
  }, [currentProposta, toggleLoading, getPropostas]);

  const procurarProposta = useCallback(async () => {
    const termo = procurarRef.current.value;
    getPropostas(termo);
  }, [procurarRef, getPropostas]);

  useEffect(() => {
    getPropostas();
  }, [getPropostas]);

  const columns = [
    { name: 'ID', selector: 'id', },
    { name: 'Cliente', selector: 'cliente', },
    {
      name: 'Data',
      cell: (row) => (
        <Fragment>{DateHelper.convertServerDate(row.date_created)}</Fragment>
      ),
    },
    {
      name: 'Ações',
      selector: 'actions',
      cell: (row) => (
        <Fragment>
          <Button
            color='primary'
            className='mr-1'
            onClick={() => history.push(`/edicao-proposta/${row.id}`)}
          >
            Abrir
          </Button>
          <Button
            color='danger'
            className='mr-1'
            onClick={() => {
              setCurrentProposta(row);
              toggleModalRemove();
            }}
          >
            <IconDelete size={18} />
          </Button>
        </Fragment>
      )
    }
  ];

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <h1>Propostas</h1>
        </Col>
        <Col md={6} className='text-right'>
          <Button
            color='primary'
            className='mr-1'
            onClick={() => history.push(`/proposta`)}
          >
            Nova proposta
          </Button>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col md={12}>
          <Card>
            <Row>
              <Col className='d-flex justify-content-end py-1 pr-2'>
                <div className='d-flex align-items-center mb-sm-0 mb-1 mr-1'>
                  <Input
                    placeholder='Procurar proposta...'
                    id='search-invoice'
                    className='ml-50 w-100'
                    type='text'
                    innerRef={procurarRef}
                  />
                </div>
                <Button.Ripple color='primary' onClick={procurarProposta}>
                  Procurar
                </Button.Ripple>
              </Col>
            </Row>

            <DataTableRepensa columns={columns} data={data} />
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalRemove} toggle={toggleModalRemove}>
        <ModalHeader toggle={toggleModalRemove}>
          Excluir proposta
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>Confirmar exclusão da proposta:</p>
              <p>ID: {currentProposta && currentProposta.id}</p>
              <p>Cliente: {currentProposta && currentProposta.cliente}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='text-right'>
              <Button color='secondary' onClick={toggleModalRemove}>Cancelar</Button>
              <Button color='danger' className='ml-1' onClick={removerProposta}>Remover</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default Propostas
