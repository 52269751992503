import { useCallback, useEffect } from 'react'
import { Row, Col, Card, Button, Input, Label, FormGroup, CardBody, CardHeader } from 'reactstrap'
import api from '../../../../services/api'
import { useAuth } from '../../../../context/Auth'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY

const InformacoesPessoais = () => {
  const { toggleLoading, authenticated } = useAuth();
  const { register, handleSubmit, setValue } = useForm();

  const saveUserData = useCallback(async (data) => {
    const { user_id } = authenticated;

    if (user_id) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('user_id', user_id);

      if (data.name) formData.append('name', data.name);
      if (data.telefone) formData.append('telefone', data.telefone);

      toggleLoading(true);

      try {
        const response = await api.post('/update_user_info', formData);
        const { data } = response;
        if (data && data.result && data.result === 'success' && data.message) {
          toast.success(data.message);
        }
      } catch (err) {
        toast.error('Erro ao atualizar informações');
      }

      toggleLoading(false);
    }
  }, [authenticated, toggleLoading]);

  const getUserData = useCallback(async () => {
    const { user_id } = authenticated;
    if (user_id) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('user_id', user_id);

      toggleLoading(true);

      try {
        const response = await api.post('/get_user_info', formData);
        const { data } = response;
  
        if (data && data.data) {
          const userData = data.data;

          if (userData.name) setValue('name', userData.name);
          if (userData.email) setValue('email', userData.email);
          if (userData.telefone) setValue('telefone', userData.telefone);
        }
      } catch (err) {
        toast.error('Erro ao recuperar configurações. Atualize a página!');
      }

      toggleLoading(false);
    }
  }, [authenticated, toggleLoading, setValue]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <Card>
      <CardHeader>
        <h3>Informações pessoais</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>Nome</Label>
              <Input
                name='name'
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>E-mail</Label>
              <Input
                name='email'
                innerRef={register}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Telefone</Label>
              <Input
                name='telefone'
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Button
              color='primary'
              onClick={handleSubmit(saveUserData)}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </CardBody>
      
    </Card>
  )
}

export default InformacoesPessoais
