import { Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import AlterarSenha from './components/AlterarSenha'
import InformacoesPessoais from './components/InformacoesPessoais'

const Perfil = () => {
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <h1>Perfil</h1>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col>
          <InformacoesPessoais />
        </Col>
      </Row>

      <Row className="mt-1">
        <Col>
          <AlterarSenha />
        </Col>
      </Row>
    </Fragment>
  )
}

export default Perfil
