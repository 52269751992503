import ReactDOM from 'react-dom';

import './@core/components/ripple-button';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@styles/react/libs/toastify/toastify.scss';
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// TODO: remove
// ** Fake Database
import './@fake-db';

import App from './App';

// ** Service Worker
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

serviceWorker.unregister()