import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Button, Input, Badge } from 'reactstrap';
import api from '../../../services/api';
import { useAuth } from '../../../context/Auth';
import { toast } from 'react-toastify';
import RepensaStatuses from '../../../enums/RepensaStatuses';
import SenhaUsuario from './components/SenhaUsuario';
import CodigoUsuario from './components/CodigoUsuario';
import RepensaFunctions from '../../../enums/RepensaFunctions';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const EditUser = () => {
  const { id } = useParams();
  const { toggleLoading } = useAuth();

  const [userData, setUserData] = useState(false);

  const [editLevel, setEditLevel] = useState();

  const getData = useCallback(async () => {
    toggleLoading(true)
    try {
      const formData = new FormData()
      formData.append('access_key', ACCESS_KEY)
      formData.append('user_id', id)

      const response = await api.post('/get_user_info', formData)
      const { data } = response
      if (data && data.data) {
        setUserData(data.data)
      }
    } catch (err) {
      toast.error('Erro ao recuperar usuário')
    }
    toggleLoading(false)
  }, [setUserData, toggleLoading, id]);

  const updateUserStatus = useCallback(async (status) => {
    toggleLoading(true)
    try {
      const formData = new FormData()
      formData.append('access_key', ACCESS_KEY)
      formData.append('user_id', id)
      formData.append('status', status)

      const response = await api.post('/update_user_status', formData)
      const { data } = response
      if (data && data.message) {
        toast.success(data.message)
        getData();
      }
    } catch (err) {
      let errorMessage = 'Erro ao atualizar informações';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
    toggleLoading(false)
  }, [setUserData, toggleLoading, id, getData]);

  const updateUserLevel = useCallback(async () => {
    if (editLevel) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('user_id', id);
      formData.append('level', editLevel);

      toggleLoading(true);
      try {
        const response = await api.post('/update_user_level', formData);
        const { data } = response;
        if (data && data.message) {
          toast.success(data.message);
          getData();
        }
      } catch (err) {
        let errorMessage = 'Erro ao atualizar informações';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }
      toggleLoading(false);
    }
  }, [editLevel, setUserData, toggleLoading, id, getData]);

  useEffect(async () => {
    getData()
  }, [getData])

  const getBadgeLevel =  useCallback((user) => {
    const level  = parseInt(user.level);
    let color;

    if (level === 1) color = 'dark';
    else if (level === 2) color = 'primary';
    else if (level === 3) color = 'info';

    return <Badge color={color}>{RepensaFunctions[level]}</Badge>;
  }, []);

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Editar usuário</h1>
        </Col>
      </Row>

      <Row className="mt-2">

        <Col md={12}>
          <Card className='p-3'>
            <h3>Informações</h3>

            <Row className='mt-2'>
              <Col md={6}>
                <span className='card-text'>Nome</span>
                <h4>{userData && userData.name && userData.name}</h4>
              </Col>
              <Col md={6}>
                <span className='card-text'>E-mail</span>
                <h4>{userData && userData.email && userData.email}</h4>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md={6}>
                <span className='card-text'>Status</span>
                <h4>{userData && userData.status && RepensaStatuses[parseInt(userData.status)]}</h4>
              </Col>
              <Col md={6}>
                <span className='card-text'>Código do Usuário</span>
                <h4>{userData && userData.codigo && userData.codigo}</h4>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col md={6} className='mt-2'>
          <Card className='p-3'>
            <h3>Nível</h3>

            <Row>
              <Col>
                {userData && userData.level && getBadgeLevel(userData)}
              </Col>
            </Row>

            <Row className='mt-2'>
              <Col md={12}>
                <Input
                  type='select'
                  name='level'
                  id='level'
                  onChange={(evt) => { setEditLevel(evt.target.value) }}
                >
                  <option value=''>Selecione</option>
                  <option value='3'>Vendedor</option>
                  <option value='2'>Orçamentista / Interno</option>
                  <option value='1'>Admin</option>
                </Input>
                <Button
                  color='primary'
                  className='mt-1'
                  onClick={updateUserLevel}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col md={6} className='mt-2'>
          <Card className='p-3'>
            <h3>Status</h3>
            <h4>{userData && userData.status && RepensaStatuses[parseInt(userData.status)]}</h4>

            <Row className='mt-2'>
              <Col md={12}>
                {userData && parseInt(userData.status) !== 1 && (
                  <Button
                    color='success'
                    className='mr-1'
                    onClick={() => updateUserStatus(1)}
                  >
                    Ativar
                  </Button>
                )}
                {userData && parseInt(userData.status) !== 3 && (
                  <Button
                    color='secondary'
                    className='mr-1'
                    onClick={() => updateUserStatus(3)}
                  >
                    Bloquear
                  </Button>
                )}
                <Button
                  color='danger'
                  className='mr-1'
                  onClick={() => updateUserStatus(4)}
                >
                  Remover
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>

      </Row>

      <Row className='mt-2'>
        <Col md={6}>
          <SenhaUsuario
            user_id={id}
          />
        </Col>
        <Col md={6}>
          <CodigoUsuario
            user_id={id}
            codigo={userData.codigo}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default EditUser;
