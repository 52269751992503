import { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const fields = ['limitador_kwp', 'minimo_kwp', 'preco_30', 'preco_110'];

const ConfigParametros = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async (data) => {
    const parametros = {};
    fields.forEach((val) => {
      if (data.hasOwnProperty(val) && data[val] && data[val] !== null) {
        parametros[val] = data[val];
      }
    });

    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('parametros', JSON.stringify(parametros));

    setIsLoading(true);
    try {
      await api.post('/set_parametros_proposta', formData);
      toast.success('Configurações salvas');
    } catch (err) {
      let errorMessage = 'Erro ao salvar configurações';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
    setIsLoading(false);
  }, [setIsLoading]);

  const getData = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);

    setIsLoading(true);
    try {
      const response = await api.post('/get_parametros_proposta', formData);
      const { data } = response;
      
      if (data && data.data) {
        let parametros = data.data;
        if (parametros) {
          parametros = JSON.parse(parametros);
  
          fields.forEach((val) => {
            if (parametros.hasOwnProperty(val) && parametros[val] && parametros[val] !== null) {
              setValue(val, parametros[val]);
            }
          });
        }
      }
    } catch (err) {
      toast.error('Erro ao recuperar configurações');
    }
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={12}>
            <Label>
              Limitador para proposta de representante externo
            </Label>
            <InputGroup className='mb-2'>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText>kWp</InputGroupText>
              </InputGroupAddon>
              <Input
                id='limitador_kwp'
                name='limitador_kwp'
                innerRef={register}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>
              Mínimo para proposta de representante externo
            </Label>
            <InputGroup className='mb-2'>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText>kWp</InputGroupText>
              </InputGroupAddon>
              <Input
                id='minimo_kwp'
                name='minimo_kwp'
                innerRef={register}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>
              Preço por kWp de equipamento até
            </Label>
            <InputGroup className='mb-2'>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText>30 kWp</InputGroupText>
              </InputGroupAddon>
              <Input
                id='preco_30'
                name='preco_30'
                innerRef={register}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>
              Preço por kWp de equipamento até
            </Label>
            <InputGroup className='mb-2'>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText>110 kWp</InputGroupText>
              </InputGroupAddon>
              <Input
                id='preco_110'
                name='preco_110'
                innerRef={register}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Button
              color='primary'
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Col>
          {isLoading && <Col md={4} className='text-right'><Spinner /></Col>}
        </Row>
      </CardBody>
    </Card>
  )
}

export default ConfigParametros;
