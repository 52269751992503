import { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const ConfigTotal = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async (data) => {
    if (data.total_kwp) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('config', 'total_kwp');
      formData.append('parametros', data.total_kwp);

      setIsLoading(true);
      try {
        await api.post('/set_config', formData);
        toast.success('Configurações salvas');
      } catch (err) {
        let errorMessage = 'Erro ao salvar configurações';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }
  }, [setIsLoading]);

  const getData = useCallback(async () => {
    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('config', 'total_kwp');

    setIsLoading(true);
    try {
      const response = await api.post('/get_config', formData);
      const { data } = response;
      
      if (data && data.data) setValue('total_kwp', data.data);
    } catch (err) {
      let errorMessage = 'Erro ao recuperar configurações';
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    }
    setIsLoading(false);
  }, [setValue, setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={12}>
            <Label>
              MWp total vendido pela Repensa
            </Label>
            <InputGroup className='mb-2'>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText>MWp</InputGroupText>
              </InputGroupAddon>
              <Input
                id='total_kwp'
                name='total_kwp'
                innerRef={register}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Button
              color='primary'
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Col>
          {isLoading && <Col md={4} className='text-right'><Spinner /></Col>}
        </Row>
      </CardBody>
    </Card>
  )
}

export default ConfigTotal;
