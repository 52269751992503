import { Fragment, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, Badge, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import api from '../../../services/api';
import { useAuth } from '../../../context/Auth';
import { toast } from 'react-toastify';
import RepensaFunctions from '../../../enums/RepensaFunctions';
import RepensaStatuses from '../../../enums/RepensaStatuses';
import DataTableRepensa from '../../../@core/components/datatable/DataTableRepensa';
import { Edit as IconEdit, Trash as IconDelete } from 'react-feather';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const UsersPage = () => {
  const history = useHistory();
  const { toggleLoading } = useAuth();

  const [data, setData] = useState();

  const [modalRemove, setModalRemove] = useState(false);
  const toggleModalRemove = useCallback(() => {
    setModalRemove(value => !value);
  }, [setModalRemove]);

  const [currentUser, setCurrentUser] = useState();

  const getData = useCallback(async () => {
    toggleLoading(true);
    try {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);

      const response = await api.post('/list_users', formData);
      const { data } = response;
      if (data && data.data) {
        setData(data.data);
      }
    } catch (err) {
      toast.error('Erro ao recuperar usuários');
    }
    toggleLoading(false);
  }, [api, setData, toggleLoading]);

  useEffect(async () => {
    getData();
  }, [getData]);

  const columns = [
    { selector: 'id', name: 'ID' },
    { selector: 'name', name: 'Nome' },
    { selector: 'email', name: 'Email' },
    {
      selector: 'level',
      name: 'Função',
      cell: (row) => {
        const localLevel = parseInt(row.level)
        let color

        if (localLevel === 1) color = 'dark'
        else if (localLevel === 2) color = 'primary'
        else if (localLevel === 3) color = 'info'

        return <Badge color={color}>{RepensaFunctions[localLevel]}</Badge>
      }
    },
    {
      selector: 'status',
      name: 'Status',
      cell: (row) => {
        const localStatus = parseInt(row.status)
        let color
        
        if (localStatus === 1) color = 'success'
        else if (localStatus === 2) color = 'warning'
        else if (localStatus === 3) color = 'danger'

        return <Badge color={color}>{RepensaStatuses[localStatus]}</Badge>
      }
    },
    {
      selector: 'actions',
      name: 'Ações',
      cell: (row) => (
        <Fragment>
          <Button
            color='primary'
            className='mr-1'
            onClick={() => history.push(`/usuario/${row.id}`)}
          >
            <IconEdit size={18} />
          </Button>
          <Button
            color='danger'
            className='mr-1'
            onClick={() => {
              setCurrentUser(row);
              toggleModalRemove();
            }}
          >
            <IconDelete size={18} />
          </Button>
        </Fragment>
      )
    }
  ];

  const removerUsuario = useCallback(async () => {
    if (currentUser && currentUser.id) {
      const formData = new FormData();
      formData.append('access_key', ACCESS_KEY);
      formData.append('user_id', currentUser.id);
      formData.append('status', 4);

      toggleLoading(true);
      try {
        const response = await api.post('/update_user_status', formData);
        const { data } = response;
        if (data && data.message) {
          toggleModalRemove();
          toast.success(data.message);
          getData();
        }
      } catch (err) {
        let errorMessage = 'Erro ao atualizar informações';
        if (err && err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toast.error(errorMessage);
      }
      toggleLoading(false);
    }
  }, [currentUser, toggleLoading, getData, toggleModalRemove]);

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <h1>Usuários</h1>
        </Col>
        <Col md={6} className='text-right'>
          <Button
            color='primary'
            className='mr-1'
            onClick={() => history.push(`/criar-usuario`)}
          >
            Criar usuário
          </Button>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col md={12}>
          <Card>
            <DataTableRepensa columns={columns} data={data} />
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalRemove} toggle={toggleModalRemove}>
        <ModalHeader toggle={toggleModalRemove}>
          Remover usuário
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>Confirmar remoção do usuário:</p>
              <p>ID: {currentUser && currentUser.id}</p>
              <p>Nome: {currentUser && currentUser.name}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='text-right'>
              <Button color='secondary' onClick={toggleModalRemove}>Cancelar</Button>
              <Button color='danger' className='ml-1' onClick={removerUsuario}>Remover</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default UsersPage;
