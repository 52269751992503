/* Gerador.js */

import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Chart } from 'chart.js';

const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
const numberFormatter = new Intl.NumberFormat('pt-BR');

const vendedorDemo = {
  nome: 'Igor Gomides',
  celular: '(11) 9 9999 9999',
  fixo: '(11) 0000 0000'
}

const propostaDemo = {
  nome_do_cliente: "Angelo Silva",
  cpf_cliente: "45279733806",
  email_cliente: "angelo.silva079@gmail.com",
  telefone_cliente: "11998435989",
  tipo_projeto: "residencial",
  cep_cliente: "12942570",
  logradouro_cliente: "Rua Oswaldo Barreto, 584",
  bairro_cliente: "Alvinópolis",
  municipio_cliente: "Atibaia",
  estado_cliente: "SP",
  concessionaria: "ELEKTRO",
  classificacao: "baixa_tensao",
  custo_de_disponibilidade: "100",
  prospeccao: "sim",
  faturamento: "direto",
  demanda_contratada: '',
  n_unidade_consumidora: '',
  tipo_de_instalacao: "telhado_de_barro",

  opcional_cerca: 'nao',
  opcional_brita: 'nao',
  opcional_garantia_estendida: 'nao',
  opcional_estacao_solarimetrica: 'nao',
  opcional_abrigo: 'nao',
  
  unidade_consumidora: {
      1: {
          janeiro: "4500",
          fevereiro: "4500",
          marco: "4500",
          abril: "4500",
          maio: "4500",
          junho: "4500",
          julho: "4500",
          agosto: "4500",
          setembro: "4500",
          outubro: "4500",
          novembro: "4500",
          dezembro: "4500",
          total: "54000"
      },
      2: {
          janeiro: "",
          fevereiro: "",
          marco: "",
          abril: "",
          maio: "",
          junho: "",
          julho: "",
          agosto: "",
          setembro: "",
          outubro: "",
          novembro: "",
          dezembro: "",
          total: "0"
      },
      3: {
          janeiro: "",
          fevereiro: "",
          marco: "",
          abril: "",
          maio: "",
          junho: "",
          julho: "",
          agosto: "",
          setembro: "",
          outubro: "",
          novembro: "",
          dezembro: "",
          total: "0"
      },
      4: {
          janeiro: "",
          fevereiro: "",
          marco: "",
          abril: "",
          maio: "",
          junho: "",
          julho: "",
          agosto: "",
          setembro: "",
          outubro: "",
          novembro: "",
          dezembro: "",
          total: "0"
      },
      5: {
          janeiro: "",
          fevereiro: "",
          marco: "",
          abril: "",
          maio: "",
          junho: "",
          julho: "",
          agosto: "",
          setembro: "",
          outubro: "",
          novembro: "",
          dezembro: "",
          total: "0"
      }
  },
  geracao: {
      janeiro: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 5808,
          autonomia: 1.32,
          excedente: 1308
      },
      fevereiro: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 4839.999999999999,
          autonomia: 1.0999999999999999,
          excedente: 339.9999999999991
      },
      marco: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 4400,
          autonomia: 1,
          excedente: -100
      },
      abril: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 4473.333333333332,
          autonomia: 1.0166666666666664,
          excedente: -26.66666666666788
      },
      maio: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 3666.6666666666665,
          autonomia: 0.8333333333333333,
          excedente: -833.3333333333335
      },
      junho: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 2918.666666666667,
          autonomia: 0.6633333333333334,
          excedente: -1581.333333333333
      },
      julho: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 3080,
          autonomia: 0.7,
          excedente: -1420
      },
      agosto: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 3769.333333333332,
          autonomia: 0.8566666666666664,
          excedente: -730.6666666666679
      },
      setembro: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 4224,
          autonomia: 0.96,
          excedente: -276
      },
      outubro: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 5456,
          autonomia: 1.24,
          excedente: 956
      },
      novembro: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 5103.999999999999,
          autonomia: 1.1599999999999997,
          excedente: 603.9999999999991
      },
      dezembro: {
          consumo_atual: "4500",
          consumo_a_compensar: "4400",
          geracao: 5030.666666666666,
          autonomia: 1.143333333333333,
          excedente: 530.6666666666661
      },
      total: {
          consumo_a_compensar: 52800,
          geracao: 52770.666666666664,
          autonomia: "0",
          excedente: "0"
      }
  },
  consumo_medio_mensal: 4500,
  fator_irradiacao: "5.3",
  irradiacao: {
      detalhado: {
          cidades: {
              1: {
                  nome: "Jundiaí",
                  meses: {
                      1: 6.852,
                      2: 6.043,
                      3: 5.293,
                      4: 5.257,
                      5: 4.447,
                      6: 3.555,
                      7: 3.485,
                      8: 4.291,
                      9: 4.914,
                      10: 6.665,
                      11: 6.161,
                      12: 5.825,
                      media: 5.23
                  },
                  desvio_media: {
                      1: 1.31,
                      2: 1.15,
                      3: 1.01,
                      4: 1,
                      5: 0.85,
                      6: 0.68,
                      7: 0.67,
                      8: 0.82,
                      9: 0.94,
                      10: 1.27,
                      11: 1.18,
                      12: 1.11
                  }
              },
              2: {
                  nome: "São Paulo",
                  meses: {
                      1: 6.658,
                      2: 5.38,
                      3: 4.796,
                      4: 5.053,
                      5: 4.056,
                      6: 3.317,
                      7: 3.583,
                      8: 3.999,
                      9: 4.388,
                      10: 5.875,
                      11: 5.428,
                      12: 5.825,
                      media: 4.86
                  },
                  desvio_media: {
                      1: 1.27,
                      2: 1.11,
                      3: 0.99,
                      4: 1.04,
                      5: 0.83,
                      6: 0.68,
                      7: 0.74,
                      8: 0.82,
                      9: 0.9,
                      10: 1.21,
                      11: 1.12,
                      12: 1.2
                  }
              },
              3: {
                  nome: "Riberão Preto",
                  meses: {
                      1: 7.207,
                      2: 5.732,
                      3: 5.506,
                      4: 5.562,
                      5: 4.541,
                      6: 3.482,
                      7: 3.84,
                      8: 5.121,
                      9: 5.765,
                      10: 6.848,
                      11: 6.523,
                      12: 6.215,
                      media: 5.53
                  },
                  desvio_media: {
                      1: 1.38,
                      2: 1.04,
                      3: 1,
                      4: 1.01,
                      5: 0.82,
                      6: 0.63,
                      7: 0.69,
                      8: 0.93,
                      9: 1.04,
                      10: 1.24,
                      11: 1.18,
                      12: 1.12
                  }
              }
          }
      },
      simples: {
          1: 6.996,
          2: 5.829999999999999,
          3: 5.3,
          4: 5.388333333333333,
          5: 4.416666666666667,
          6: 3.515666666666667,
          7: 3.7100000000000004,
          8: 4.540333333333333,
          9: 5.088,
          10: 6.572,
          11: 6.148,
          12: 6.059666666666667,
          media: 5.3
      },
      potencial_medio_diario: 5.3,
      pontecial_medio_mensal: 159,
      potencial_medio_anual: 1908,
      geracao_media_anual_swera: 1431
  },
  taxas_fixas: {
      pis: 0.0165,
      cofins: 0.076,
      multiplicador: 0.27,
      custo_administrativo: 0.05,
      comissao_vendedor_interno: 0.01,
      comissao_representante: 0.05,
      preco_kwp_equipamentos: 3087.55,
      materiais_eletricos: 0.14,
      andaime: 0.02,
      reserva_servicos: 0.02,
      iss: 0.14
  },
  potencia_sistema_sugerida: 36.897274633123686,
  potencia_instalada: 36.897274633123686,
  autonomia_desejada: "100",
  potencia_do_sistema: 36.897274633123686,
  autonomia_atendida: 100,
  consumo_medio_antes_instalacao_sistema: 0,
  estimativa_geracao_media_mensal: 4397.555555555556,
  estimativa_geracao_media_anual: 52770.666666666664,
  custo_medio_aproximado_nova_fatura_energia: 71.71111111111104,
  economia_media_mensal_estimada: 3078.288888888889,
  economia_media_anual_estimada: 36939.46666666666,
  area_aproximada_instalacao: 239.83228511530396,
  tarifa_media_energia: 0.7,
  elaboracao_dimensionamento_projeto_total: 1500,
  visita_tecnica_comercial: 0,
  analise_elaboracao_proposta: 0,
  visita_tecnica_levantamento: 0,
  elaboracao_dimensionamento_projeto: 1500,
  processo_acesso_rede: 0,
  seguros: 0,
  instalacao_civil_eletrica_total: 24968.339853249476,
  frete: 0,
  materiais_eletricos: 5565.618448637317,
  transformador: 9509.43,
  transformador_label: '',
  transformador_value: '',
  inversor: "",
  placa: "",
  diarias_equipe_instalacao: 8117.400419287211,
  deslocamento_hospedagem_equipe_instalacao: 0,
  alimentacao_equipe_instalacao: 0,
  andaime: 737.9454926624737,
  descarga_kit_fotovoltaico: 300,
  reserva_servicos: 737.9454926624737,
  obras_civis_total: 0,
  civil: 0,
  alvenaria: 0,
  aterramento: 0,
  pos_venda_total: 0,
  visita_tecnica_comercial_inspecao: 0,
  servico_o_m_1_visita: 0,
  servico_o_m_pacote_1_ano: 0,
  seguro: 0,
  impostos_taxas_total: 0,
  pis: 0,
  cofins: 0,
  iss: 12954.825377358495,
  custos_fixos_variaveis_total: 92534.4669811321,
  multiplicador: 0,
  custo_administrativo: 10322.832363731657,
  comissao_vendedor_interno: 2064.5664727463313,
  comissao_representante: 10322.832363731657,
  comissao_representante_perc: 0.05,
  lucro_liquido_valor_total: 30401.070550314478,
  lucro_liquido_valor_total_perc: 0.14725159471312305,
  lucro_liquido_servicos: 0,
  lucro_liquido_servicos_perc: 0.3285378037192703,
  custom_lucro_liquido_servicos_perc: 0,
  custom_lucro: false,
  equipamentos: 113922.18029350105,
  total: 206456.64727463314,
  total_wp: 5.595444360795455,
  total_custos_diretos: 26468.339853249476,
  total_projeto_repensa: 140390.52014675052,
  modulos: 0
};

const mapClassificacao = {
  baixa_tensao: 'Baixa tensão',
  media_tensao: 'Média tensão'
};

const mapTipoInstalacao = {
  telhado_de_barro: 'Telhado de Barro',
  telhado_de_cimento: 'Telhado de Cimento',
  telhado_de_fibrocimento: 'Telhado de Fibrocimento',
  telhado_de_chapa_metalica: 'Telhado de Chapa Metálica',
  laje: 'Laje',
  solo: 'Solo',
  carport: 'Carport'
};

const PGTO = (valor, prestacoes, juros) => {
  let E, cont;
  E = parseFloat("1");
  cont = parseFloat("1");

  for (let k = 1; k <= prestacoes; k++) {
    cont = cont * (1 + juros);
    E = E + cont;
  }

  E = E - cont;
  valor = valor * cont;

  return valor / E;
}

function sinal(x) {
  return x < 0.0 ? -1 : 1;
}

function vpl (taxa, montantes) {
    let ret = montantes[0];
    for (let i = 1; i < montantes.length; i++) {
      ret += montantes[i] / Math.pow((1.0 + taxa), i);
    }
    return ret;
}

function tir (montantes) {   
    const ret = -1000000000.0;
    let juros_inicial = -0.09;
    let juros_medio = 0.0;
    let juros_final = 1.0;
    let vpl_inicial = 0.0;
    let vpl_final = 0.0;
    const vf = 0.0;
    const erro = 1e-5; // Valor mínimo que determina que a raiz foi encontrada

    // Procura um possível intervalo para a raiz
    // O looping deve terminar antes de i chegar a 100!

    for (let i = 0; i < 100; i++) {
      vpl_inicial = vpl(juros_inicial, montantes);
      vpl_final = vpl(juros_final, montantes);
      if (sinal(vpl_inicial) !== sinal(vpl_final)) {
        break;
      }
      juros_inicial -= 1.0;
      juros_final += 1.0;
    };

    // Contador que evita um looping infinito
    let count = 0;

    // Busca por Bisseção
    for (;;) {          
      juros_medio = (juros_inicial + juros_final) / 2.0;        
      const vpl_medio = vpl(juros_medio, montantes);

      if (Math.abs(vpl_medio) <= erro) {
        // Resultado foi encontrado
        return juros_medio * 100.0;
      };

      if (sinal(vpl_inicial) === sinal(vpl_medio)) {
        juros_inicial = juros_medio;
        vpl_inicial = vpl(juros_medio, montantes);          
      } else {
        juros_final = juros_medio;
        vpl_final = vpl(juros_medio, montantes);          
      };

      // Evita um possível looping infinito
      if (++count > 10000) {
        throw "looping inválido";
      }
    };

    // O algoritmo nunca deve chegar aqui.
    return ret;
}

const calculoAlternativaVPL = (fluxo) => {
  let VPL = 0;
  if (fluxo && fluxo.dados && fluxo.dados[24] && fluxo.dados[24][4]) {
    VPL = fluxo.dados[24][4] / 2.2;
  } else if (fluxo && fluxo.vpl) {
    VPL = fluxo.vpl;
  }
  return VPL;
}

const calcularMediaGeracao = (proposta) => {
  let total = 0;
  let media = 0;
  if (proposta && proposta.geracao) {
    if (proposta.geracao.janeiro && proposta.geracao.janeiro.geracao) {
      if (proposta.geracao.janeiro.geracao !== null || proposta.geracao.janeiro.geracao !== '') {
        if (typeof proposta.geracao.janeiro.geracao === 'string') {
          total += parseFloat(proposta.geracao.janeiro.geracao);
        } else {
          total += proposta.geracao.janeiro.geracao;
        }
      }
    }
    if (proposta.geracao.fevereiro && proposta.geracao.fevereiro.geracao) {
      if (proposta.geracao.fevereiro.geracao !== null || proposta.geracao.fevereiro.geracao !== '') {
        if (typeof proposta.geracao.fevereiro.geracao === 'string') {
          total += parseFloat(proposta.geracao.fevereiro.geracao);
        } else {
          total += proposta.geracao.fevereiro.geracao;
        }
      }
    }
    if (proposta.geracao.marco && proposta.geracao.marco.geracao) {
      if (proposta.geracao.marco.geracao !== null || proposta.geracao.marco.geracao !== '') {
        if (typeof proposta.geracao.marco.geracao === 'string') {
          total += parseFloat(proposta.geracao.marco.geracao);
        } else {
          total += proposta.geracao.marco.geracao;
        }
      }
    }
    if (proposta.geracao.abril && proposta.geracao.abril.geracao) {
      if (proposta.geracao.abril.geracao !== null || proposta.geracao.abril.geracao !== '') {
        if (typeof proposta.geracao.abril.geracao === 'string') {
          total += parseFloat(proposta.geracao.abril.geracao);
        } else {
          total += proposta.geracao.abril.geracao;
        }
      }
    }
    if (proposta.geracao.maio && proposta.geracao.maio.geracao) {
      if (proposta.geracao.maio.geracao !== null || proposta.geracao.maio.geracao !== '') {
        if (typeof proposta.geracao.maio.geracao === 'string') {
          total += parseFloat(proposta.geracao.maio.geracao);
        } else {
          total += proposta.geracao.maio.geracao;
        }
      }
    }
    if (proposta.geracao.junho && proposta.geracao.junho.geracao) {
      if (proposta.geracao.junho.geracao !== null || proposta.geracao.junho.geracao !== '') {
        if (typeof proposta.geracao.junho.geracao === 'string') {
          total += parseFloat(proposta.geracao.junho.geracao);
        } else {
          total += proposta.geracao.junho.geracao;
        }
      }
    }
    if (proposta.geracao.julho && proposta.geracao.julho.geracao) {
      if (proposta.geracao.julho.geracao !== null || proposta.geracao.julho.geracao !== '') {
        if (typeof proposta.geracao.julho.geracao === 'string') {
          total += parseFloat(proposta.geracao.julho.geracao);
        } else {
          total += proposta.geracao.julho.geracao;
        }
      }
    }
    if (proposta.geracao.agosto && proposta.geracao.agosto.geracao) {
      if (proposta.geracao.agosto.geracao !== null || proposta.geracao.agosto.geracao !== '') {
        if (typeof proposta.geracao.agosto.geracao === 'string') {
          total += parseFloat(proposta.geracao.agosto.geracao);
        } else {
          total += proposta.geracao.agosto.geracao;
        }
      }
    }
    if (proposta.geracao.setembro && proposta.geracao.setembro.geracao) {
      if (proposta.geracao.setembro.geracao !== null || proposta.geracao.setembro.geracao !== '') {
        if (typeof proposta.geracao.setembro.geracao === 'string') {
          total += parseFloat(proposta.geracao.setembro.geracao);
        } else {
          total += proposta.geracao.setembro.geracao;
        }
      }
    }
    if (proposta.geracao.outubro && proposta.geracao.outubro.geracao) {
      if (proposta.geracao.outubro.geracao !== null || proposta.geracao.outubro.geracao !== '') {
        if (typeof proposta.geracao.outubro.geracao === 'string') {
          total += parseFloat(proposta.geracao.outubro.geracao);
        } else {
          total += proposta.geracao.outubro.geracao;
        }
      }
    }
    if (proposta.geracao.novembro && proposta.geracao.novembro.geracao) {
      if (proposta.geracao.novembro.geracao !== null || proposta.geracao.novembro.geracao !== '') {
        if (typeof proposta.geracao.novembro.geracao === 'string') {
          total += parseFloat(proposta.geracao.novembro.geracao);
        } else {
          total += proposta.geracao.novembro.geracao;
        }
      }
    }
    if (proposta.geracao.dezembro && proposta.geracao.dezembro.geracao) {
      if (proposta.geracao.dezembro.geracao !== null || proposta.geracao.dezembro.geracao !== '') {
        if (typeof proposta.geracao.dezembro.geracao === 'string') {
          total += parseFloat(proposta.geracao.dezembro.geracao);
        } else {
          total += proposta.geracao.dezembro.geracao;
        }
      }
    }
  }
  
  media = total / 12;

  return `${media.toFixed(1)}`;
}

const calcularMediaExcedente = (proposta) => {
  let total = 0;
  let media = 0;
  if (proposta && proposta.geracao) {
    if (proposta.geracao.janeiro && proposta.geracao.janeiro.excedente) {
      if (proposta.geracao.janeiro.excedente !== null || proposta.geracao.janeiro.excedente !== '') {
        if (typeof proposta.geracao.janeiro.excedente === 'string') {
          total += parseFloat(proposta.geracao.janeiro.excedente);
        } else {
          total += proposta.geracao.janeiro.excedente;
        }
      }
    }
    if (proposta.geracao.fevereiro && proposta.geracao.fevereiro.excedente) {
      if (proposta.geracao.fevereiro.excedente !== null || proposta.geracao.fevereiro.excedente !== '') {
        if (typeof proposta.geracao.fevereiro.excedente === 'string') {
          total += parseFloat(proposta.geracao.fevereiro.excedente);
        } else {
          total += proposta.geracao.fevereiro.excedente;
        }
      }
    }
    if (proposta.geracao.marco && proposta.geracao.marco.excedente) {
      if (proposta.geracao.marco.excedente !== null || proposta.geracao.marco.excedente !== '') {
        if (typeof proposta.geracao.marco.excedente === 'string') {
          total += parseFloat(proposta.geracao.marco.excedente);
        } else {
          total += proposta.geracao.marco.excedente;
        }
      }
    }
    if (proposta.geracao.abril && proposta.geracao.abril.excedente) {
      if (proposta.geracao.abril.excedente !== null || proposta.geracao.abril.excedente !== '') {
        if (typeof proposta.geracao.abril.excedente === 'string') {
          total += parseFloat(proposta.geracao.abril.excedente);
        } else {
          total += proposta.geracao.abril.excedente;
        }
      }
    }
    if (proposta.geracao.maio && proposta.geracao.maio.excedente) {
      if (proposta.geracao.maio.excedente !== null || proposta.geracao.maio.excedente !== '') {
        if (typeof proposta.geracao.maio.excedente === 'string') {
          total += parseFloat(proposta.geracao.maio.excedente);
        } else {
          total += proposta.geracao.maio.excedente;
        }
      }
    }
    if (proposta.geracao.junho && proposta.geracao.junho.excedente) {
      if (proposta.geracao.junho.excedente !== null || proposta.geracao.junho.excedente !== '') {
        if (typeof proposta.geracao.junho.excedente === 'string') {
          total += parseFloat(proposta.geracao.junho.excedente);
        } else {
          total += proposta.geracao.junho.excedente;
        }
      }
    }
    if (proposta.geracao.julho && proposta.geracao.julho.excedente) {
      if (proposta.geracao.julho.excedente !== null || proposta.geracao.julho.excedente !== '') {
        if (typeof proposta.geracao.julho.excedente === 'string') {
          total += parseFloat(proposta.geracao.julho.excedente);
        } else {
          total += proposta.geracao.julho.excedente;
        }
      }
    }
    if (proposta.geracao.agosto && proposta.geracao.agosto.excedente) {
      if (proposta.geracao.agosto.excedente !== null || proposta.geracao.agosto.excedente !== '') {
        if (typeof proposta.geracao.agosto.excedente === 'string') {
          total += parseFloat(proposta.geracao.agosto.excedente);
        } else {
          total += proposta.geracao.agosto.excedente;
        }
      }
    }
    if (proposta.geracao.setembro && proposta.geracao.setembro.excedente) {
      if (proposta.geracao.setembro.excedente !== null || proposta.geracao.setembro.excedente !== '') {
        if (typeof proposta.geracao.setembro.excedente === 'string') {
          total += parseFloat(proposta.geracao.setembro.excedente);
        } else {
          total += proposta.geracao.setembro.excedente;
        }
      }
    }
    if (proposta.geracao.outubro && proposta.geracao.outubro.excedente) {
      if (proposta.geracao.outubro.excedente !== null || proposta.geracao.outubro.excedente !== '') {
        if (typeof proposta.geracao.outubro.excedente === 'string') {
          total += parseFloat(proposta.geracao.outubro.excedente);
        } else {
          total += proposta.geracao.outubro.excedente;
        }
      }
    }
    if (proposta.geracao.novembro && proposta.geracao.novembro.excedente) {
      if (proposta.geracao.novembro.excedente !== null || proposta.geracao.novembro.excedente !== '') {
        if (typeof proposta.geracao.novembro.excedente === 'string') {
          total += parseFloat(proposta.geracao.novembro.excedente);
        } else {
          total += proposta.geracao.novembro.excedente;
        }
      }
    }
    if (proposta.geracao.dezembro && proposta.geracao.dezembro.excedente) {
      if (proposta.geracao.dezembro.excedente !== null || proposta.geracao.dezembro.excedente !== '') {
        if (typeof proposta.geracao.dezembro.excedente === 'string') {
          total += parseFloat(proposta.geracao.dezembro.excedente);
        } else {
          total += proposta.geracao.dezembro.excedente;
        }
      }
    }
  }
  
  media = total / 12;

  return `${media.toFixed(1)}`;
}

const calcularNumeroArvoresPlantadas = (proposta) => {
  let geracao = '';
  if (proposta && proposta.geracao && proposta.geracao.total && proposta.geracao.total.geracao) {
    let value = proposta.geracao.total.geracao;
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (value && value !== '' && value !== 0 && value !== null) {
      const arvores = value / 300;
      geracao = `${arvores.toFixed(0)}`;
    }
  }
  return geracao;
}

const calcularCo2 = (proposta) => {
  let co2 = '';
  if (proposta && proposta.geracao && proposta.geracao.total && proposta.geracao.total.geracao) {
    let value = proposta.geracao.total.geracao;
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (value && value !== '' && value !== 0 && value !== null) {
      const emissao = (value * 0.13) / 1000;
      co2 = `${emissao.toFixed(1)}`;
    }  
  }
  return co2;
}

const calcularLitrosGasolina = (proposta) => {
  let emissao = 0;
  let litrosGas = '';
  let litros = 0;

  if (proposta && proposta.geracao && proposta.geracao.total && proposta.geracao.total.geracao) {
    let value = proposta.geracao.total.geracao;
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (value && value !== '' && value !== 0 && value !== null) {
      emissao = (value * 0.13) / 1000;
    }  
  }

  litros = (emissao * 1000) / 2.28;
  litrosGas = `${litros.toFixed(0)}`;
  return litrosGas;
}

const calcularFinanciamentoSantander = (proposta) => {
  const financiamento = {
    opcao_1: {
      valor_total_a_vista: 0,
      parcelas_6: 0,
      parcelas_12: 0,
      parcelas_24: 0,
      parcelas_36: 0,
      parcelas_48: 0,
      parcelas_60: 0
    },
    opcao_2: {
      valor_servicos: 0,
      valor_servicos_4_parcelas: 0,
      valor_equipamentos: 0,
      valor_equipamentos_a_vista: 0,
      valor_equipamentos_12_parcelas: 0,
      valor_equipamentos_36_parcelas: 0,
      valor_equipamentos_48_parcelas: 0,
      valor_equipamentos_60_parcelas: 0
    }
  };

  let total = 0, servicos = 0, equipamentos = 0;

  if (proposta) {
    if (proposta.total && proposta.total !== '' && proposta.total !== ' ' && proposta.total !== null) {
      if (typeof proposta.total === 'string') {
        total = parseFloat(proposta.total);
      } else {
        total = proposta.total;
      }
      financiamento.opcao_1.valor_total_a_vista = total;
    }

    if (proposta.equipamentos && proposta.equipamentos !== '' && proposta.equipamentos !== ' ' && proposta.equipamentos !== null) {
      if (typeof proposta.equipamentos === 'string') {
        equipamentos = parseFloat(proposta.equipamentos);
      } else {
        equipamentos = proposta.equipamentos;
      }
      financiamento.opcao_2.valor_equipamentos = equipamentos;
    }

    if (proposta.faturamento && proposta.faturamento === 'direto') {
      servicos = total - equipamentos;
    } else if (proposta.faturamento && proposta.faturamento === 'completo') {
      servicos = total - equipamentos;
    } else {
      servicos = total - equipamentos;
    }

    financiamento.opcao_2.valor_servicos = servicos;

    financiamento.opcao_2.valor_servicos_4_parcelas = servicos / 4;

    const arrayFinanCompleto = [
      [0.018, 60, 0],
      [0.018, 48, 0],
      [0.018, 36, 0],
      [0.018, 24, 0],
      [0.017, 12, 0],
      [0.017, 6, 0],
      [0.017, 3, 0]
    ];
    arrayFinanCompleto.forEach((item) => {
      item[2] = PGTO(total, item[1], item[0]);
    });
    financiamento.opcao_1.parcelas_6 = arrayFinanCompleto[5][2];
    financiamento.opcao_1.parcelas_12 = arrayFinanCompleto[4][2];
    financiamento.opcao_1.parcelas_24 = arrayFinanCompleto[3][2];
    financiamento.opcao_1.parcelas_36 = arrayFinanCompleto[2][2];
    financiamento.opcao_1.parcelas_48 = arrayFinanCompleto[1][2];
    financiamento.opcao_1.parcelas_60 = arrayFinanCompleto[0][2];

    const arrayFinanEquipamentos = [
      [0.0125, 60, 0],
      [0.0120, 48, 0],
      [0.0115, 36, 0],
      [0.0115, 24, 0],
      [0.0115, 12, 0],
      [0.0115, 6, 0],
      [0.0115, 3, 0]
    ];
    arrayFinanEquipamentos.forEach((item) => {
      item[2] = PGTO(equipamentos, item[1], item[0]);
    });
    financiamento.opcao_2.valor_equipamentos_12_parcelas = arrayFinanEquipamentos[4][2];
    financiamento.opcao_2.valor_equipamentos_36_parcelas = arrayFinanEquipamentos[2][2];
    financiamento.opcao_2.valor_equipamentos_48_parcelas = arrayFinanEquipamentos[1][2];
    financiamento.opcao_2.valor_equipamentos_60_parcelas = arrayFinanEquipamentos[0][2];
  }

  return financiamento;
}

const calcularEconomia = (proposta) => {
  const economia = {
    gasto_sem_energia_solar: [],
    economia: []
  };

  for (let i = 0; i < 25; i++) {
    economia.gasto_sem_energia_solar.push(i + 1);
    economia.economia.push((i + 1) * 2);
  }

  let consumo_medio_mensal = 0;
  let tarifa_media_energia = 0;
  const inc_per_year = 0.09;

  if (proposta) {
    if (proposta.consumo_medio_mensal && proposta.consumo_medio_mensal !== '' && proposta.consumo_medio_mensal !== ' ' && proposta.consumo_medio_mensal !== null) {
      if (typeof proposta.consumo_medio_mensal === 'string') {
        consumo_medio_mensal = parseFloat(proposta.consumo_medio_mensal);
      } else {
        consumo_medio_mensal = proposta.consumo_medio_mensal;
      }
    }
    if (proposta.tarifa_media_energia && proposta.tarifa_media_energia !== '' && proposta.tarifa_media_energia !== ' ' && proposta.tarifa_media_energia !== null) {
      if (typeof proposta.tarifa_media_energia === 'string') {
        tarifa_media_energia = parseFloat(proposta.tarifa_media_energia);
      } else {
        tarifa_media_energia = proposta.tarifa_media_energia;
      }
    }
  }

  for (let i = 0; i < 25; i++) {
    if (i === 0) {
      const calc = (consumo_medio_mensal * tarifa_media_energia) * 12;
      economia.gasto_sem_energia_solar[i] = calc;
      economia.economia[i] = calc * 2;
    } else {
      const anterior = economia.gasto_sem_energia_solar[i - 1];
      const calc = anterior * (1 + inc_per_year);
      economia.gasto_sem_energia_solar[i] = calc;
      economia.economia[i] = calc * 2;
    }
  }

  return economia;
}

const calcularFluxoCaixaProjeto = (proposta, economia) => {
  const fluxo = {
    dados: [],
    vpl: 0,
    payback: 0,
    tir: 0,
    economia_25_anos: 0
  }

  // População dos dados
  for (let i = 0; i < 25; i++) {
    const line = [0, 0, 0, 0, 0];
    fluxo.dados.push(line);
  }

  let total = 0;
  let tarifa_media_energia = 0;
  let geracao = 0;
  const inc_per_year = 0.09;
  const solar_decrease_rate = 0.995;

  let payback = 0;

  if (proposta) {
    if (proposta.total && proposta.total !== '' && proposta.total !== ' ' && proposta.total !== null) {
      if (typeof proposta.total === 'string') {
        total = parseFloat(proposta.total);
      } else {
        total = proposta.total;
      }
    }
    if (proposta.tarifa_media_energia && proposta.tarifa_media_energia !== '' && proposta.tarifa_media_energia !== ' ' && proposta.tarifa_media_energia !== null) {
      if (typeof proposta.tarifa_media_energia === 'string') {
        tarifa_media_energia = parseFloat(proposta.tarifa_media_energia);
      } else {
        tarifa_media_energia = proposta.tarifa_media_energia;
      }
    }
    if (proposta.geracao.total.geracao && proposta.geracao.total.geracao !== '' && proposta.geracao.total.geracao !== ' ' && proposta.geracao.total.geracao !== null) {
      if (typeof proposta.geracao.total.geracao === 'string') {
        geracao = parseFloat(proposta.geracao.total.geracao);
      } else {
        geracao = proposta.geracao.total.geracao;
      }
    }
  }

  // Fluxo de caixa
  for (let i = 0; i < 25; i++) {
    if (i === 0) {
      fluxo.dados[i][0] = geracao;
      fluxo.dados[i][1] = tarifa_media_energia;
      fluxo.dados[i][2] = fluxo.dados[0][0] * fluxo.dados[0][1];
      fluxo.dados[i][3] = total;
      fluxo.dados[i][4] = fluxo.dados[0][2] - fluxo.dados[0][3];
    } else {
      fluxo.dados[i][0] = fluxo.dados[i - 1][0] * solar_decrease_rate;
      fluxo.dados[i][1] = fluxo.dados[i - 1][1] * (1 + inc_per_year);
      fluxo.dados[i][2] = fluxo.dados[i][0] * fluxo.dados[i][1];
      fluxo.dados[i][4] = fluxo.dados[i - 1][4] + fluxo.dados[i][2];
    }
    if (payback === 0 && fluxo.dados[i][4] > 0) {
      payback = i + 1;
    }
  }

  fluxo.payback = payback;

  // CÁLCULO TIR E VPL
  const balanceSheet = [];
  for (let i = 0; i < 26; i++) {
    balanceSheet.push(0);
  }
  if (economia) {
    for (let i = 0; i < 26; i++) {
      if (i === 0) {
        balanceSheet[i] = -1 * total;
      } else if (i === 1) {
        balanceSheet[i] = economia.gasto_sem_energia_solar[i - 1];
      } else {
        balanceSheet[i] = (economia.gasto_sem_energia_solar[i - 1]) - (economia.gasto_sem_energia_solar[i - 2]);
      }
    }
  }

  const VPL = vpl(0, balanceSheet);
  const TIR = tir(balanceSheet);

  fluxo.tir = TIR / 10;
  fluxo.vpl = VPL + total;
  fluxo.economia_25_anos = fluxo.vpl;
  // console.log(TIR);
  // console.log(balanceSheet);
  // console.log(VPL);

  return fluxo;
}

const formatPercentage = (value) => {
  if (value === '') {
    return ''
  } else {
    value = parseFloat(value)
    value = value * 100
    return `${value.toFixed(2)} %`
  }
}

const formatCurrency = (value) => {
  if (value === '') {
    value = 0
  }
  value = parseFloat(value)

  return currencyFormatter.format(value)
}

const formatCurrencyWithoutSymbol = (value) => {
  if (value === '') {
    value = 0
  }
  value = parseFloat(value)

  return numberFormatter.format(value.toFixed(2))
}

const formatW = (value) => {
  if (value === '') {
    value = 0;
  }
  value = parseFloat(value);

  return `${numberFormatter.format(value.toFixed(2))} W`
}

const formatkWhMes = (value) => {
  if (value === '') {
    value = 0;
  }
  value = parseFloat(value);

  return `${numberFormatter.format(value.toFixed(2))} kWh/mês`
}

const formatkWhAno  = (value) => {
  if (value === '') {
    value = 0;
  }
  value = parseFloat(value);

  return `${numberFormatter.format(value.toFixed(2))} kWh/ano`
}

const formatV = (value) => {
  if (value === '') {
    value = 0;
  }
  value = parseFloat(value);

  return `${numberFormatter.format(value.toFixed(1))}`
}

const formatKWP = (value) => {
  if (value === '') {
    value = 0
  }
  value = parseFloat(value)

  return `${numberFormatter.format(value.toFixed(2))} kWp`
}

const formatKWh = (value) => {
  if (value === '') { value = 0; }
  value = parseFloat(value);
  return `${numberFormatter.format(value.toFixed(2))} kWh`;
}

const gerarDataAtual = () => {
  const data = new Date();
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();
  return `${dia}/${mes}/${ano}`;
}

const calcularAutonomiaMedia = (proposta) => {
  let total = 0;
  if (proposta && proposta.geracao) {
    if (proposta.geracao.janeiro && proposta.geracao.janeiro.autonomia) {
      if (typeof proposta.geracao.janeiro.autonomia === 'string') {
        total += parseFloat(proposta.geracao.janeiro.autonomia);
      } else {
        total += proposta.geracao.janeiro.autonomia;
      }
    }
    if (proposta.geracao.fevereiro && proposta.geracao.fevereiro.autonomia) {
      if (typeof proposta.geracao.fevereiro.autonomia === 'string') {
        total += parseFloat(proposta.geracao.fevereiro.autonomia);
      } else {
        total += proposta.geracao.fevereiro.autonomia;
      }
    }
    if (proposta.geracao.marco && proposta.geracao.marco.autonomia) {
      if (typeof proposta.geracao.marco.autonomia === 'string') {
        total += parseFloat(proposta.geracao.marco.autonomia);
      } else {
        total += proposta.geracao.marco.autonomia;
      }
    }
    if (proposta.geracao.abril && proposta.geracao.abril.autonomia) {
      if (typeof proposta.geracao.abril.autonomia === 'string') {
        total += parseFloat(proposta.geracao.abril.autonomia);
      } else {
        total += proposta.geracao.abril.autonomia;
      }
    }
    if (proposta.geracao.maio && proposta.geracao.maio.autonomia) {
      if (typeof proposta.geracao.maio.autonomia === 'string') {
        total += parseFloat(proposta.geracao.maio.autonomia);
      } else {
        total += proposta.geracao.maio.autonomia;
      }
    }
    if (proposta.geracao.junho && proposta.geracao.junho.autonomia) {
      if (typeof proposta.geracao.junho.autonomia === 'string') {
        total += parseFloat(proposta.geracao.junho.autonomia);
      } else {
        total += proposta.geracao.junho.autonomia;
      }
    }
    if (proposta.geracao.julho && proposta.geracao.julho.autonomia) {
      if (typeof proposta.geracao.julho.autonomia === 'string') {
        total += parseFloat(proposta.geracao.julho.autonomia);
      } else {
        total += proposta.geracao.julho.autonomia;
      }
    }
    if (proposta.geracao.agosto && proposta.geracao.agosto.autonomia) {
      if (typeof proposta.geracao.agosto.autonomia === 'string') {
        total += parseFloat(proposta.geracao.agosto.autonomia);
      } else {
        total += proposta.geracao.agosto.autonomia;
      }
    }
    if (proposta.geracao.setembro && proposta.geracao.setembro.autonomia) {
      if (typeof proposta.geracao.setembro.autonomia === 'string') {
        total += parseFloat(proposta.geracao.setembro.autonomia);
      } else {
        total += proposta.geracao.setembro.autonomia;
      }
    }
    if (proposta.geracao.outubro && proposta.geracao.outubro.autonomia) {
      if (typeof proposta.geracao.outubro.autonomia === 'string') {
        total += parseFloat(proposta.geracao.outubro.autonomia);
      } else {
        total += proposta.geracao.outubro.autonomia;
      }
    }
    if (proposta.geracao.novembro && proposta.geracao.novembro.autonomia) {
      if (typeof proposta.geracao.novembro.autonomia === 'string') {
        total += parseFloat(proposta.geracao.novembro.autonomia);
      } else {
        total += proposta.geracao.novembro.autonomia;
      }
    }
    if (proposta.geracao.dezembro && proposta.geracao.dezembro.autonomia) {
      if (typeof proposta.geracao.dezembro.autonomia === 'string') {
        total += parseFloat(proposta.geracao.dezembro.autonomia);
      } else {
        total += proposta.geracao.dezembro.autonomia;
      }
    }
  }
  const autonomia = total / 12;
  return `${numberFormatter.format((autonomia * 100).toFixed(2))} %`;
}

const formatarTextShow = (text) => {
  if (text === '' || text === ' ' || text === null || text === undefined || text === 'undefined' || typeof text === 'undefined') {
    text = '-';
  }
  return text;
}

const getEscopoRepensa = (proposta) => {
  let opcoes = [];
  if (proposta && proposta.tipo_de_instalacao) {
    if (proposta.tipo_de_instalacao === 'solo') {
      opcoes = [
        '• Projeto executivo civil e elétrico;',
        '• Instalação e montagem eletromecânica;',
        '• Gerenciamento, supervisão e fiscalização da obra;',
        '• Anotação de Responsabilidade Técnica (ART) – projeto e execução;',
        '• Relatórios de inspeção e ensaio de comissionamento;',
        '• Obtenção das licenças junto à distribuidora de energia;',
        '• Fornecimento de Material Elétrico de corrente alternada: cabos, disjuntores, quadros, e proteções em baixa tensão;',
        '• Obra civil: abertura de valas para passagem de cabos, fundação, concretagem para fixação das estruturas;',
        '• Fornecimento de MO e materiais para malha de aterramento de acordo com norma vigente;',
        '• TRANSFORMADOR;',
        '• Frete incluso;',
        '• Deslocamento, hospedagem e alimentação;',
        '• A proposta está sujeita a alterações conforme observações na vistoria técnica.',
        'Importante: os equipamentos deverão ser armazenados no local de instalação até o início da execução da montagem',
        '• Prazo total para execução dos serviços: até 120 dias',
        '• Validade da proposta: 10 dias'
      ];
      const transformador = proposta.transformador_label;
      if (transformador !== '' && transformador !== ' ' && transformador !== null) {
        opcoes[9] = `• ${transformador};`;
      } else {
        opcoes.splice(9, 1);
      }
    } else {
      opcoes = [
        '• Projeto executivo civil e elétrico;',
        '• Instalação e montagem eletromecânica;',
        '• Gerenciamento, supervisão e fiscalização da obra;',
        '• Anotação de Responsabilidade Técnica (ART) – projeto e execução;',
        '• Relatórios de inspeção e ensaio de comissionamento;',
        '• Obtenção das licenças junto à distribuidora de energia;',
        '• Fornecimento de Material Elétrico de corrente alternada: cabos, disjuntores, quadros, e proteções em baixa tensão;',
        '• TRANSFORMADOR',
        '• Frete incluso;',
        '• Deslocamento, hospedagem e alimentação;',
        '• A proposta está sujeita a alterações conforme observações na vistoria técnica.',
        'Importante: os equipamentos deverão ser armazenados no local de instalação até o início da execução da montagem',
        '• Prazo total para execução dos serviços: até 120 dias',
        '• Validade da proposta: 10 dias'
      ];
      const transformador = proposta.transformador_label;
      if (transformador !== '' && transformador !== ' ' && transformador !== null) {
        opcoes[7] = `• ${transformador};`;
      } else {
        opcoes.splice(7, 1);
      }
    }
  }
  return opcoes;
}

const getCondicoesComerciaisCoordenadasBox = (proposta) => {
  const direto = { x: 37, y: 743 };
  const completo = { x: 37, y: 715 };

  if (proposta && proposta.faturamento && proposta.faturamento === 'completo') {
    return completo;
  }

  return direto;
}

export default async function Gerador({propostaData, demo = false, versao = '1.0', vendedor = false, telefoneRepensa = '', totalKwp = 0}) {
  let proposta;
  if (demo) {
    proposta = propostaDemo;
  } else {
    proposta = propostaData;
  }

  if (vendedor === false) {
    vendedor = vendedorDemo;
  }

  const financiamentoSantander = calcularFinanciamentoSantander(proposta);
  const economia = calcularEconomia(proposta);
  const fluxoDeCaixa = calcularFluxoCaixaProjeto(proposta, economia);
  const alternativaVPL = calculoAlternativaVPL(fluxoDeCaixa);

  try {
    const origin = window.location.origin;
    const baseFileName = 'proposta-comercial.pdf';
    const urlPdfBase = `${origin}/${baseFileName}`;
  
    const axiosOptions = { responseType: 'arraybuffer' };
    const existingPdfBytes = await axios.get(urlPdfBase, axiosOptions).then(res => res.data);

    const fontFileName = 'fonts/Raleway-SemiBold.ttf';
    const urlFont = `${origin}/${fontFileName}`;
    const fontBytes = await axios.get(urlFont, axiosOptions).then(res => res.data);

    const pdfDoc = await PDFDocument.load(existingPdfBytes, {
      updateMetadata: true
    });

    pdfDoc.registerFontkit(fontkit);
    // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const ralewayFont = await pdfDoc.embedFont(fontBytes);
    const Colors = {
      black: rgb(0, 0, 0),
      white: rgb(1, 1, 1),
      azul: rgb(0.07, 0.13, 0.25),
      amarelo: rgb(0.92, 0.8, 0.28)
    };

    const pages = pdfDoc.getPages();

    const page1 = pages[0];

    let id = 0;
    if (proposta.id) {
      id = proposta.id;
    }

    let codigo_projeto = 'REP-';
    if (vendedor && vendedor.codigo) {
      codigo_projeto += `${vendedor.codigo}`;
    }

    // Código do projeto
    page1.drawText(`${codigo_projeto}-${id}`, {
      x: 467,
      y: 790.5,
      size: 14,
      font: ralewayFont,
      color: Colors.azul
    });
    // Versão
    page1.drawText(`v ${versao}`, {
      x: 467,
      y: 780.5,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    // Nome do cliente
    page1.drawText(proposta.nome_do_cliente, {
      x: 27,
      y: 595,
      size: 24,
      font: ralewayFont,
      color: Colors.azul
    });
    // Potência do sistema
    page1.drawText(formatKWP(proposta.potencia_do_sistema), {
      x: 140,
      y: 474,
      size: 11,
      font: ralewayFont,
      color: Colors.azul
    });

    let executivo_de_vendas_nome = '';
    let executivo_de_vendas_telefone = '';

    if (proposta.representante && proposta.representante_nome !== '') {
      executivo_de_vendas_nome = proposta.representante_nome;
      executivo_de_vendas_telefone = proposta.representante_telefone;
    } else {
      executivo_de_vendas_nome = (proposta.executivo_de_vendas_nome) ? proposta.executivo_de_vendas_nome : '';
      executivo_de_vendas_telefone = (proposta.executivo_de_vendas_telefone) ? proposta.executivo_de_vendas_telefone : '';
    }

    // Executivo de vendas
    page1.drawText(executivo_de_vendas_nome, {
      x: 144,
      y: 454,
      size: 11,
      font: ralewayFont,
      color: Colors.azul
    });

    // Proposta elaborada em
    page1.drawText(gerarDataAtual(), {
      x: 158,
      y: 436,
      size: 11,
      font: ralewayFont,
      color: Colors.azul
    });
    // Celular vendedor
    page1.drawText(executivo_de_vendas_telefone, {
      x: 45,
      y: 363,
      size: 12,
      font: ralewayFont,
      color: Colors.azul
    });
    // Telefone vendedor
    page1.drawText(telefoneRepensa, {
      x: 168,
      y: 363,
      size: 12,
      font: ralewayFont,
      color: Colors.azul
    });

    // Total kWp
    const page2 = pages[1];
    page2.drawText(`+${totalKwp} MWp`, {
      x: 272,
      y: 773.5,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page2.drawText(`${totalKwp} MWp`, {
      x: 155.5,
      y: 382,
      size: 10,
      font: ralewayFont,
      color: Colors.amarelo
    });

    const page5 = pages[4];

    // Dados do cliente
    page5.drawText('Dados do cliente', {
      x: 28,
      y: 806,
      size: 17,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText('Nome', {
      x: 28,
      y: 790,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatarTextShow(proposta.nome_do_cliente), {
      x: 28,
      y: 780,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText('CPF/CNPJ', {
      x: 388,
      y: 790,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatarTextShow(proposta.cpf_cliente), {
      x: 388,
      y: 780,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText('Telefone', {
      x: 28,
      y: 760,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatarTextShow(proposta.telefone_cliente), {
      x: 28,
      y: 750,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText('E-mail', {
      x: 388,
      y: 760,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatarTextShow(proposta.email_cliente), {
      x: 388,
      y: 750,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });

    // Concessionária
    page5.drawText(proposta.concessionaria, {
      x: 28,
      y: 656,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Classificação
    page5.drawText(mapClassificacao[proposta.classificacao], {
      x: 208,
      y: 656,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Tipo de instalação
    page5.drawText(mapTipoInstalacao[proposta.tipo_de_instalacao], {
      x: 388,
      y: 656,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Consumo médio mensal
    page5.drawText(formatkWhMes(proposta.consumo_medio_mensal), {
      x: 28,
      y: 618,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Tarifa média de energia
    page5.drawText(formatCurrency(proposta.tarifa_media_energia), {
      x: 208,
      y: 618,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // N. Unidade Consumidora
    page5.drawText(formatarTextShow(proposta.n_unidade_consumidora), {
      x: 388,
      y: 618,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Consumo médio anual a ser suprido
    // formatkWhAno(proposta.geracao.total.consumo_a_compensar)
    page5.drawText(formatkWhAno(proposta.geracao.total.consumo_a_compensar), {
      x: 28,
      y: 580,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Demanda contratada
    page5.drawText(formatarTextShow(proposta.demanda_contratada), {
      x: 208,
      y: 580,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // BLANK SPACE
    // page5.drawText('', {
    //   x: 388,
    //   y: 580,
    //   size: 10,
    //   font: ralewayFont,
    //   color: Colors.azul
    // });
    // Potência do sistema
    page5.drawText(`[Potência: ${formatKWP(proposta.potencia_do_sistema)}]`, {
      x: 320,
      y: 524,
      size: 14,
      font: ralewayFont,
      color: Colors.azul
    });

    // Autonomia média
    page5.drawText(`Autonomia média: ${(proposta.autonomia_atendida).toFixed(0)} %`, {
      x: 60,
      y: 168,
      size: 12,
      font: ralewayFont,
      color: Colors.azul
    });

    // Tabela geração/consumo/excedente - LINHA1
    page5.drawText(formatV(proposta.geracao.janeiro.geracao), {
      x: 88,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.fevereiro.geracao), {
      x: 125,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.marco.geracao), {
      x: 162,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.abril.geracao), {
      x: 199,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.maio.geracao), {
      x: 236,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.junho.geracao), {
      x: 273,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.julho.geracao), {
      x: 310,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.agosto.geracao), {
      x: 347,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.setembro.geracao), {
      x: 384,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.outubro.geracao), {
      x: 421,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.novembro.geracao), {
      x: 458,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.dezembro.geracao), {
      x: 495,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(calcularMediaGeracao(proposta), {
      x: 532,
      y: 122,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });

    // Tabela geração/consumo/excedente - LINHA2
    page5.drawText(formatV(proposta.geracao.janeiro.consumo_a_compensar), {
      x: 88,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.fevereiro.consumo_a_compensar), {
      x: 125,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.marco.consumo_a_compensar), {
      x: 162,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.abril.consumo_a_compensar), {
      x: 199,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.maio.consumo_a_compensar), {
      x: 236,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.junho.consumo_a_compensar), {
      x: 273,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.julho.consumo_a_compensar), {
      x: 310,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.agosto.consumo_a_compensar), {
      x: 347,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.setembro.consumo_a_compensar), {
      x: 384,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.outubro.consumo_a_compensar), {
      x: 421,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.novembro.consumo_a_compensar), {
      x: 458,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.dezembro.consumo_a_compensar), {
      x: 495,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.total.consumo_a_compensar / 12), {
      x: 532,
      y: 106,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });

    // Tabela geração/consumo/excedente - LINHA3
    page5.drawText(formatV(proposta.geracao.janeiro.excedente), {
      x: 88,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.fevereiro.excedente), {
      x: 125,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.marco.excedente), {
      x: 162,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.abril.excedente), {
      x: 199,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.maio.excedente), {
      x: 236,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.junho.excedente), {
      x: 273,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.julho.excedente), {
      x: 310,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.agosto.excedente), {
      x: 347,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.setembro.excedente), {
      x: 384,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.outubro.excedente), {
      x: 421,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.novembro.excedente), {
      x: 458,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(formatV(proposta.geracao.dezembro.excedente), {
      x: 495,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });
    page5.drawText(calcularMediaExcedente(proposta), {
      x: 532,
      y: 90,
      size: 8,
      font: ralewayFont,
      color: Colors.azul
    });

    // Equipamentos - componentes do sistema
    const page6 = pages[5];

    const linhasEquipamentos = []; const equipamentos = []; const maxEquipamentos = 7;
    linhasEquipamentos.push({ item: { x: 32, y: 720 }, descricao: { x: 150, y: 720 }, unidade: { x: 426, y: 720 }, quantidade: { x: 485, y: 720 } });
    linhasEquipamentos.push({ item: { x: 32, y: 702 }, descricao: { x: 150, y: 702 }, unidade: { x: 426, y: 702 }, quantidade: { x: 485, y: 702 } });
    linhasEquipamentos.push({ item: { x: 32, y: 684 }, descricao: { x: 150, y: 684 }, unidade: { x: 426, y: 684 }, quantidade: { x: 485, y: 684 } });
    linhasEquipamentos.push({ item: { x: 32, y: 667 }, descricao: { x: 150, y: 667 }, unidade: { x: 426, y: 667 }, quantidade: { x: 485, y: 667 } });
    linhasEquipamentos.push({ item: { x: 32, y: 649 }, descricao: { x: 150, y: 649 }, unidade: { x: 426, y: 649 }, quantidade: { x: 485, y: 649 } });
    linhasEquipamentos.push({ item: { x: 32, y: 633 }, descricao: { x: 150, y: 633 }, unidade: { x: 426, y: 633 }, quantidade: { x: 485, y: 633 } });
    linhasEquipamentos.push({ item: { x: 32, y: 615 }, descricao: { x: 150, y: 615 }, unidade: { x: 426, y: 615 }, quantidade: { x: 485, y: 615 } });

    equipamentos.push({ item: 'Módulo FV', descricao: proposta.placa, unidade: 'un', quantidade: `${parseFloat(proposta.modulos).toFixed(0)}` });
    if (proposta.inversor_1 && proposta.inversor_1_qtd) {
      equipamentos.push({ item: 'Inversor*', descricao: proposta.inversor_1, unidade: 'un', quantidade: proposta.inversor_1_qtd });
    }
    if (proposta.inversor_2 && proposta.inversor_2_qtd) {
      equipamentos.push({ item: 'Inversor*', descricao: proposta.inversor_2, unidade: 'un', quantidade: proposta.inversor_2_qtd });
    }
    if (proposta.inversor_3 && proposta.inversor_3_qtd) {
      equipamentos.push({ item: 'Inversor*', descricao: proposta.inversor_3, unidade: 'un', quantidade: proposta.inversor_3_qtd });
    }
    equipamentos.push({ item: 'Estruturas', descricao: 'Estruturas de alumínio para suporte dos módulos', unidade: 'CJT', quantidade: '1' });
    equipamentos.push({ item: 'Cabos CC', descricao: 'Cabo Solar 6mm < 1000 V (Preto e Vermelho)', unidade: 'CJT', quantidade: '1' });
    equipamentos.push({ item: 'Conectores Solar', descricao: 'Conectores Fêmea/Macho para cabos CC', unidade: 'CJT', quantidade: '1' });

    for (let i = 0; i < maxEquipamentos; i++) {
      if (equipamentos[i] && linhasEquipamentos[i]) {
        page6.drawText(`${equipamentos[i].item}`, { x: linhasEquipamentos[i].item.x, y: linhasEquipamentos[i].item.y, size: 10, font: ralewayFont, color: Colors.azul });
        page6.drawText(`${equipamentos[i].descricao}`, { x: linhasEquipamentos[i].descricao.x, y: linhasEquipamentos[i].descricao.y, size: 8, font: ralewayFont, color: Colors.azul });
        page6.drawText(`${equipamentos[i].unidade}`, { x: linhasEquipamentos[i].unidade.x, y: linhasEquipamentos[i].unidade.y, size: 10, font: ralewayFont, color: Colors.azul });
        page6.drawText(`${equipamentos[i].quantidade}`, { x: linhasEquipamentos[i].quantidade.x, y: linhasEquipamentos[i].quantidade.y, size: 10, font: ralewayFont, color: Colors.azul });
      }
    }

    // Escopo de soluções Repensa Energia
    const escopoConteudo = getEscopoRepensa(proposta);
    const el_x = 30;
    const el_y = 470;

    if (proposta.descricao_adicionais) escopoConteudo.push(`• ${proposta.descricao_adicionais}`);
    if (proposta.descricao_adicionais_2) escopoConteudo.push(`• ${proposta.descricao_adicionais_2}`);
    if (proposta.descricao_adicionais_3) escopoConteudo.push(`• ${proposta.descricao_adicionais_3}`);
    if (proposta.descricao_adicionais_4) escopoConteudo.push(`• ${proposta.descricao_adicionais_4}`);
    if (proposta.descricao_adicionais_5) escopoConteudo.push(`• ${proposta.descricao_adicionais_5}`);

    escopoConteudo.forEach((item, index) => {
      page6.drawText(item, {
        x: el_x,
        y: el_y - (index * 11),
        size: 8,
        font: ralewayFont,
        color: Colors.azul
      });
    });

    //Itens opcionais
    const itensOpcionais = {
      cerca: {
        sim: { x: 32, y: 231 },
        nao: { x: 66, y: 231 }
      },
      brita: {
        sim: { x: 110, y: 231 },
        nao: { x: 144, y: 231 }
      },
      garantia_estendida: {
        sim: { x: 188, y: 231 },
        nao: { x: 222, y: 231 }
      },
      estacao_solarimetrica: {
        sim: { x: 316, y: 231 },
        nao: { x: 350, y: 231 }
      },
      abrigo: {
        sim: { x: 451, y: 231 },
        nao: { x: 485, y: 231 }
      }
    }
    // angelo
    const itensOpcionaisSquares = [];
    if (proposta.opcional_cerca === 'sim') {
      itensOpcionaisSquares.push(itensOpcionais.cerca.sim);
    } else {
      itensOpcionaisSquares.push(itensOpcionais.cerca.nao);
    }
    if (proposta.opcional_brita === 'sim') {
      itensOpcionaisSquares.push(itensOpcionais.brita.sim);
    } else {
      itensOpcionaisSquares.push(itensOpcionais.brita.nao);
    }
    if (proposta.opcional_garantia_estendida === 'sim') {
      itensOpcionaisSquares.push(itensOpcionais.garantia_estendida.sim);
    } else {
      itensOpcionaisSquares.push(itensOpcionais.garantia_estendida.nao);
    }
    if (proposta.opcional_estacao_solarimetrica === 'sim') {
      itensOpcionaisSquares.push(itensOpcionais.estacao_solarimetrica.sim);
    } else {
      itensOpcionaisSquares.push(itensOpcionais.estacao_solarimetrica.nao);
    }
    if (proposta.opcional_abrigo === 'sim') {
      itensOpcionaisSquares.push(itensOpcionais.abrigo.sim);
    } else {
      itensOpcionaisSquares.push(itensOpcionais.abrigo.nao);
    }

    itensOpcionaisSquares.forEach((item) => {
      page6.drawRectangle({
        x: item.x,
        y: item.y,
        width: 9,
        height: 9,
        color: Colors.azul
      });
    });    

    const page7 = pages[6];
    // Valor do investimento
    page7.drawText(formatCurrencyWithoutSymbol(proposta.total), {
      x: 70,
      y: 680,
      size: 22,
      font: ralewayFont,
      color: Colors.white
    });
    // Economia no primeiro ano
    page7.drawText(formatCurrency(economia.gasto_sem_energia_solar[0]), {
      x: 305,
      y: 735,
      size: 16,
      font: ralewayFont,
      color: Colors.azul
    });
    // Abatimento médio mensal
    page7.drawText(formatCurrency(proposta.economia_media_mensal_estimada), {
      x: 305,
      y: 660,
      size: 16,
      font: ralewayFont,
      color: Colors.azul
    });
    // Aumento custo energia/ano
    page7.drawText('9%', {
      x: 220,
      y: 587,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Vida útil do sistema solar
    page7.drawText('25 anos', {
      x: 220,
      y: 567,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Payback
    page7.drawText(`${fluxoDeCaixa.payback} anos`, {
      x: 220,
      y: 547,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // TIR
    page7.drawText(formatPercentage(fluxoDeCaixa.tir), {
      x: 220,
      y: 527,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Carro
    page7.drawText('-5 % a.a.', {
      x: 70,
      y: 80,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Poupança
    page7.drawText('8 % a.a.', {
      x: 175,
      y: 80,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Solar FV
    page7.drawText(formatPercentage(fluxoDeCaixa.tir), {
      x: 284,
      y: 80,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Renda FIXA
    page7.drawText('10,25 % a.a.', {
      x: 378,
      y: 80,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    // Compra de Imóvel
    page7.drawText('5% a.a.', {
      x: 494,
      y: 80,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });

    const page8 = pages[7];
    const sizeFluxoCaixa = 8;

    // Preenchimento de cores
    const rectsPos = [
      { x: 455, y: 708 },
      { x: 455, y: 688 },
      { x: 455, y: 668 },
      { x: 455, y: 648 },
      { x: 455, y: 629 },
      { x: 455, y: 609 },
      { x: 455, y: 589 },
      { x: 455, y: 569 },
      { x: 455, y: 549 },
      { x: 455, y: 529 },
      { x: 455, y: 510 },
      { x: 455, y: 490 },
      { x: 455, y: 470 },
      { x: 455, y: 450 },
      { x: 455, y: 430 },
      { x: 455, y: 411 },
      { x: 455, y: 391 },
      { x: 455, y: 372 },
      { x: 455, y: 352 },
      { x: 455, y: 332 },
      { x: 455, y: 312 },
      { x: 455, y: 292 },
      { x: 455, y: 272 },
      { x: 455, y: 252 },
      { x: 455, y: 233 }
    ];
    rectsPos.forEach((item, index) => {
      const color = (index + 1 < fluxoDeCaixa.payback) ? Colors.amarelo : Colors.azul;
      page8.drawRectangle({
        x: item.x,
        y: item.y,
        width: 90,
        height: 17,
        color
      });
    });

    // Fluxo de caixa do projeto - LINHA1
    page8.drawText(formatKWh(fluxoDeCaixa.dados[0][0]), {
      x: 97,
      y: 712,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[0][1]), {
      x: 175,
      y: 712,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[0][2]), {
      x: 287,
      y: 712,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[0][3]), {
      x: 367,
      y: 712,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[0][4]), {
      x: 460,
      y: 712,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA2
    page8.drawText(formatKWh(fluxoDeCaixa.dados[1][0]), {
      x: 97,
      y: 692,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[1][1]), {
      x: 175,
      y: 692,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[1][2]), {
      x: 287,
      y: 692,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[1][4]), {
      x: 460,
      y: 692,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA3
    page8.drawText(formatKWh(fluxoDeCaixa.dados[2][0]), {
      x: 97,
      y: 672,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[2][1]), {
      x: 175,
      y: 672,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[2][2]), {
      x: 287,
      y: 672,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[2][4]), {
      x: 460,
      y: 672,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA4
    page8.drawText(formatKWh(fluxoDeCaixa.dados[3][0]), {
      x: 97,
      y: 652,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[3][1]), {
      x: 175,
      y: 652,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[3][2]), {
      x: 287,
      y: 652,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[3][4]), {
      x: 460,
      y: 652,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA5
    page8.drawText(formatKWh(fluxoDeCaixa.dados[4][0]), {
      x: 97,
      y: 632,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[4][1]), {
      x: 175,
      y: 632,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[4][2]), {
      x: 287,
      y: 632,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[4][4]), {
      x: 460,
      y: 632,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA6
    page8.drawText(formatKWh(fluxoDeCaixa.dados[5][0]), {
      x: 97,
      y: 612,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[5][1]), {
      x: 175,
      y: 612,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[5][2]), {
      x: 287,
      y: 612,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[5][4]), {
      x: 460,
      y: 612,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA7
    page8.drawText(formatKWh(fluxoDeCaixa.dados[6][0]), {
      x: 97,
      y: 592,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[6][1]), {
      x: 175,
      y: 592,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[6][2]), {
      x: 287,
      y: 592,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[6][4]), {
      x: 460,
      y: 592,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA8
    page8.drawText(formatKWh(fluxoDeCaixa.dados[7][0]), {
      x: 97,
      y: 572,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[7][1]), {
      x: 175,
      y: 572,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[7][2]), {
      x: 287,
      y: 572,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[7][4]), {
      x: 460,
      y: 572,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA9
    page8.drawText(formatKWh(fluxoDeCaixa.dados[8][0]), {
      x: 97,
      y: 552,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[8][1]), {
      x: 175,
      y: 552,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[8][2]), {
      x: 287,
      y: 552,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[8][4]), {
      x: 460,
      y: 552,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA10
    page8.drawText(formatKWh(fluxoDeCaixa.dados[9][0]), {
      x: 97,
      y: 532,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[9][1]), {
      x: 175,
      y: 532,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[9][2]), {
      x: 287,
      y: 532,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[9][4]), {
      x: 460,
      y: 533,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA11
    page8.drawText(formatKWh(fluxoDeCaixa.dados[10][0]), {
      x: 97,
      y: 512,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[10][1]), {
      x: 175,
      y: 512,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[10][2]), {
      x: 287,
      y: 512,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[10][4]), {
      x: 460,
      y: 514,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA12
    page8.drawText(formatKWh(fluxoDeCaixa.dados[11][0]), {
      x: 97,
      y: 492,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[11][1]), {
      x: 175,
      y: 492,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[11][2]), {
      x: 287,
      y: 492,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[11][4]), {
      x: 460,
      y: 494,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA13
    page8.drawText(formatKWh(fluxoDeCaixa.dados[12][0]), {
      x: 97,
      y: 472,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[12][1]), {
      x: 175,
      y: 472,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[12][2]), {
      x: 287,
      y: 472,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[12][4]), {
      x: 460,
      y: 473,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA14
    page8.drawText(formatKWh(fluxoDeCaixa.dados[13][0]), {
      x: 97,
      y: 452,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[13][1]), {
      x: 175,
      y: 452,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[13][2]), {
      x: 287,
      y: 452,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[13][4]), {
      x: 460,
      y: 453,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA15
    page8.drawText(formatKWh(fluxoDeCaixa.dados[14][0]), {
      x: 97,
      y: 433,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[14][1]), {
      x: 175,
      y: 433,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[14][2]), {
      x: 287,
      y: 433,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[14][4]), {
      x: 460,
      y: 433,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA16
    page8.drawText(formatKWh(fluxoDeCaixa.dados[15][0]), {
      x: 97,
      y: 413,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[15][1]), {
      x: 175,
      y: 413,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[15][2]), {
      x: 287,
      y: 413,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[15][4]), {
      x: 460,
      y: 415,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA17
    page8.drawText(formatKWh(fluxoDeCaixa.dados[16][0]), {
      x: 97,
      y: 393,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[16][1]), {
      x: 175,
      y: 393,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[16][2]), {
      x: 287,
      y: 393,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[16][4]), {
      x: 460,
      y: 395,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA18
    page8.drawText(formatKWh(fluxoDeCaixa.dados[17][0]), {
      x: 97,
      y: 373,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[17][1]), {
      x: 175,
      y: 373,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[17][2]), {
      x: 287,
      y: 373,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[17][4]), {
      x: 460,
      y: 375,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA19
    page8.drawText(formatKWh(fluxoDeCaixa.dados[18][0]), {
      x: 97,
      y: 353,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[18][1]), {
      x: 175,
      y: 353,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[18][2]), {
      x: 287,
      y: 353,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[18][4]), {
      x: 460,
      y: 355,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA20
    page8.drawText(formatKWh(fluxoDeCaixa.dados[19][0]), {
      x: 97,
      y: 333,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[19][1]), {
      x: 175,
      y: 333,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[19][2]), {
      x: 287,
      y: 333,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[19][4]), {
      x: 460,
      y: 335,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA21
    page8.drawText(formatKWh(fluxoDeCaixa.dados[20][0]), {
      x: 97,
      y: 313,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[20][1]), {
      x: 175,
      y: 313,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[20][2]), {
      x: 287,
      y: 313,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[20][4]), {
      x: 460,
      y: 315,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA22
    page8.drawText(formatKWh(fluxoDeCaixa.dados[21][0]), {
      x: 97,
      y: 293,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[21][1]), {
      x: 175,
      y: 293,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[21][2]), {
      x: 287,
      y: 293,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[21][4]), {
      x: 460,
      y: 295,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA23
    page8.drawText(formatKWh(fluxoDeCaixa.dados[22][0]), {
      x: 97,
      y: 273,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[22][1]), {
      x: 175,
      y: 273,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[22][2]), {
      x: 287,
      y: 273,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[22][4]), {
      x: 460,
      y: 276,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA24
    page8.drawText(formatKWh(fluxoDeCaixa.dados[23][0]), {
      x: 97,
      y: 254,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[23][1]), {
      x: 175,
      y: 254,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[23][2]), {
      x: 287,
      y: 254,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[23][4]), {
      x: 460,
      y: 255,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // LINHA25
    page8.drawText(formatKWh(fluxoDeCaixa.dados[24][0]), {
      x: 97,
      y: 237,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[24][1]), {
      x: 175,
      y: 237,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[24][2]), {
      x: 287,
      y: 237,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(fluxoDeCaixa.dados[24][4]), {
      x: 460,
      y: 237,
      size: sizeFluxoCaixa,
      font: ralewayFont,
      color: Colors.white
    });
    // VPL
    page8.drawText(formatCurrency(alternativaVPL), {
      x: 460,
      y: 218,
      size: 10,
      font: ralewayFont,
      color: Colors.white
    });

    // Indicadores de sustentabilidade e economia
    page8.drawText(calcularNumeroArvoresPlantadas(proposta), {
      x: 85,
      y: 70,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(calcularLitrosGasolina(proposta), {
      x: 218,
      y: 70,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(calcularCo2(proposta), {
      x: 364,
      y: 70,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });
    page8.drawText(formatCurrency(alternativaVPL), {
      x: 465,
      y: 70,
      size: 10,
      font: ralewayFont,
      color: Colors.azul
    });

    const page9 = pages[8];
    // Condições comerciais consideradas
    const condicoesComerciaisCoordenadas = getCondicoesComerciaisCoordenadasBox(proposta);
    page9.drawRectangle({
      x: condicoesComerciaisCoordenadas.x,
      y: condicoesComerciaisCoordenadas.y,
      width: 9,
      height: 9,
      color: Colors.azul
    });

    // Simulação de financiamento
    page9.drawText(formatCurrency(financiamentoSantander.opcao_1.valor_total_a_vista), {
      x: 184,
      y: 540 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_1.parcelas_6), {
      x: 184,
      y: 516 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_1.parcelas_24), {
      x: 184,
      y: 500 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_1.parcelas_36), {
      x: 184,
      y: 484 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_1.parcelas_48), {
      x: 184,
      y: 455 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_1.parcelas_60), {
      x: 184,
      y: 439 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });

    // Opção2
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_servicos), {
      x: 476,
      y: 540 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_servicos_4_parcelas), {
      x: 476,
      y: 516 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });

    // equipamentos
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_equipamentos), {
      x: 476,
      y: 475 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_equipamentos), {
      x: 476,
      y: 455 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_equipamentos_12_parcelas), {
      x: 476,
      y: 438 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_equipamentos_36_parcelas), {
      x: 476,
      y: 422 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_equipamentos_48_parcelas), {
      x: 476,
      y: 406 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });
    page9.drawText(formatCurrency(financiamentoSantander.opcao_2.valor_equipamentos_60_parcelas), {
      x: 476,
      y: 390 - 9,
      size: 9,
      font: ralewayFont,
      color: Colors.azul
    });

    // Gráfico 1
    const ctx1 = document.getElementById('chart_01');
    ctx1.style.display = 'block';
    const chart1 = new Chart(ctx1, {
      type: 'bar',
      data: {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [
          {
            label: 'Consumo',
            data: [
              proposta.geracao.janeiro.consumo_atual,
              proposta.geracao.fevereiro.consumo_atual,
              proposta.geracao.marco.consumo_atual,
              proposta.geracao.abril.consumo_atual,
              proposta.geracao.maio.consumo_atual,
              proposta.geracao.junho.consumo_atual,
              proposta.geracao.julho.consumo_atual,
              proposta.geracao.agosto.consumo_atual,
              proposta.geracao.setembro.consumo_atual,
              proposta.geracao.outubro.consumo_atual,
              proposta.geracao.novembro.consumo_atual,
              proposta.geracao.dezembro.consumo_atual
            ],
            borderColor: '#0c2343',
            backgroundColor: '#0c2343'
          },
          {
            label: 'Geração',
            data: [
              proposta.geracao.janeiro.geracao,
              proposta.geracao.fevereiro.geracao,
              proposta.geracao.marco.geracao,
              proposta.geracao.abril.geracao,
              proposta.geracao.maio.geracao,
              proposta.geracao.junho.geracao,
              proposta.geracao.julho.geracao,
              proposta.geracao.agosto.geracao,
              proposta.geracao.setembro.geracao,
              proposta.geracao.outubro.geracao,
              proposta.geracao.novembro.geracao,
              proposta.geracao.dezembro.geracao
            ],
            borderColor: '#00a6b2',
            backgroundColor: '#00a6b2'
          }
        ]
      },
      options: {
        animation: {
          duration: 0
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                  beginAtZero: true
              }
            }
          ]
        }
      }
    });
    const image1_url = chart1.toBase64Image();
    const image1 = await pdfDoc.embedPng(image1_url);
    page5.drawImage(image1, {
      x: 50,
      y: 185,
      width: 500,
      height: 280
    });
    ctx1.style.display = 'none';

    // Gráfico 2
    const yearsLabels = [];
    for (let i = 1; i < 26; i++) {
      yearsLabels.push(`Ano ${i}`);
    }
    const ctx2 = document.getElementById('chart_02');
    ctx2.style.display = 'block';
    const chart2 = new Chart(ctx2, {
      type: 'bar',
      data: {
        labels: yearsLabels,
        datasets: [
          {
            label: 'Gasto sem sistema solar',
            data: economia.gasto_sem_energia_solar,
            backgroundColor: '#a4a5a2'
          },
          {
            label: 'Economia',
            data: economia.economia,
            backgroundColor: '#538695'
          }
        ]
      },
      options: {
        animation: {
          duration: 0
        },
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      }
    });
    const image2_url = chart2.toBase64Image();
    const image2 = await pdfDoc.embedPng(image2_url);
    page7.drawImage(image2, {
      x: 45,
      y: 160,
      width: 500,
      height: 330
    });
    ctx2.style.display = 'none';

    // Finalizando PDF
    pdfDoc.setTitle('Repensa Energia - Proposta comercial');
    pdfDoc.setAuthor('Repensa Energia');
    pdfDoc.setKeywords(['repensa energia', 'proposta']);
    pdfDoc.setProducer('Gerador de Propostas Repensa Energia');
    pdfDoc.setCreator('repensa-energia-webapp');
    pdfDoc.setCreationDate(new Date());
    pdfDoc.setModificationDate(new Date());

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl);
  } catch (err) {
    toast.error('Erro ao gerar proposta');
    console.error(err);
  }
}