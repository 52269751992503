import { useCallback, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Row, Col, CardTitle, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap'
import InputPasswordToggle from '@components/input-password-toggle'
import { useSkin } from '@hooks/useSkin'
import '@styles/base/pages/page-auth.scss'
import { toast } from 'react-toastify'
import { useAuth } from '../../../context/Auth';
import logo from '@src/assets/images/logo/repensa.png';

export default () => {
  const [skin] = useSkin();
  const { login, toggleLoading } = useAuth();
  const history = useHistory();

  const illustration = skin === 'dark' ? 'login-v2-dark.svg' : 'login-v2.svg'
  const source = require(`@src/assets/images/pages/${illustration}`).default

  const form = useRef()
  const { register, errors, handleSubmit } = useForm()

  const keyPressed = useCallback((event) => {
    if (event.key === "Enter") {
      form.current.props.onSubmit()
    }
  }, [form])

  const submitLogin = useCallback(async (data) => {
    toggleLoading(true)
    try {
      await login({email: data.email, password: data.password})
      toggleLoading(false)
      history.replace('/dashboard')
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error('Erro ao realizar login. Tente novamente mais tarde')
      }
      toggleLoading(false)
    }
  }, [login, toast, toggleLoading])

  return (
    <div className='auth-wrapper auth-v2'>
      <Row className='auth-inner m-0'>
        <a className='brand-logo' href='https://www.repensaenergia.com/' target='_blank'>
          <img className='img-fluid' style={{width: '200px'}} src={logo} alt='illustration' />
        </a>
        <Col className='d-none d-lg-flex align-items-center p-5' lg='8' sm='12'>
          <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
            <img className='img-fluid' src={source} alt='Login V2' />
          </div>
        </Col>
        <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='4' sm='12'>
          <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
            <CardTitle tag='h2' className='font-weight-bold mb-1'>
              Bem-vindo! 👋
            </CardTitle>
            <Form
              className='auth-login-form mt-2'
              onSubmit={handleSubmit(submitLogin)}
              ref={form}
              autoComplete='off'
            >
              <FormGroup>
                <Label className='form-label' for='login-email'>
                  E-mail
                </Label>
                <Input
                  type='email'
                  name='email'
                  id='email'
                  placeholder='email@repensaenergia.com'
                  innerRef={register({
                    required: 'Preencha o email'
                  })}
                  autoFocus
                  onKeyPress={keyPressed}
                />
                {errors.email && (
                  <Alert className='mt-1' color='danger'>
                    <div className='alert-body'>
                      <span>{errors.email.message}</span>
                    </div>
                  </Alert>
                )}
              </FormGroup>
              <FormGroup>
                <div className='d-flex justify-content-between'>
                  <Label className='form-label' for='login-password'>
                    Senha
                  </Label>
                  <Link to='/esqueci-a-senha'>
                    <small>Esqueceu a senha?</small>
                  </Link>
                </div>
                <InputPasswordToggle
                  className='input-group-merge'
                  id='password'
                  name='password'
                  innerRef={register({
                    required: 'Preencha a senha'
                  })}
                  onKeyPress={keyPressed}
                />
                {errors.password && (
                  <Alert className='mt-1' color='danger'>
                    <div className='alert-body'>
                      <span>{errors.password.message}</span>
                    </div>
                  </Alert>
                )}
              </FormGroup>
              <Button.Ripple color='primary' block onClick={handleSubmit(submitLogin)}>
                Entrar
              </Button.Ripple>
            </Form>
            <p className='text-center mt-2'>
              <span className='mr-25'>Novo na nossa plataforma?</span><br />
              <Link to='/cadastro'>
                <span>Criar conta</span>
              </Link>
            </p>
          </Col>
        </Col>
      </Row>
    </div>
  )
}
