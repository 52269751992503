import { useSkin } from '@hooks/useSkin'
import { Link, useParams, useHistory } from 'react-router-dom'
import { ChevronLeft } from 'react-feather'
import InputPassword from '@components/input-password-toggle'
import { Row, Col, CardTitle, CardText, Form, FormGroup, Label, Button, Alert } from 'reactstrap'
import '@styles/base/pages/page-auth.scss'
import logo from '@src/assets/images/logo/repensa.png';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../context/Auth';
import api from '../../../services/api';
import { useForm } from 'react-hook-form';

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const ResetPassword = () => {
  const [skin] = useSkin();
  const illustration = skin === 'dark' ? 'reset-password-v2-dark.svg' : 'reset-password-v2.svg';
  const source = require(`@src/assets/images/pages/${illustration}`).default;

  const { register, errors, handleSubmit } = useForm();

  const { hash } = useParams();
  const [validPage, setValidPage] = useState(true);
  const { toggleLoading } = useAuth();
  const history = useHistory();

  const submitForm = useCallback(async (data) => {
    toggleLoading(true);

    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('hash', hash);
    formData.append('password', data.password);

    try {
      const response = await api.post('/reset_password', formData);
      const { data } = response;
      toggleLoading(false);

      if (data && data.result === 'success' && data.message) {
        toast.success(data.message);
        history.push(`/`);
      }
    } catch (err) {
      toggleLoading(false);
      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error('Erro ao recuperar senha')
      }
    }
  }, [])
  
  const checkHash = useCallback(async () => {
    toggleLoading(true);

    const formData = new FormData();
    formData.append('access_key', ACCESS_KEY);
    formData.append('hash', hash);

    try {
      const response = await api.post('/check_forgot_password_hash', formData);
      const { data } = response;
      toggleLoading(false);
      console.log(data);
    } catch (err) {
      toggleLoading(false);
      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error('Erro')
      }
      setValidPage(false);
    }
  }, []);

  useEffect(() => {
    checkHash();
  }, [checkHash]);

  return (
    <div className='auth-wrapper auth-v2'>
      <Row className='auth-inner m-0'>
        <a className='brand-logo' href='https://www.repensaenergia.com/' target='_blank'>
          <img className='img-fluid' style={{ width: '200px' }} src={logo} alt='illustration' />
        </a>
        <Col className='d-none d-lg-flex align-items-center p-5' lg='8' sm='12'>
          <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
            <img className='img-fluid' src={source} alt='Login V2' />
          </div>
        </Col>
        <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='4' sm='12'>
          <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
            <CardTitle tag='h2' className='font-weight-bold mb-1'>
              Redefinir senha 🔒
            </CardTitle>
            <CardText className='mb-2'>Sua nova senha deve ser diferente de senhas anteriores</CardText>
            {validPage && (
              <Form className='auth-reset-password-form mt-2' onSubmit={e => e.preventDefault()}>
                <FormGroup>
                  <Label className='form-label' for='new-password'>
                    Nova senha
                  </Label>
                  <InputPassword
                    className='input-group-merge'
                    id='password'
                    name='password'
                    autoFocus
                    innerRef={register({
                      required: 'Preencha a nova senha'
                    })}
                  />
                  {errors.password && (
                    <Alert className='mt-1' color='danger'>
                      <div className='alert-body'>
                        <span>{errors.password.message}</span>
                      </div>
                    </Alert>
                  )}
                </FormGroup>
                <Button.Ripple
                  color='primary'
                  block
                  onClick={handleSubmit(submitForm)}
                >
                  Redefinir
                </Button.Ripple>
              </Form>
            )}
            <p className='text-center mt-2'>
              <Link to='/'>
                <ChevronLeft className='mr-25' size={14} />
                <span className='align-middle'>Retornar ao login</span>
              </Link>
            </p>
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default ResetPassword;
