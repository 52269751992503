import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import Loader from './utility/Loader';

import ability from './configs/acl/ability';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';
import { IntlProviderWrapper } from './utility/context/Internationalization';
import { store } from './redux/storeConfig/store';
import Routes from './router/Routes';
import AppProvider from './context/Provider';

export default function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <IntlProviderWrapper>
              <Router>
                <Loader />
                <Routes />
                <ToastContainer />
              </Router>
            </IntlProviderWrapper>
          </ThemeContext>
        </AbilityContext.Provider>
      </AppProvider>
    </Provider>
  )
}
